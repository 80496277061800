import ChangePriceIllustration from "components/atoms/illustration/ChangePriceIllustration";
import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi2";
import * as Yup from "yup";
import FormikPriceInput from "../basics/from/FormikPriceInput";
import { ChatProductMessageContentDto, MessageContentTemplateResponseDto } from "@neolime-gmbh/api-gateway-client";

type Props = {
  messageId?: string;
  broadcastId?: string;
  chatProduct?: ChatProductMessageContentDto;
  broadcastContent?: MessageContentTemplateResponseDto;
  open: boolean;
  onClose: () => void;
};

const EditChatProductPricePopup = ({ messageId, broadcastId, chatProduct, broadcastContent, open, onClose }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const [step, setStep] = useState(0);
  const [error, setError] = useState<boolean>(false);

  const initialPrice = (broadcastId ? broadcastContent?.price : chatProduct?.price.net) ?? 0;
  const [price, setPrice] = useState(initialPrice);

  const [isPosting, setIsPosting] = useState<boolean>(false);

  // get title of popup
  const popUpTitle = useMemo(() => {
    switch (step) {
      case 0:
        return t("changeChatProductPrice.changePrice.title");
      case 1:
        return t("changeChatProductPrice.areYouSure.title");
      default:
        return error ? t("changeChatProductPrice.error.title") : t("changeChatProductPrice.success.title");
    }
  }, [step]);

  const handleSaveMessageNewPrice = async (netAmount: number = price) => {
    if (!chatProduct || !messageId) return;
    setIsPosting(true);
    await maloumClient.messages.updateMessagePrice(messageId, { netAmount }).catch(() => setError(true));
    setIsPosting(false);
    setStep(2);
  };

  const handleSaveBroadcastNewPrice = async (netAmount: number = price) => {
    if (!broadcastContent || !broadcastId) return;
    setIsPosting(true);
    await maloumClient.broadcasts
      .updateBroadcastChatProductPrice(broadcastId, { netAmount })
      .catch(() => setError(true));
    setIsPosting(false);
    setStep(2);
  };

  // reset step on open
  useEffect(() => {
    if (open) {
      setStep(0);
      setPrice(initialPrice);
      setError(false);
      setIsPosting(false);
    }
  }, [open]);

  return (
    <PopUp isOpen={open} onClose={onClose} title={popUpTitle}>
      {step === 0 && (
        <PriceChangeStep
          price={price}
          isLoading={isPosting}
          nextStep={(newPrice: number) => {
            if (broadcastId) {
              setPrice(newPrice as number);
              setStep(1);
            } else {
              handleSaveMessageNewPrice(newPrice);
            }
          }}
        />
      )}
      {step === 1 && (
        <MassMessageConfirmationStep
          onBack={() => setStep(0)}
          nextStep={handleSaveBroadcastNewPrice}
          isLoading={isPosting}
        />
      )}
      {error && <PriceChangeErrorStep nextStep={onClose} />}
      {!error && step === 2 && <PriceChangeSuccessStep nextStep={onClose} />}
    </PopUp>
  );
};

export default EditChatProductPricePopup;

type PriceChangeStepProps = {
  price: number;
  isLoading: boolean;
  nextStep: (newPrice: number) => void;
};

const PriceChangeStep = ({ price, nextStep, isLoading }: PriceChangeStepProps) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const decimalSchema = Yup.number()
    .typeError(t("validation.onlyNumbers") as string)
    .min(1, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) ?? "")
    .max(9999, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) ?? "")
    .test("is-decimal", t("validation.only2Decimals") as string, (val) => {
      if (val !== undefined) {
        return /^\d+(\.\d{0,2})?$/.test(val.toString());
      }
      return false;
    })
    .required(t("validation.required") as string);

  return (
    <Formik
      initialValues={{
        price: price?.toFixed(2) ?? "",
      }}
      validationSchema={Yup.object().shape({
        price: decimalSchema,
      })}
      onSubmit={(values) => {
        nextStep(parseFloat(values.price));
      }}
    >
      {({ isValid }) => (
        <Form className="mt-4 text-left">
          <div className="mb-4">
            <FormikPriceInput
              data-testid="net-price-input"
              name="price"
              textSizeClassName="text-2xl"
              className="text-center font-semibold"
            />
          </div>
          <Button
            disabled={!isValid || isLoading}
            type="submit"
            text={t("changeChatProductPrice.changePrice.setPrice")}
          />
        </Form>
      )}
    </Formik>
  );
};

type MassMessageConfirmationStepProps = {
  nextStep: () => void;
  onBack: () => void;
  isLoading: boolean;
};

const MassMessageConfirmationStep = ({ nextStep, onBack, isLoading }: MassMessageConfirmationStepProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <ChangePriceIllustration className="mx-auto mb-4 h-24" />
      <div className="text-center text-sm text-gray-500">{t("changeChatProductPrice.areYouSure.description")}</div>
      <div className="flex gap-2 pt-6">
        <Button text={t("back")} onClick={onBack} variant="secondary" />
        <Button
          text={t("changeChatProductPrice.areYouSure.saveChanges")}
          disabled={isLoading}
          onClick={() => nextStep()}
        />
      </div>
    </div>
  );
};

const PriceChangeSuccessStep = ({ nextStep }: { nextStep: () => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <HiOutlineCheckCircle className="mx-auto my-2 h-16 w-16 text-red-900" />
      <div>{t("changeChatProductPrice.success.description")}</div>
      <Button text={t("gotIt")} onClick={nextStep} className="mt-6" />
    </div>
  );
};

const PriceChangeErrorStep = ({ nextStep }: { nextStep: () => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <HiOutlineXCircle className="mx-auto my-2 h-16 w-16" />
      <div>{t("changeChatProductPrice.error.description")}</div>
      <Button text={t("gotIt")} onClick={nextStep} className="mt-6" />
    </div>
  );
};
