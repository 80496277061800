import useUserStore from "state/userState";

const LoadingChatItem = () => {
  const user = useUserStore((state) => state.user);
  return (
    <>
      <div className="flex min-w-0 animate-pulse items-start gap-0.5 bg-white px-4 py-3">
        <div id="avatarAndText" className="flex min-w-0 flex-grow items-center gap-3">
          <div className="h-[60px] w-[60px] shrink-0 rounded-full bg-gray-100" />
          <div id="messageAndInfo" className="flex min-w-0 flex-grow flex-col items-start gap-1">
            <div id="usernameAndMessage" className="flex flex-col items-start gap-0.5 self-stretch">
              <div className="flex w-full items-center">
                <div className="h-4 w-32 rounded-md bg-gray-100" />
              </div>
              <div className="flex w-full min-w-0 items-center justify-between">
                <div className="h-4 w-48 rounded-md bg-gray-100" />
              </div>
            </div>
            {user?.isCreator && (
              <div id="rankAndSpending" className="flex items-start gap-1.5">
                <div className="h-5 w-20 rounded-full bg-gray-100" />
              </div>
            )}
          </div>
        </div>
        <div id="dateAndIndicator" className="flex flex-col items-end gap-1">
          <div className="h-3 w-16 rounded-full bg-gray-100" />
        </div>
      </div>
      <div className="mx-4 box-border border-b border-gray-100" />
    </>
  );
};

export default LoadingChatItem;
