import { BroadcastUserGroup, ChatListDto } from "@neolime-gmbh/api-gateway-client";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import CreateNewListPopup from "components/molecules/lists/popups/CreateNewListPopup";
import ListSelectionList from "components/organisms/lists/ListSelectionList";
import MaloumClientContext from "contexts/MaloumClientContext";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

const AddToLists = () => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const navigate = useStatefulNavigate();

  const { state } = useLocation();
  const userIdToAdd = state?.userIdToAdd as string | undefined;
  const broadcastUserToAdd = state?.broadcastUserToAdd as
    | { broadcastId: string; userGroup: BroadcastUserGroup }
    | undefined;
  const listId = state?.listId as string | undefined;

  const [createPopupOpen, setCreatePopupOpen] = useState(false);
  const [selectedLists, setSelectedLists] = useState<string[]>([]);

  const handleItemClick = (list: ChatListDto) => {
    if (selectedLists.includes(list._id)) {
      setSelectedLists(selectedLists.filter((id) => id !== list._id));
    } else {
      setSelectedLists([...selectedLists, list._id]);
    }
  };

  const handleAddMemberToLists = async (userIdToAdd: string) =>
    Promise.all(
      selectedLists.map(
        async (listId: string) => await maloumClient.chatLists.updateMembersById(listId, { add: [userIdToAdd] }),
      ),
    );

  const addMemberMutation = useMutation({ mutationFn: handleAddMemberToLists });

  const addBroadcastUsersToList = async ({
    broadcastId,
    userGroup,
  }: {
    broadcastId: string;
    userGroup: BroadcastUserGroup;
  }) =>
    maloumClient.broadcasts.addUsersOfBroadcastToChatList(broadcastId, {
      userGroup,
      chatListIds: selectedLists,
    });

  const addBroadcastUsersToListsMutation = useMutation({ mutationFn: addBroadcastUsersToList });

  const handleConfirm = async () => {
    if (userIdToAdd) {
      addMemberMutation.mutate(userIdToAdd, { onSuccess: () => navigate(-1) });
    }

    if (broadcastUserToAdd) {
      addBroadcastUsersToListsMutation.mutate(
        {
          broadcastId: broadcastUserToAdd.broadcastId,
          userGroup: broadcastUserToAdd.userGroup,
        },
        { onSuccess: () => navigate(-1) },
      );
    }

    return;
  };

  useEffect(() => {
    if (listId && !selectedLists.includes(listId)) setSelectedLists([...selectedLists, listId]);
  }, [listId]);

  // if no userIdToAdd is set, redirect to home
  if (!userIdToAdd && !broadcastUserToAdd) return <StatefulNavigate to="/" />;

  return (
    <>
      <Layout hideNavigationMobile>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.Cancel />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("chatSettings.addToList")}</HeaderBar.Title>
            </HeaderBar.Center>

            <HeaderBar.Right>
              <TernaryButton variant="red" size="sm" onClick={() => setCreatePopupOpen(true)}>
                {t("lists.new.cta")}
              </TernaryButton>
            </HeaderBar.Right>
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container>
          <ListSelectionList selectedListIds={selectedLists} onItemClick={handleItemClick} showManagedLists={false} />
          <div className="mt-12 grow" />

          <div className="sticky bottom-0 border-t border-t-gray-100 bg-white px-4 py-4 md:px-0">
            <Button text={t("chatSettings.addToList")} onClick={handleConfirm} disabled={addMemberMutation.isPending} />
          </div>
        </Container>
      </Layout>
      <CreateNewListPopup replaceStateOnSave open={createPopupOpen} onClose={() => setCreatePopupOpen(false)} />
    </>
  );
};

export default AddToLists;
