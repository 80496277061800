import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

const useList = (id: string | null) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchList = async () => {
    if (!id) return null;
    return await maloumClient.chatLists.getById(id);
  };

  const {
    data: list,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["list", id],
    queryFn: fetchList,
  });

  return { list, isLoading, isError };
};

export default useList;
