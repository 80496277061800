import { MediaUploadStatus } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { VideoPlayOverlay } from "components/atoms/VideoPlayOverlay";
import UnprocessedMedia from "components/molecules/UnprocessedMedia";
import { useState } from "react";

type Props = { url?: string; uploadStatus: MediaUploadStatus; type: "picture" | "video" };

const ChatGridImage = ({ url, uploadStatus, type }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  return (
    <div className="relative aspect-[130/172] w-full grow overflow-hidden">
      {uploadStatus === MediaUploadStatus.PROCESSING ? (
        <div className="flex h-full w-full items-center justify-center bg-gray-100">
          <UnprocessedMedia isSender hideText />
        </div>
      ) : (
        <>
          <div
            className={classNames("h-full w-full bg-gray-200", {
              hidden: isLoaded,
            })}
          />
          {!hasError && (
            <img
              src={url}
              className={classNames("h-full w-full object-cover", { hidden: !isLoaded })}
              alt=""
              onLoad={() => setIsLoaded(true)}
              onError={() => setHasError(true)}
            />
          )}
          {type === "video" && (
            <VideoPlayOverlay className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" size={8} />
          )}
        </>
      )}
    </div>
  );
};

export default ChatGridImage;
