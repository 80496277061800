import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import FanInfo from "components/chats/FanInfo";
import { MessageBarRef } from "components/chats/MessageBar";
import Layout from "components/layouts/Layout";
import ThreeColumnLayout from "components/layouts/ThreeColumnLayout";
import EmptyChatSelection from "components/molecules/chat/EmptyChatSelection";
import { MessageBarContext } from "contexts/MessageBarContext";
import { useMemo, useRef, useEffect } from "react";
import { ChatTabContext } from "contexts/ChatTabContext";
import { useParams, useSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import Chat from "./Chat";
import { ChatOverview } from "components/chats/ChatOverview";
import useChatFilterState from "state/chatFilterState";

const ChatsParent = () => {
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { id } = useParams();
  const messageBarRef = useRef<MessageBarRef>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const setFilter = useChatFilterState((state) => state.setFilter);

  const messageBarContextValue = useMemo(
    () => ({
      focusMessageBar: () => messageBarRef.current?.focus(),
    }),
    [],
  );

  useEffect(() => {
    return () => {
      //clear filter when leaving chats page
      setFilter(undefined);
    };
  }, []);

  useEffect(() => {
    if (!id && scrollPosition.current !== null && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: scrollPosition.current });
      scrollPosition.current = null;
    }
  }, [id]);

  const handleScroll = () => {
    if (scrollContainerRef.current && !id) {
      scrollPosition.current = scrollContainerRef.current.scrollTop;
    }
  };

  const chatTabContextValue = useMemo(
    () => ({
      switchToPrimaryTab: () => {
        searchParams.delete("tab");
        setSearchParams(searchParams);
      },
    }),
    [searchParams, setSearchParams],
  );

  const center = id ? (
    <Chat messageBarRef={messageBarRef} />
  ) : (
    <>
      {/* use layout with navigation bar (bottom) for up to sm */}
      <div className="sm:hidden">
        <Layout>
          <ChatOverview />
        </Layout>
      </div>

      {/* then use layout with navigation bar (side) for md and up */}
      <div className="hidden sm:block md:hidden">
        <ChatOverview />
      </div>
      <div className="hidden min-h-screen md:block">
        <EmptyChatSelection />;
      </div>
    </>
  );
  const right = id && user.isCreator ? <FanInfo /> : null;

  return (
    <ChatTabContext.Provider value={chatTabContextValue}>
      <MessageBarContext.Provider value={messageBarContextValue}>
        <div className="h-full overflow-x-hidden" ref={scrollContainerRef} onScroll={handleScroll}>
          <ThreeColumnLayout
            hideNavigationOnMobile
            leftColumn={<ChatOverview />}
            centerColumn={center}
            rightColumn={right}
          />
        </div>
      </MessageBarContext.Provider>
    </ChatTabContext.Provider>
  );
};

export default ChatsParent;
