import classNames from "classnames";
import StillUploadingMediaPopup from "components/media/StillUploadingMediaPopup";
import { populateUsername } from "helper/routeHelper";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import useUploadStore from "state/uploadState";
import useUserStore from "state/userState";
import { NavItemCommonProps, NavItemLinkProps } from "types/navigation.types";

const NavItemLink = ({
  Icon,
  SelectedIcon,
  title,
  to,
  disabled,
}: NavItemCommonProps & Omit<NavItemLinkProps, "type">) => {
  const navigate = useStatefulNavigate();

  const user = useUserStore((state) => state.user);
  const { uploads } = useUploadStore();
  const [stillUploading, setStillUploading] = useState(false);

  const populatedTo = populateUsername(to, user);

  const handleNavigate = () => {
    if (uploads.map((upload) => upload.status).includes("uploading")) {
      setStillUploading(true);
    } else {
      navigate(populatedTo);
    }
  };

  // set isActive to true if the current path is the same as the to path
  const isActive = populatedTo === window.location.pathname;

  return (
    <>
      <button
        onClick={handleNavigate}
        className={classNames(
          "flex w-full items-center gap-3 rounded-md border-2 border-transparent p-2 text-gray-700 outline-none duration-100",
          {
            "bg-beige-400": isActive && populatedTo !== "/verification/start",
            "bg-gray-100 text-gray-300": disabled,
            "hover:bg-beige-300 hover:text-gray-500 focus:border-blue-violet active:bg-beige-400 active:text-gray-900":
              !disabled,
          },
        )}
      >
        {isActive && populatedTo !== "/verification/start" && SelectedIcon ? (
          <SelectedIcon className={"h-6 w-6 "} />
        ) : (
          <Icon className={"h-6 w-6 "} />
        )}
        <span className="hidden lg:flex">{title}</span>
      </button>
      <StillUploadingMediaPopup isOpen={stillUploading} setIsOpen={setStillUploading} />
    </>
  );
};

export default NavItemLink;
