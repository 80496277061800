import classNames from "classnames";
import { BaseComponent } from "types/components/component.type";
import { EmptyGalleryIllustration } from "components/atoms/illustration/EmptyGalleryIllustration";

type Props = {
  title?: string;
  description: string;
} & BaseComponent;

export const EmptyGallerySelection = ({ className, title, description }: Props) => {
  return (
    <div className={classNames("flex h-full w-full grow flex-col items-center justify-center gap-6", className)}>
      <EmptyGalleryIllustration />
      <div className="mx-20 text-center text-sm text-gray-500">
        {title && <h2 className="text-xl font-semibold text-gray-900">{title}</h2>}
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
};
