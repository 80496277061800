import { ChatDto, InboxType, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import PopUp from "components/popup/PopUp";
import useChatItem from "hooks/useChatItem.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import BlockUserPopup from "./block/BlockUserPopup";
import { useChatTabContext } from "contexts/ChatTabContext";

type Props = {
  chat: ChatDto;
  open: boolean;
  onClose: () => void;
  "data-testid"?: string;
};

const ChatSettingsPopup = ({ chat, open, onClose, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const [blockUserOpen, setBlockUserOpen] = useState(false);
  const { moveChatToPrimary, moveChatToRequest } = useChatItem(chat._id);
  const { switchToPrimaryTab } = useChatTabContext();
  const options = [
    ...(user.isCreator
      ? [
          {
            title: t("chatSettings.addToList"),
            onClick: () => navigate("/lists/add/member", { state: { userIdToAdd: chat.chatPartner._id } }),
          },
        ]
      : [
          {
            ...(chat.inbox === InboxType.REQUEST
              ? {
                  title: t("chats.request.accept"),
                  onClick: async () => {
                    moveChatToPrimary();
                    onClose();
                    switchToPrimaryTab();
                  },
                }
              : {
                  title: t("chats.request.moveTo"),
                  onClick: async () => {
                    moveChatToRequest();
                    onClose();
                  },
                }),
          },
        ]),
    {
      title: t("chatSettings.block.blockUser"),
      onClick: () => setBlockUserOpen(true),
    },
  ];

  if (blockUserOpen) {
    return (
      <BlockUserPopup
        chatId={chat._id}
        userId={chat.chatPartner._id}
        open={blockUserOpen}
        onClose={() => {
          setBlockUserOpen(false);
          onClose();
        }}
      />
    );
  }

  return (
    <PopUp
      isOpen={open}
      onClose={onClose}
      title={t("chatSettings.title") as string}
      data-testid={dataTestId}
      options={options}
    />
  );
};

export default ChatSettingsPopup;
