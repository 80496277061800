import {
  MessageContentType,
  MessageDto,
  MessagePreviewDto,
  TextMessageContentDto,
  TipMessageContentDto,
  ChatProductMessageContentDto,
  MediaMessageContentDto,
} from "@neolime-gmbh/api-gateway-client";

export type MessageDtoWithDeliveryInfo = MessageDto & {
  failedDelivery?: boolean;
};

export function messageToMessagePreview(message: MessageDto): MessagePreviewDto {
  const messagePreview: MessagePreviewDto = {
    _id: message._id,
    senderId: message.senderId,
    sentAt: message.sentAt,
    type: message.content.type,
    text: "text" in message.content ? message.content.text : undefined,
    isBroadcasted: message.isBroadcasted,
  };

  if (message.content.type === MessageContentType.TIP) {
    messagePreview.tip = (message.content as TipMessageContentDto).price.net;
  }

  return messagePreview;
}

export const sortMessages = ({ newMessages, chatId }: { newMessages: MessageDto[]; chatId: string }) => {
  const messagesTmp = newMessages.filter((m) => m.chat === chatId);

  return messagesTmp.sort((m1, m2) => new Date(m2.sentAt).getTime() - new Date(m1.sentAt).getTime());
};

export const doesMessageContentHaveText = (
  messageContent: MessageDto["content"],
): messageContent is TextMessageContentDto | ChatProductMessageContentDto | MediaMessageContentDto => {
  return (
    messageContent.type === MessageContentType.TEXT ||
    messageContent.type === MessageContentType.CHAT_PRODUCT ||
    messageContent.type === MessageContentType.MEDIA
  );
};
