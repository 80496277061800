import { createContext, useContext, useEffect } from "react";
import SocketContext, { ServerToClientEvents } from "./SocketContext";
import { useMessagesQueryManipulator } from "hooks/queries/useMessagesQueryManipulator.hook";
import MaloumClientContext from "./MaloumClientContext";
import { useChatsQueryManipulator } from "hooks/queries/useChatsQueryManipulator.hook";
import { useSingleChatQueryManipulator } from "hooks/queries/useSingleChatQueryManipulator.hook";
import { ChatDto, MessagePreviewDto, TipMessageContentDto } from "@neolime-gmbh/api-gateway-client";
import { doesMessageContentHaveText } from "helper/messageHelper";
type ChatsContextType = {
  markChatAsRead: (chatId: string) => void;
};

export const ChatsContext = createContext<ChatsContextType>({
  markChatAsRead: () => {},
});

export const useChatsContext = () => useContext(ChatsContext);

export const ChatsProvider = ({ children }: { children: React.ReactNode }) => {
  const socket = useContext(SocketContext);
  const { maloumClient } = useContext(MaloumClientContext);

  const {
    addMessageToMessagesQuery,
    refetchMessagesQuery,
    replaceMessageInMessagesQuery,
    markMessageAsFailed,
    removeMessageFromMessagesQuery,
    doesMessageExistInMessagesQuery,
    updateOptimisticMessage,
    invalidateAndRefetchIfExistsInMessagesQuery,
  } = useMessagesQueryManipulator();

  const {
    updateChatQueryAndAddToTop,
    markChatAsReadInQuery,
    invalidateChatsQueryAndRefetch,
    updateTotalSpendForChat,
    getChatFromChatsQuery,
    updateChatInboxInChatsQuery,
    updateChatInChatsQuery,
  } = useChatsQueryManipulator();

  const { updateTotalSpendForSingleChatItem } = useSingleChatQueryManipulator();

  const handleReceiveMessage: ServerToClientEvents["receive_message"] = async (message) => {
    const id = window.location.pathname.split("/").filter(Boolean).pop();

    const isChatOpen = message.chat === id;
    if (isChatOpen) {
      markChatAsRead(message.chat);
    }

    if (message.optimisticMessageId) {
      updateOptimisticMessage(message, message.optimisticMessageId);
    } else {
      addMessageToMessagesQuery(message);
    }

    const existingChatInQuery = getChatFromChatsQuery(message.chat, message.inboxType);

    let chat: ChatDto;

    if (!existingChatInQuery) {
      chat = await maloumClient.chats.getChat(message.chat);
    } else {
      const messagePreview: MessagePreviewDto = {
        _id: message._id,
        sentAt: message.sentAt,
        senderId: message.senderId,
        isBroadcasted: message.isBroadcasted,
        type: message.content.type,
      };

      const messageContent = message.content;

      if (doesMessageContentHaveText(messageContent)) {
        messagePreview.text = messageContent.text;
      } else {
        messagePreview.tip = (messageContent as TipMessageContentDto).price.net;
      }

      chat = {
        ...existingChatInQuery,
        // manually set unreadMessages to true because we have received a new message.
        // we check later on if chat is open
        unreadMessages: true,
        lastRelevantMessage: messagePreview,
      };
    }

    return updateChatQueryAndAddToTop({ ...chat, unreadMessages: isChatOpen ? false : chat.unreadMessages });
  };

  const handleUpdateMessagePrice: ServerToClientEvents["update_message_price"] = async (message) => {
    const id = window.location.pathname.split("/").filter(Boolean).pop();

    const isChatOpen = message.chat === id;
    if (isChatOpen) {
      markChatAsRead(message.chat);
    }

    if (!doesMessageExistInMessagesQuery(message.chat, message._id)) {
      return;
    }

    replaceMessageInMessagesQuery(message);

    const existingChatInQuery = getChatFromChatsQuery(message.chat, message.inboxType);

    // only update chat overview if message is the last relevant message
    if (!existingChatInQuery || existingChatInQuery.lastRelevantMessage?._id !== message._id) {
      return;
    }

    const messagePreview: MessagePreviewDto = {
      _id: message._id,
      sentAt: message.sentAt,
      senderId: message.senderId,
      isBroadcasted: message.isBroadcasted,
      type: message.content.type,
      text: message.content.text,
    };

    return updateChatInChatsQuery({
      ...existingChatInQuery,
      lastRelevantMessage: messagePreview,
    });
  };

  const handleMessageFailed: ServerToClientEvents["send_message_error"] = ({ chatId, messageId }) => {
    markMessageAsFailed({ chatId, messageId });
  };

  const handleUploadCompleted: ServerToClientEvents["upload_completed"] = (message) => {
    replaceMessageInMessagesQuery(message);
  };

  const handleDeleteMessage: ServerToClientEvents["delete_message_update"] = async (message) => {
    if (message.lastRelevantMessageForMember === message.message) {
      // refetch
      refetchMessagesQuery(message.chat);
      invalidateChatsQueryAndRefetch(message.inbox);
      return;
    }

    //remove message from messagequery
    removeMessageFromMessagesQuery({ chatId: message.chat, messageId: message.message });
  };

  const markChatAsRead = (chatId: string) => {
    markChatAsReadInQuery(chatId);
    socket?.emit("read_messages", {
      chat: chatId,
    });
  };

  const handleTotalSpendUpdated: ServerToClientEvents["total_spend_updated"] = ({ chatId, totalSpend }) => {
    updateTotalSpendForChat({ chatId, totalSpend });
    updateTotalSpendForSingleChatItem({ chatId, totalSpend });
  };

  const handleChatInboxUpdated: ServerToClientEvents["chat_inbox_updated"] = ({ chatId, inbox }) => {
    updateChatInboxInChatsQuery(chatId, inbox);
  };

  const handleBroadcastSent: ServerToClientEvents["broadcast_sent"] = ({ chatIds }) => {
    // if we have already fetched messages and the broadcast has been sent to the fan, we need to refetch the messages
    // in order to show the broadcasted message
    chatIds.forEach((chatId) => {
      invalidateAndRefetchIfExistsInMessagesQuery(chatId);
    });
  };

  useEffect(() => {
    socket?.on("receive_message", handleReceiveMessage);
    socket?.on("upload_completed", handleUploadCompleted);
    socket?.on("delete_message_update", handleDeleteMessage);
    socket?.on("total_spend_updated", handleTotalSpendUpdated);
    socket?.on("chat_inbox_updated", handleChatInboxUpdated);
    socket?.on("send_message_error", handleMessageFailed);
    socket?.on("update_message_price", handleUpdateMessagePrice);
    socket?.on("broadcast_sent", handleBroadcastSent);
    return () => {
      socket?.off("receive_message", handleReceiveMessage);
      socket?.off("upload_completed", handleUploadCompleted);
      socket?.off("delete_message_update", handleDeleteMessage);
      socket?.off("total_spend_updated", handleTotalSpendUpdated);
      socket?.off("chat_inbox_updated", handleChatInboxUpdated);
      socket?.off("send_message_error", handleMessageFailed);
      socket?.off("update_message_price", handleUpdateMessagePrice);
      socket?.off("broadcast_sent", handleBroadcastSent);
    };
  }, [socket]);
  return <ChatsContext.Provider value={{ markChatAsRead }}>{children}</ChatsContext.Provider>;
};
