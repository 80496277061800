import { ChatDto, MessageContentType, MessageDto } from "@neolime-gmbh/api-gateway-client";
import { useReverseInfiniteScroll } from "hooks/useReverseInfiniteScroll.hook";
import useSendMessage from "hooks/useSendMessage.hook";
import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState, memo, useCallback, useMemo } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useInView } from "react-intersection-observer";
import LoadingChat from "./LoadingChat";
import Message from "./Message";
import { isChatProductMessageContent, isMediaMessageContent } from "types/messages/message.type";
type Props = { chatId: string; chat: ChatDto };

export type MessageListRef = {
  saveScroll: () => void;
};

const MemoizedMessage = memo(Message, (prev, next) => {
  return prev.message.content === next.message.content && 
         prev.settingsOpenForMessage === next.settingsOpenForMessage &&
         prev.message.failedDelivery === next.message.failedDelivery;
});

const MessageList = forwardRef<MessageListRef, Props>(({ chatId, chat }, ref) => {
  const { deleteMessage } = useSendMessage(chatId);

  const refBottom = useRef<HTMLDivElement | null>(null);
  const { ref: refTop, inView } = useInView();

  const [settingsOpenForMessage, setSettingsOpenForMessage] = useState<string | undefined>(undefined);

  const resetSettingsOpenForMessage = () => setSettingsOpenForMessage(undefined);

  const { handleScroll, scrollPositionRef, scrollContainerRef, messages, isLoading, isFetching, setMessageScroll } =
    useReverseInfiniteScroll({
      bottomRef: refBottom,
      chatId,
      inView,
    });

  useImperativeHandle(ref, () => ({
    saveScroll: () => {
      setMessageScroll(scrollPositionRef.current ?? null);
    },
  }));

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.overflow = settingsOpenForMessage ? "hidden" : "auto";
    }
  }, [scrollContainerRef, settingsOpenForMessage]);

  const renderMessage = useCallback((m: MessageDto) => {
    const messageKey = `${m._id}-${m.content.type}`;
    let textMessage;

    if ((isChatProductMessageContent(m.content) || isMediaMessageContent(m.content)) && m.content.text) {
      const mCopy = { ...m, content: { ...m.content, type: MessageContentType.TEXT } };
      textMessage = (
        <MemoizedMessage
          message={mCopy}
          key={`${messageKey}-text`}
          displayLinks={chat.chatPartner.isTrusted}
          onDelete={() => {
            resetSettingsOpenForMessage();
            deleteMessage({ messageId: m._id, deleteTextOnly: true });
          }}
          settingsOpenForMessage={settingsOpenForMessage}
          onOpenSettings={setSettingsOpenForMessage}
          onCloseSettings={resetSettingsOpenForMessage}
        />
      );
    }

    return (
      <Fragment key={messageKey}>
        {textMessage}
        <MemoizedMessage
          message={m}
          key={`${messageKey}-main`}
          displayLinks={chat.chatPartner.isTrusted}
          onDelete={() => {
            resetSettingsOpenForMessage();
            deleteMessage({ messageId: m._id, deleteTextOnly: false });
          }}
          settingsOpenForMessage={settingsOpenForMessage}
          onOpenSettings={setSettingsOpenForMessage}
          onCloseSettings={resetSettingsOpenForMessage}
        />
      </Fragment>
    );
  }, [chat.chatPartner.isTrusted, deleteMessage, settingsOpenForMessage]);

  const memoizedMessages = useMemo(() => 
    messages.map(renderMessage)
  , [messages, renderMessage]);

  if (isLoading) return <LoadingChat showHeader={false} />;

  return (
    <div
      className="scrollbar-hide flex-1 overflow-y-auto overscroll-none"
      ref={scrollContainerRef}
      onScroll={handleScroll}
      id="messages-list-container"
    >
      <div className="relative grow">
        <div ref={refTop} className="absolute left-0 top-[3rem]" />
        <div className="flex flex-col-reverse pt-8" style={{ minHeight: "100%" }}>
          {memoizedMessages}
          {isFetching && (
            <div className="absolute left-1/2 top-2 mx-auto w-fit -translate-x-1/2 py-4">
              <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin fill-gray-400" />
            </div>
          )}
        </div>
        <div ref={refBottom} />
      </div>
    </div>
  );
});

MessageList.displayName = "MessageList";

export default MessageList;
