import classNames from "classnames";
import PopUp from "components/popup/PopUp";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { t } from "i18next";
import { ReactNode } from "react";
import { Location, ScrollRestoration } from "react-router-dom";
import useNavigationStore from "state/navigationState";
import { BaseComponent } from "types/components/component.type";
import NavigationBar from "./NavigationBar";
import Sidebar from "./Sidebar";

type Props = {
  leftColumn?: ReactNode;
  centerColumn?: ReactNode;
  rightColumn?: ReactNode;
  hideNavigationOnMobile?: boolean;
} & BaseComponent;

const ThreeColumnLayout = ({
  leftColumn,
  centerColumn,
  rightColumn,
  hideNavigationOnMobile = false,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const navigate = useStatefulNavigate();
  const { isCreationPopUpOpen, closeCreationPopUp } = useNavigationStore();

  const popUpOptions = [
    {
      title: t("posts.add"),
      onClick: () => {
        navigate("/post/create");
        closeCreationPopUp();
      },
    },
    {
      title: t("addProduct"),
      onClick: () => {
        navigate("/product/create");
        closeCreationPopUp();
      },
    },
  ];

  const getKey = (location: Location): string | null => {
    return location.key;
  };

  return (
    <div className={classNames("full-height flex flex-col", className)} data-testid={dataTestId}>
      <Sidebar />
      <div className="ml-0 flex grow flex-col sm:ml-20 sm:flex-row lg:ml-60">
        <div className="full-height scrollbar-hide hidden w-80 shrink-0 md:block md:overflow-y-auto" id="leftColumn">
          {leftColumn}
        </div>
        <div className="hidden h-full w-px bg-gray-100 md:block" />
        <div
          className="md:full-height md:rx-4 mx-auto flex min-h-full w-full flex-col md:mx-0 md:block md:overflow-y-auto"
          id="centerColumn"
        >
          {centerColumn}
        </div>
        {Boolean(rightColumn) && (
          <>
            <div className="hidden h-full w-px bg-gray-100 md:block" />
            <div
              className="full-height hidden w-80 shrink-0 chatBreakpoint:block chatBreakpoint:overflow-y-auto chatBreakpoint:px-4"
              id="rightColumn"
            >
              {rightColumn}
            </div>
          </>
        )}
        {!hideNavigationOnMobile && <NavigationBar />}
      </div>

      <PopUp
        title={t("createContent") as string}
        options={popUpOptions}
        isOpen={isCreationPopUpOpen}
        onClose={closeCreationPopUp}
      />
      <ScrollRestoration getKey={getKey} storageKey={window.location.pathname} />
    </div>
  );
};

export default ThreeColumnLayout;
