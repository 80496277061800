import { useInfiniteQuery } from "@tanstack/react-query";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";

export const useRelevantMembers = (listId: string, query: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchRelevantMembers = async ({ pageParam }: { pageParam: string | undefined }) => {
    return maloumClient.chatLists.getRelevantMembersById(listId, pageParam, 15, query);
  };

  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["relevantMembers", query, listId],
    queryFn: fetchRelevantMembers,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
  });

  return {
    relevantMembers: data?.pages.flatMap((page) => page.data) || [],
    isRelevantMembersLoading: isFetching,
    hasNextPage,
    fetchNextPage,
  };
};
