import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { BaseComponent } from "types/components/component.type";
import { useMediaGalleryByCreator } from "hooks/media-gallery/useMediaGalleryByCreator.hook";
import CollectionMedia from "./CollectionMedia";
import { ThreeColMediaGrid } from "../ThreeColMediaGrid";
import { LoadingMedia } from "components/molecules/LoadingMedia";
import { MediaGalleryFilter } from "@neolime-gmbh/api-gateway-client";
import { EmptyGallerySelection } from "components/molecules/media-gallery/EmptyGallerySelection";
import { useTranslation } from "react-i18next";
type Props = {
  creatorId: string;
  filter?: MediaGalleryFilter;
} & BaseComponent;

export const CollectionGrid = ({ creatorId, filter, className }: Props) => {
  const { ref, inView } = useInView();

  const { media, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useMediaGalleryByCreator(
    creatorId,
    filter,
  );

  const { t } = useTranslation();

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) fetchNextPage();
  }, [inView, isLoading]);

  if (!isLoading && media?.length === 0 && !filter) {
    return (
      <div className="flex h-full w-full flex-grow items-center justify-center">
        <EmptyGallerySelection
          title={t("collection.emptyGallery.title") as string}
          description={t("collection.emptyGallery.description")}
        />
      </div>
    );
  }

  if (!isLoading && media?.length === 0 && filter) {
    return (
      <div className="flex h-full w-full flex-grow items-center justify-center">
        <EmptyGallerySelection description={t("collection.emptyGallery.resetFilter")} />
      </div>
    );
  }

  return (
    <ThreeColMediaGrid className={className}>
      {media?.map((m) => (
        <CollectionMedia media={m.media} thumbnail={m.thumbnail} key={m.sentMediaId.toString()} />
      ))}
      {(isLoading || isFetchingNextPage) && Array.from({ length: 8 }).map((_, i) => <LoadingMedia key={i} />)}
      {!isLoading && <div className="absolute bottom-40" ref={ref} />}
    </ThreeColMediaGrid>
  );
};
