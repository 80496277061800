import { FolderDto, VaultMediaDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useUploadStore from "state/uploadState";

// query: search query
// loadData: boolean (load data from the backend) | false (do not load data from the backend)
const useVaultFolders = (query: string, loadData = true) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();
  const { removeUpload } = useUploadStore();

  const createFolder = async (folderName: string) => maloumClient.vaultFolders.createFolder({ name: folderName });

  const createFolderMutation = useMutation({
    mutationFn: createFolder,
    onSuccess: (folder: FolderDto) => {
      queryClient.setQueryData(["vaultFolders", query], (oldData: any) => {
        return {
          ...oldData,
          pages: [
            {
              data: [folder, ...oldData.pages[0].data],
              next: oldData.pages[0].next,
            },
            ...oldData.pages.slice(1),
          ],
        };
      });
      queryClient.setQueryData(["folder", folder._id], folder);
    },
  });

  const deleteFolder = async ({ folderId, deleteMedia }: { folderId: string; deleteMedia: boolean }) => {
    return {
      uploadIds: (await maloumClient.vaultFolders.deleteFolder(folderId, deleteMedia)).deletedUploadIds,
      folderId,
    };
  };

  const deleteFolderMutation = useMutation({
    mutationFn: deleteFolder,
    onSuccess: (response) => {
      queryClient.setQueryData(["vaultFolders", query], (oldData: any) => {
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            data: page.data.filter((f: FolderDto) => f._id !== response.folderId),
            next: page.next,
          })),
        };
      });
      (folders?.pages.flatMap((page) => page.data) ?? []).forEach((f) => {
        const data = queryClient.getQueryData(["vaultMedia", { folderId: f._id, fanId: undefined }]);
        if (!data) return;
        queryClient.setQueryData(["vaultMedia", { folderId: f._id, fanId: undefined }], (prev: any) => ({
          ...prev,
          pages: prev.pages.map((page: any) => {
            return {
              next: page.next,
              data: page.data.filter(
                (media: VaultMediaDto) => response.uploadIds.findIndex((m1) => m1 === media.upload._id) === -1,
              ),
            };
          }),
        }));
      });
      // remove uploads from store
      response.uploadIds.forEach((id) => removeUpload(id));
    },
  });

  const renameFolder = async ({ folderId, newFolderName }: { folderId: string; newFolderName: string }) =>
    maloumClient.vaultFolders.updateFolder(folderId, { name: newFolderName });

  const renameFolderMutation = useMutation({
    mutationFn: renameFolder,
    onSuccess: (folder: FolderDto) => {
      queryClient.setQueryData(["vaultFolders", query], (oldData: any) => {
        return {
          ...oldData,
          pages: oldData.pages.map((page: any) => ({
            data: page.data.map((f: FolderDto) => (f._id === folder._id ? folder : f)),
            next: page.next,
          })),
        };
      });
      queryClient.setQueryData(["folder", folder._id], folder);
    },
  });

  const getVaultFolders = async (next: any) => maloumClient.vaultFolders.findFolders(query, next.pageParam);

  const {
    data: folders,
    isFetching: isLoading,
    hasNextPage,
    fetchNextPage,
    isError,
  } = useInfiniteQuery({
    queryKey: ["vaultFolders", query],
    queryFn: getVaultFolders,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    enabled: loadData,
    initialPageParam: undefined,
  });

  return {
    folders: folders?.pages.flatMap((page) => page.data) ?? [],
    isLoading,
    isError,
    hasNextPage: hasNextPage ?? true,
    fetchNextPage,
    createFolderMutation,
    deleteFolderMutation,
    renameFolderMutation,
  };
};

export default useVaultFolders;
