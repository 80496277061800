import classNames from "classnames";
import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import useFolder from "hooks/vault/useFolder.hook";
import useVaultFolders from "hooks/vault/useVaultFolders.hook";
import useVaultMedia from "hooks/vault/useVaultMedia.hook";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

type Props = {
  currentlySelectedFolderId: string | undefined;
  selectedMedia: TSelectedMedia[];
  clearMedia: () => void;
} & PopUpComponent &
  BaseComponent;

const DeleteMediaPopup = ({
  currentlySelectedFolderId,
  selectedMedia,
  clearMedia,
  open,
  onClose,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { deleteMediaMutation } = useVaultMedia("", undefined, false);
  const { folders } = useVaultFolders("", false);
  const { folder } = useFolder(currentlySelectedFolderId ?? "", false);
  const [surePopupOpen, setSurePopupOpen] = useState(false);

  const { folderId, mediaFromMultipleFolders, currentFolderManaged } = useMemo(() => {
    const folderId = selectedMedia[0].folderId;
    const mediaFromMultipleFolders = selectedMedia.some((s) => s.folderId !== folderId);
    // users can reload the page (in grid) which would result in the folders being undefined so we also include the currently selected folder
    // to check if it is managed or not (if it is not managed, the user can only delete from the entire vault)
    const currentFolderManaged =
      [...folders, folder].filter((f) => f?.isManaged).find((f) => f?._id === folderId) !== undefined;
    return { folderId, mediaFromMultipleFolders, currentFolderManaged };
  }, [selectedMedia]);

  const handleDeleteMedia = (deleteFromEntireVault = false) => {
    const folderToDeleteFrom = deleteFromEntireVault || mediaFromMultipleFolders ? undefined : folderId;
    deleteMediaMutation.mutate(
      { mediaIds: selectedMedia.map((s) => s._id), folderId: folderToDeleteFrom },
      {
        onSuccess: () => {
          clearMedia();
          onClose();
        },
      },
    );
  };

  if (mediaFromMultipleFolders || surePopupOpen || currentFolderManaged)
    return (
      <PopUp isOpen={open} onClose={onClose} title={t("delete") as string} data-testid={dataTestId}>
        <div className={classNames("mt-4", className)}>
          <div>{t("vault.delete.areYouSure")}</div>
          <div className="mt-4 flex gap-2">
            <Button text={t("cancel")} variant="secondary" onClick={onClose} />
            <Button
              text={t("delete")}
              onClick={() => handleDeleteMedia(true)}
              disabled={deleteMediaMutation.isPending}
            />
          </div>
        </div>
      </PopUp>
    );

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("vault.delete.title") as string} data-testid={dataTestId}>
      <div className={classNames("mt-4", className)}>
        <div>{t("vault.delete.removeFromOrDelete")}</div>
        <div className="mt-4 flex flex-col gap-2">
          <Button
            text={t("vault.delete.removeFrom")}
            onClick={() => handleDeleteMedia(false)}
            disabled={deleteMediaMutation.isPending}
          />
          <Button
            text={t("vault.delete.deleteFromAll")}
            variant="outline"
            onClick={() => setSurePopupOpen(true)}
            disabled={deleteMediaMutation.isPending}
          />
        </div>
      </div>
    </PopUp>
  );
};

export default DeleteMediaPopup;
