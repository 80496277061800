import classNames from "classnames";
import SearchInput from "components/molecules/basics/SearchInput";
import ListItem, { LoadingListItem } from "components/molecules/lists/ListItem";
import NoResults from "components/utilities/NoResults";
import useDebounceQuery from "hooks/common/useDebounceQuery.hook";
import useLists from "hooks/lists/useLists.hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { BaseComponent } from "types/components/component.type";

type Props = {
  selectedListId?: string;
  onListSelection: (listId: string) => void;
} & BaseComponent;

const ListList = ({ selectedListId, onListSelection, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const { query, debounceQuery, isTyping, setDebounceQuery } = useDebounceQuery();

  const { lists, isLoading, hasNextPage, fetchNextPage } = useLists(query);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading) fetchNextPage();
  }, [inView, isLoading, hasNextPage]);

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <SearchInput
        value={debounceQuery}
        onChange={setDebounceQuery}
        placeholder={t("lists.search.placeholder") as string}
        className="mb-4"
      />
      <div className="relative flex flex-col gap-1.5 pb-16 xl:gap-0">
        {(isLoading || isTyping) &&
          Array.from({ length: 10 }).map((_, i) => <LoadingListItem key={i} className="xl:mt-1.5" />)}
        {!isTyping &&
          !isLoading &&
          lists.length > 0 &&
          lists.map((list) => (
            <ListItem
              list={list}
              selected={list._id === selectedListId}
              onSelect={() => onListSelection(list._id)}
              key={list._id}
            />
          ))}
        {!!debounceQuery && query && !isTyping && !lists.length && !isLoading && (
          <div>
            <NoResults searchedText={debounceQuery} />
            <div className="text-center text-sm text-gray-500">{t("lists.search.noResults")}</div>
          </div>
        )}
        <div className="absolute bottom-40" ref={ref} />
      </div>
    </div>
  );
};

export default ListList;
