import { ProductDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useFirstline } from "@first-line/firstline-react";

type Props = {
  creator: PublicUserDto;
  viewedByGuest?: boolean;
};
const useCreatorProductFeed = ({ creator, viewedByGuest = false }: Props) => {
  const { user: authUser } = useFirstline();
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const updateProduct = (productToChange: ProductDto) => {
    queryClient.setQueryData(["creator-product-feed", creator._id], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.map((product: ProductDto) =>
              product._id === productToChange._id ? productToChange : product,
            ),
          };
        }),
      };
    });
  };

  const fetchCreatorProductFeed = async (next: any) => {
    return await maloumClient.products.getByCreator(creator.username, next.pageParam, 15);
  };

  const fetchCreatorProductFeedGuest = async (next: any) => {
    return await maloumClient.guest.getProductsByUsername(creator.username, next.pageParam, 15);
  };

  const fetchOwnCreatorProductFeed = async (next: any) => {
    return await maloumClient.products.me(next.pageParam, 15);
  };

  const splitter = async (next: any) => {
    if (!creator.isVerified) return { data: [], next: null };
    if (creator.username === authUser?.username) return fetchOwnCreatorProductFeed(next);
    if (viewedByGuest) return fetchCreatorProductFeedGuest(next);
    return fetchCreatorProductFeed(next);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["creator-product-feed", creator._id],
    queryFn: splitter,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
  });

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage, updateProduct };
};

export default useCreatorProductFeed;
