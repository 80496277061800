import classNames from "classnames";
import useSuggestedCreators from "hooks/useSuggestedCreators.hook";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import SuggestedCreatorCard, { SuggestedCreatorCardLoading } from "./card/SuggestedCreatorCard";

type Props = {
  className?: string;
  "data-testid"?: string;
};

const SuggestedcreatorSection = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { suggestedCreators } = useSuggestedCreators();

  return (
    <article className={classNames("", className)} data-testid={dataTestId}>
      <h2 className="mb-2 px-4 text-2xl font-semibold text-gray-800 md:px-0">{t("feed.suggested")}</h2>
      <ScrollContainer className="flex gap-2 overflow-x-auto px-4 pb-8 md:px-0">
        {suggestedCreators === undefined
          ? Array.from({ length: 4 }, (_, i) => (
              <SuggestedCreatorCardLoading data-testid={`suggested-creator-card-loading-${i}`} key={i} />
            ))
          : suggestedCreators?.map((creator) => (
              <SuggestedCreatorCard
                data-testid={creator.user.username}
                backgroundImage={creator.lastPostMedia}
                profileImage={creator.user.profilePictureThumbnail}
                username={creator.user.username}
                isVerified={creator.user.isVerified}
                key={creator.user.username}
              />
            ))}
      </ScrollContainer>
    </article>
  );
};
export default SuggestedcreatorSection;
