import { DisplayableMediaDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { HiOutlinePhoto, HiOutlineVideoCamera } from "react-icons/hi2";
import { BaseComponent } from "types/components/component.type";
import BaseListItem, { LoadingBaseListItem } from "components/atoms/base/item/BaseListItem";
import { ListItemName } from "components/atoms/ListItemName";
import { ListEntryThumbnails } from "./ListEntryThumbnails";

type Props = {
  name: string;
  selected: boolean;
  onSelect: () => void;
  thumbnails: DisplayableMediaDto[];
  videoCount?: number;
  pictureCount?: number;
} & BaseComponent;

export const ListItem = ({ name, thumbnails, videoCount, pictureCount, selected, onSelect, className }: Props) => {
  return (
    <BaseListItem
      isSelected={selected}
      onClick={onSelect}
      className={classNames("flex items-center justify-between", className)}
    >
      <div className="max-w-[calc(100%-80px)]">
        <ListItemName name={name} className={classNames("truncate", selected && "font-semibold")} />
        <div className={classNames("mt-0.5 flex items-center gap-1", !selected && "text-gray-500")}>
          <div className="flex items-center gap-1">
            <HiOutlinePhoto className="h-4 w-4" />
            <div className="text-xs">{pictureCount ?? 0}</div>
          </div>

          {Boolean(videoCount) && (
            <div className="flex items-center gap-1">
              <HiOutlineVideoCamera className="h-4 w-4" />
              <div className="text-xs">{videoCount}</div>
            </div>
          )}
        </div>
      </div>
      <ListEntryThumbnails thumbnails={thumbnails} />
    </BaseListItem>
  );
};

export const LoadingListEntryItem = ({ className }: BaseComponent) => {
  return <LoadingBaseListItem className={classNames("h-[3.75rem]", className)} />;
};
