import classNames from "classnames";
import { HiPlay } from "react-icons/hi2";

export type VideoPlayIconSize = 8 | 10 | 12;

export const VideoPlayOverlay = ({ className, size = 12 }: { className?: string; size?: VideoPlayIconSize }) => {
  return (
    <div className={classNames("rounded-full bg-black/75 p-2", className)}>
      <HiPlay className={`h-${size} w-${size} translate-x-[2.5px] text-white`} />
    </div>
  );
};
