import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useSearchStore from "../../state/searchState";
import ProfileImage from "../basics/ProfileImage";
import MyInfiniteScroll from "../layouts/MyInfiniteScroll";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import SearchNoResults from "components/utilities/SearchNoResults";
import { useTranslation } from "react-i18next";
import StatefulLink from "components/atoms/utils/StatefulLink";

type TData = {
  next: string | null;
  data: PublicUserDto[];
};

type Props = {
  query: string | null;
  isTyping: boolean;
};

const SearchCreator = ({ query, isTyping = false }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const searchUsername = useCallback(
    (username: string, next: string | null, limit: number) => {
      return maloumClient.users.getByUsername(username, next ?? undefined, limit);
    },
    [maloumClient],
  );

  const setSearchResults = useSearchStore<(value: TData, addToData: boolean) => void>(
    (state) => state.setSearchResults,
  );

  const data = useSearchStore<TData>((state) => state.searchResults);
  const setData = useCallback((newData: TData) => setSearchResults(newData, true), [setSearchResults]);
  const loadNextCreators = useCallback(
    (next: string | null) => (query ? searchUsername(query, next, 20) : null),
    [searchUsername, query],
  );
  const createCreatorComponent = useCallback(
    (creator: PublicUserDto) => (
      <StatefulLink
        to={`/creator/${creator.username}`}
        className={"flex flex-1 items-center gap-3 px-3 py-2 text-xl"}
        key={creator.username}
      >
        <ProfileImage
          url={creator?.profilePictureThumbnail?.url}
          className={"h-12 w-12"}
          uploadStatus={creator?.profilePictureThumbnail?.uploadStatus}
        />
        <span className="notranslate flex items-center gap-x-1">
          {creator.username}
          {creator.isVerified && <VerifiedUserIcon />}
        </span>
      </StatefulLink>
    ),
    [],
  );
  const createLoadingCreatorComponent = useCallback(
    (key: number) => (
      <div key={key} className={"flex flex-1 animate-pulse items-center px-3 py-2 text-xl"}>
        <div className={"mr-3 h-12 w-12 rounded-full bg-gray-200 "} />
        <div className={"h-4 flex-1 rounded-full bg-gray-200"} />
      </div>
    ),
    [],
  );
  const NoCreatorsResultsView = useCallback(
    () => (
      <div className={"py-4"}>
        <SearchNoResults query={query} tab={t("creators")} />
      </div>
    ),
    [],
  );

  return (
    <MyInfiniteScroll
      // @ts-expect-error - worst typed file in the history of typescript
      loadNext={loadNextCreators}
      loadingItemsCount={12}
      createItemComponent={createCreatorComponent}
      createLoadingItemComponent={createLoadingCreatorComponent}
      NoResultsView={NoCreatorsResultsView}
      showLoader={isTyping}
      data={data}
      setData={setData}
    />
  );
};

export default SearchCreator;
