import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";

const useFollowers = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchNextFollowers = async (query: QueryFunctionContext<string[], any>) => {
    return await maloumClient.users.getFollower(query?.pageParam);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["followers"],
    queryFn: fetchNextFollowers,
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: undefined,
  });

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage };
};

export default useFollowers;
