import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import SelectedLists from "components/organisms/lists/SelectedLists";
import PopUp from "components/popup/PopUp";
import useReceivers from "hooks/lists/members/useReceivers.hook";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useSelectedListsState from "state/lists/chat/selectedListsState";
import { ReactComponent as NoFansYetSVG } from "svg/undraw_waiting_for_you.svg";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useLocation } from "react-router-dom";

const NewMessagePage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useStatefulNavigate();

  const { includeLists, excludeLists, addOrRemoveList, reset } = useSelectedListsState();
  const { data: receivers, isLoading } = useReceivers(
    includeLists.map((l) => l._id),
    excludeLists.map((l) => l._id),
  );

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const handleMessageToClick = () => {
    if (!receivers || receivers === 0) {
      setIsPopUpOpen(true);
    } else {
      navigate(`/chat/new/message`);
    }
  };

  const handleGoToSelection = (type: "include" | "exclude") => () => {
    const hideListIds = type === "include" ? excludeLists.map((l) => l._id) : includeLists.map((l) => l._id);
    navigate(`/lists/${type}`, { state: { hideListIds: hideListIds } });
  };

  const handleRemoveList = (type: "include" | "exclude") => (list: ChatListDto) => {
    addOrRemoveList(list, type);
  };

  // reset selected lists on mount if no keepSelection is set in state
  useEffect(() => {
    if (state?.keepSelection) return;
    reset();
    navigate(".", { state: { keepSelection: true }, replace: true });
  }, []);

  return (
    <>
      <Layout hideNavigationMobile>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.Cancel />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("newMessage.title")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container as="section" className="pt-4">
          <div className="font-semibold">{t("newMessage.sendTo")}</div>
          <div className="mt-0.5 text-sm">{t("newMessage.sendToDescription")}</div>
          <SelectedLists
            lists={includeLists}
            removeList={handleRemoveList("include")}
            goToListSelection={handleGoToSelection("include")}
            className="mt-4"
          />

          <div className="mt-10 font-semibold">{t("newMessage.exclude")}</div>
          <div className="mt-0.5 text-sm">{t("newMessage.excludeDescription")}</div>
          <SelectedLists
            lists={excludeLists}
            removeList={handleRemoveList("exclude")}
            goToListSelection={handleGoToSelection("exclude")}
            className="mt-4"
          />

          <div className="mb-12 grow" />
          <Button
            text={!isLoading ? t("newMessage.messageMembers", { count: receivers || 0 }) : undefined}
            IconFront={
              isLoading
                ? ({ className }: { className: string }) => (
                    <AiOutlineLoading3Quarters className={classNames("animate-spin", className)} />
                  )
                : undefined
            }
            className="mb-4"
            onClick={handleMessageToClick}
            disabled={isLoading}
          />
        </Container>
      </Layout>
      <PopUp isOpen={isPopUpOpen} onClose={() => setIsPopUpOpen(false)} title={t("newMessage.noReceivers") as string}>
        <div className="flex flex-col items-center justify-center gap-4">
          <NoFansYetSVG width="60%" className="my-4" />
          <span className="text-gray-700">{t("newMessage.noReceiversDescription")}</span>
          <Button onClick={() => setIsPopUpOpen(false)} text={t("media.gotIt")} />
        </div>
      </PopUp>
    </>
  );
};

export default NewMessagePage;
