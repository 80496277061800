import { Tab } from "@headlessui/react";
import { InboxType, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Callout from "components/atoms/Callout";
import UnreadMessagesBadge from "components/atoms/UnreadMessagesBadge";
import { Fragment, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import ChatsList from "./ChatsList";
import unreadChatsCounts from "state/unreadChatsState";
import { useChats } from "hooks/chats/useChats.hook";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { HiOutlineChartBarSquare } from "react-icons/hi2";
import HeaderBar from "components/layouts/HeaderBar";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { ChatFilter } from "state/chatFilterState";
import { useInboxType } from "hooks/chats/useInboxType.hook";
import useChatFilterState from "state/chatFilterState";
import useUserStore from "state/userState";
import ScheduledBroadcastMessages from "./ScheduledBroadcastMessages";
import classNames from "classnames";
import PillSelection from "components/atoms/PillSelection";

export const ChatOverview = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const { unreadChatsCount, unreadRequestsCount } = unreadChatsCounts();

  const tabs = [InboxType.PRIMARY, InboxType.REQUEST];

  const tabName = searchParams.get("tab")?.toUpperCase() as InboxType | null;
  const isValidTab = tabName && tabs.includes(tabName);
  const selectedIndex = isValidTab ? tabs.indexOf(tabName) : 0;

  const { chats, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useChats(tabs[selectedIndex]);

  const onTabChange = (tab: number) => {
    setSearchParams({ tab: tabs[tab].toLowerCase() }, { replace: true });
  };
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isLoading, isFetchingNextPage]);

  const inboxType = useInboxType();

  const { filter, setFilter } = useChatFilterState();

  const handleFilterClick = (clickedFilter: ChatFilter) => {
    if (filter === clickedFilter) {
      return;
    }
    setFilter(clickedFilter);
  };

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
      <div className="hide-scrollbar relative mx-auto flex w-full max-w-xl flex-col items-start gap-3 pt-4 md:pt-5">
        <div
          className={classNames(
            "sticky top-0 z-50 mx-auto flex w-full max-w-xl flex-col gap-2 bg-white px-4",
            !user.isCreator && "gap-4",
          )}
        >
          <HeaderBar>
            <HeaderBar.SubPage className="!px-0 !pt-0">
              <HeaderBar.MainPageHeading className="-mb-1.5 !pb-0 !pt-[5.91px] text-xl font-semibold !text-gray-700">
                {t("navigation.chat")}
              </HeaderBar.MainPageHeading>
              {user.isCreator && (
                <HeaderBar.MainPageActions className="items-center">
                  <HeaderBar.Link to={user.isVerified ? "/chat/new" : "/verification/start"} className="md:!p-0.5">
                    <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                  <HeaderBar.Link
                    to={user.isVerified ? "/chat/broadcasts" : "/verification/start"}
                    className="md:!p-0.5"
                  >
                    <HiOutlineChartBarSquare className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                  <HeaderBar.Link to={user.isVerified ? "/chat/settings" : "/verification/start"} className="md:!p-0.5">
                    <HiOutlineCog6Tooth className="h-6 w-6 text-gray-800" />
                  </HeaderBar.Link>
                </HeaderBar.MainPageActions>
              )}
            </HeaderBar.SubPage>
          </HeaderBar>
          <div className="box-border h-[1px] w-full bg-gray-100" />
        </div>

        {!user?.isCreator && (
          <Tab.List className="flex w-full justify-around md:px-4">
            <InboxTab>
              <InboxTabHeader header={t("chats.primary.title")} unreadCount={unreadChatsCount} />
            </InboxTab>
            <InboxTab>
              <InboxTabHeader header={t("chats.request.title")} unreadCount={unreadRequestsCount} />
            </InboxTab>
          </Tab.List>
        )}

        {inboxType === InboxType.PRIMARY && (
          <div className="flex items-center gap-1.5 px-4">
            <PillSelection
              text={t("chats.filter.all")}
              selected={!filter}
              onClick={() => handleFilterClick(undefined)}
            />
            <PillSelection
              text={t("chats.filter.unread")}
              selected={filter === "unread"}
              onClick={() => handleFilterClick("unread")}
            />
          </div>
        )}

        {user?.isCreator ? (
          <>
            <ScheduledBroadcastMessages />
            <ChatsList chats={chats} bottomRef={ref} isLoading={isLoading} />
          </>
        ) : (
          <Tab.Panels className="flex w-full flex-grow flex-col gap-2">
            <Tab.Panel className="flex flex-grow flex-col">
              <ChatsList chats={chats} bottomRef={ref} isLoading={isLoading} />
            </Tab.Panel>
            <Tab.Panel className="flex flex-grow flex-col">
              <div className="mx-4 mb-3">
                <Callout className="rounded-lg bg-beige-400 p-4">{t("chats.request.info")}</Callout>
              </div>
              <ChatsList chats={chats} bottomRef={ref} isLoading={isLoading} />
            </Tab.Panel>
          </Tab.Panels>
        )}
      </div>
    </Tab.Group>
  );
};

const InboxTab = ({ children }: PropsWithChildren) => {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          className={classNames(
            "flex flex-1 flex-col items-center justify-center gap-2 border-b pb-2 outline-none transition-all",
            {
              "border-b-gray-700 text-gray-700": selected,
              "border-gray-100 text-gray-500": !selected,
            },
          )}
        >
          {children}
        </button>
      )}
    </Tab>
  );
};

const InboxTabHeader = ({ header, unreadCount }: { header: string; unreadCount: number }) => {
  return (
    <div className="flex items-center gap-1">
      <span>{header}</span>
      <UnreadMessagesBadge counter={unreadCount} />
    </div>
  );
};
