import { useContext } from "react";
import {
  DeleteMessageDto,
  MessageContentType,
  MessageDto,
  SendMessageDto,
  TextMessageContentDto,
  MediaMessageContentDto,
  DisplayableMediaDto,
  ChatProductMessageContentDto,
} from "@neolime-gmbh/api-gateway-client";
import SocketContext from "contexts/SocketContext";
import { useMessagesQuery } from "./queries/useMessagesQuery.hook";
import { useMessagesQueryManipulator } from "./queries/useMessagesQueryManipulator.hook";
import { v4 as uuidv4 } from "uuid";
import { MessageContentDto } from "types/messages/message.type";
import useUserStore from "state/userState";
import { Prettify } from "types/utils.types";

export type SendMediaDto = Prettify<
  DisplayableMediaDto & {
    mediaId: string;
    thumbnail: DisplayableMediaDto;
  }
>;

const useSendMessage = (chatId: string) => {
  const { data: messagesData } = useMessagesQuery(chatId);
  const messages = messagesData?.pages.flatMap((p) => p.data) || [];
  const { user } = useUserStore();

  const socket = useContext(SocketContext);
  const { addMessageToMessagesQuery, removeMessageFromMessagesQuery } = useMessagesQueryManipulator();

  const emitSendMessageEvent = (message: SendMessageDto) => {
    socket?.emit("send_message", {
      chat: message.chat,
      content: message.content,
      optimisticMessageId: message.optimisticMessageId,
    });
  };

  const deleteMessage = ({
    messageId,
    deleteTextOnly,
    deleteBroadcastMessage,
  }: {
    messageId: string;
    deleteTextOnly: boolean;
    deleteBroadcastMessage?: boolean;
  }) => {
    if (deleteTextOnly) {
      const messagesTmp = messages;
      const msgIndex = messagesTmp.findIndex((m) => m._id === messageId);

      const message = messagesTmp[msgIndex];

      if ("text" in message.content) {
        delete message.content.text;
      }
    } else {
      removeMessageFromMessagesQuery({ chatId, messageId });
    }
    socket?.emit("delete_message", {
      chat: chatId,
      message: messageId,
      deleteTextOnly,
    } satisfies DeleteMessageDto);
  };

  const sendTextMessage = (message: string) => {
    const optimisticMessage = createOptimisticMessage({
      type: MessageContentType.TEXT,
      text: message,
    } satisfies TextMessageContentDto);

    addMessageToMessagesQuery(optimisticMessage);

    const sendMessageDto = {
      chat: chatId,
      content: {
        type: "text",
        text: message,
      },
      optimisticMessageId: optimisticMessage._id,
    } satisfies SendMessageDto;

    emitSendMessageEvent(sendMessageDto);
  };

  const sendMediaMessage = (sendMediaDto: SendMediaDto[], message: string | undefined) => {
    const optimisticMessage = createOptimisticMessage({
      type: MessageContentType.MEDIA,
      media: sendMediaDto satisfies DisplayableMediaDto[],
      thumbnails: sendMediaDto.map((m) => m.thumbnail),
      text: message,
    } satisfies MediaMessageContentDto);

    addMessageToMessagesQuery(optimisticMessage);

    emitSendMessageEvent({
      chat: chatId,
      content: {
        type: "media",
        text: message,
        mediaIds: sendMediaDto.map((m) => m.mediaId),
      },
      optimisticMessageId: optimisticMessage._id,
    });
  };

  const sendChatProduct = async (media: SendMediaDto[], netAmount: number, message?: string) => {
    const optimisticMessage = createOptimisticMessage({
      type: MessageContentType.CHAT_PRODUCT,
      media: media satisfies DisplayableMediaDto[],
      thumbnails: media.map((m) => m.thumbnail),
      text: message,
      price: {
        net: netAmount,
        gross: undefined,
      },
    } satisfies ChatProductMessageContentDto);

    addMessageToMessagesQuery(optimisticMessage);

    emitSendMessageEvent({
      chat: chatId,
      content: {
        type: "chat_product",
        mediaIds: media.map((m) => m.mediaId),
        priceNet: netAmount,
        ...(message !== undefined && { text: message }),
      },
      optimisticMessageId: optimisticMessage._id,
    });
  };

  const createOptimisticMessage = (content: MessageContentDto): MessageDto => ({
    _id: uuidv4(), // Temporary ID
    chat: chatId,
    content,
    sentAt: new Date().toISOString(),
    senderId: user._id,
    readAt: new Date().toISOString(),
    isBroadcasted: false,
    isDeleted: false,
    isBought: false,
  });

  return { sendTextMessage, sendMediaMessage, sendChatProduct, deleteMessage };
};

export default useSendMessage;
