import { FolderDto } from "@neolime-gmbh/api-gateway-client";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import HeaderBar from "components/layouts/HeaderBar";
import EditVaultFolderPopup from "components/molecules/vault/popups/edit/EditVaultFolderPopup";
import VaultMediaGrid from "components/organisms/vault/VaultMediaGrid";
import { mapUploadToSelectedMedia } from "helper/mediaHelper";
import useFolder from "hooks/vault/useFolder.hook";
import useVaultMedia from "hooks/vault/useVaultMedia.hook";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineEllipsisHorizontal } from "react-icons/hi2";
import { useQueryClient } from "@tanstack/react-query";
import useUploadStore from "state/uploadState";
import { BaseComponent } from "types/components/component.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import { ListItemName } from "components/atoms/ListItemName";
import { ThreeColMediaGridLoading } from "components/organisms/ThreeColMediaGrid";

type Props = {
  folderId: string;
  isSelectingMode: boolean;
  allowMultipleSelect: boolean;
  onBack: () => void;
  onCancel: () => void;
  selectedMedia: TSelectedMedia[];
  handleSelectMedia: (media: TSelectedMedia) => void;
  handleSelectAllMedia: (media: TSelectedMedia[]) => void;
  handleUnselectAllMedia: (media: TSelectedMedia[]) => void;
};

const FolderDetail = ({
  folderId,
  isSelectingMode,
  allowMultipleSelect,
  onBack,
  onCancel,
  selectedMedia,
  handleSelectMedia,
  handleSelectAllMedia,
  handleUnselectAllMedia,
}: Props) => {
  const queryClient = useQueryClient();
  const { folder } = useFolder(folderId);
  const { media, hasNextPage, isLoading } = useVaultMedia(folderId);
  const { uploads } = useUploadStore();

  const { relevantUploads, selectAllEnabled, allMediaInFolderSelected } = useMemo(() => {
    const relevantUploads = folder?.isManaged ? uploads : uploads.filter((u) => u.folderIds?.includes(folderId));

    const selectAllEnabled = folder
      ? (!isSelectingMode || allowMultipleSelect) &&
        !folder.isManaged &&
        !isLoading &&
        media.length + relevantUploads.length > 0 &&
        media.length + relevantUploads.length <= 50 &&
        !hasNextPage
      : false;

    if (selectedMedia.length === 0 || isLoading || hasNextPage) {
      return { relevantUploads, selectAllEnabled, allMediaInFolderSelected: false };
    }

    const selectedMediaIds = selectedMedia.map((m) => m._id);
    const mediaIds = media.map((m) => m.upload._id);
    const uploadIds = relevantUploads.map((u) => u.id);
    const allMediaInFolderIds: string[] = [...mediaIds, ...uploadIds];
    const allMediaInFolderSelected = allMediaInFolderIds.filter((id) => !selectedMediaIds.includes(id)).length === 0;

    return { relevantUploads, selectAllEnabled, allMediaInFolderSelected };
  }, [folder, isSelectingMode, allowMultipleSelect, isLoading, media, uploads, hasNextPage, selectedMedia]);

  const handleSelectAll = () => {
    if (selectAllEnabled) {
      const uploadsInFolder = relevantUploads.map((u) => {
        const thumbnailData: { thumbnail: string; size?: { width: number; height: number } | undefined } | undefined =
          queryClient.getQueryData(["videoThumbnail", u.id]);
        const thumbnail = thumbnailData?.thumbnail;
        const size = thumbnailData?.size ?? { width: 0, height: 0 };

        return mapUploadToSelectedMedia(u, thumbnail, size);
      });
      const mediaInFolder = media.map((m) => ({ _id: m.upload._id, ...m.media, thumbnail: m.thumbnail }));

      if (allMediaInFolderSelected) {
        handleUnselectAllMedia([...uploadsInFolder, ...mediaInFolder]);
      } else {
        handleSelectAllMedia([...uploadsInFolder, ...mediaInFolder]);
      }
    }
  };

  if (!folder) return <>{isSelectingMode ? <LoadingFolderDetailSelecting /> : <LoadingFolderDetail />}</>;

  return (
    <div>
      <FolderDetailHeader
        folder={folder}
        selectAllEnabled={selectAllEnabled}
        allMediaInFolderSelected={allMediaInFolderSelected}
        isSelectingMode={isSelectingMode}
        onBack={onBack}
        onCancel={onCancel}
        handleSelectAll={handleSelectAll}
      />

      <VaultMediaGrid folder={folder} selectedMedia={selectedMedia} handleSelectMedia={handleSelectMedia} />
    </div>
  );
};

type FolderDetailHeaderProps = {
  folder: FolderDto;
  selectAllEnabled: boolean;
  allMediaInFolderSelected: boolean;
  isSelectingMode: boolean;
  onBack: () => void;
  onCancel: () => void;
  handleSelectAll: () => void;
} & BaseComponent;

const FolderDetailHeader = ({
  folder,
  isSelectingMode,
  allMediaInFolderSelected,
  selectAllEnabled,
  onBack,
  onCancel,
  handleSelectAll,
  className,
  "data-testid": dataTestId,
}: FolderDetailHeaderProps) => {
  const { t } = useTranslation();
  const [folderEditPopupOpen, setFolderEditPopupOpen] = useState(false);

  const folderName = folder.isManaged ? t(`vault.managedFolders.${folder.name}`) : folder.name;

  if (!isSelectingMode) {
    return (
      <>
        <HeaderBar className={className} data-testid={dataTestId} alwaysShowDividerXL>
          <HeaderBar.SubPage>
            <HeaderBar.Left className="md:ml-0 md:grow">
              <span className="hidden text-xl font-semibold md:block">
                <ListItemName name={folderName} className="py-2" />
              </span>
              <HeaderBar.BackButton className="md:hidden" onClick={onBack} />
            </HeaderBar.Left>
            <HeaderBar.Center className="md:hidden">
              <HeaderBar.Title>
                <ListItemName name={folderName} className="py-2" />
              </HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right className="md:basis-0">
              <>
                {selectAllEnabled && (
                  <TernaryButton
                    onClick={() => handleSelectAll()}
                    variant="red"
                    size="sm"
                    className="hidden items-center whitespace-nowrap pr-1 md:flex"
                  >
                    {allMediaInFolderSelected ? t("vault.unselectAll") : t("vault.selectAll")}
                  </TernaryButton>
                )}
                {!folder.isManaged && (
                  <TernaryButton onClick={() => setFolderEditPopupOpen(true)}>
                    <HiOutlineEllipsisHorizontal className="h-6 w-6" />
                  </TernaryButton>
                )}
              </>
              <div className="h-11" />
            </HeaderBar.Right>
          </HeaderBar.SubPage>
        </HeaderBar>
        {selectAllEnabled && (
          <div className="flex items-center justify-end border-b border-gray-100 md:hidden">
            <TernaryButton onClick={() => handleSelectAll()} variant="red" size="sm">
              {allMediaInFolderSelected ? t("vault.unselectAll") : t("vault.selectAll")}
            </TernaryButton>
          </div>
        )}
        <EditVaultFolderPopup
          folder={folder}
          open={folderEditPopupOpen}
          onClose={() => setFolderEditPopupOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:-ml-6">
            <HeaderBar.Cancel className="hidden md:block" onClick={onCancel} />
            <HeaderBar.BackButton className="md:hidden" onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>
              <ListItemName name={folderName} className="py-2" />
            </HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="flex items-center justify-end border-b border-gray-100 md:justify-between md:py-2">
        <ListItemName name={folderName} className="hidden text-xl font-semibold md:block" />
        {selectAllEnabled && (
          <TernaryButton
            onClick={() => handleSelectAll()}
            variant="red"
            size="sm"
            className="whitespace-nowrap md:py-0"
          >
            {allMediaInFolderSelected ? t("vault.unselectAll") : t("vault.selectAll")}
          </TernaryButton>
        )}
      </div>
    </>
  );
};

const LoadingFolderDetail = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:ml-0">
            <div className="hidden h-6 w-32 rounded-md bg-gray-100 text-xl font-semibold md:block" />
            <HeaderBar.BackButton className="md:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center className="md:hidden">
            <div className="mx-auto h-5 w-24 rounded-md bg-gray-100" />
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0">
            <div className="m-2 h-6 w-6 rounded-md bg-gray-100" />
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <ThreeColMediaGridLoading className={className} data-testid={dataTestId} />
    </div>
  );
};

const LoadingFolderDetailSelecting = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:ml-0">
            <div className="hidden h-5 w-24 rounded-md bg-gray-100 md:block" />
            <HeaderBar.BackButton className="md:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <div className="mx-auto h-5 w-24 rounded-md bg-gray-100" />
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0 h-11" />
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="hidden h-[61px] items-center border-b border-gray-100 md:flex">
        <div className="h-6 w-32 rounded-md bg-gray-100" />
      </div>
      <ThreeColMediaGridLoading className={className} data-testid={dataTestId} />
    </div>
  );
};

export default FolderDetail;
export { LoadingFolderDetail };
