import { RelatedUserDtoKeysetResponse } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

export type MembersQueryData = InfiniteData<RelatedUserDtoKeysetResponse, number | undefined>;

const useMembers = (listId: string, query: string, fetchData = true) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const queryClient = useQueryClient();

  const removeMemberFromQuery = async (memberId: string) => {
    queryClient.setQueryData<MembersQueryData>(["members", query, listId], (oldData) => {
      if (!oldData) return oldData;

      const filteredPages = oldData.pages
        .map((page) => ({
          ...page,
          data: page.data.filter((member) => member.user._id !== memberId),
        }))
        .filter((page) => page.data.length > 0);

      return {
        pages: filteredPages,
        pageParams: oldData.pageParams.slice(0, filteredPages.length),
      };
    });
  };

  const fetchMembers = async ({ pageParam }: { pageParam: number | undefined }) => {
    return maloumClient.chatLists.getMembersById(listId, query, pageParam, 15);
  };

  const {
    data: members,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["members", query, listId],
    queryFn: fetchMembers,
    getPreviousPageParam: (firstPage) => (firstPage.next ? parseInt(firstPage.next) : undefined),
    getNextPageParam: (lastPage) => (lastPage.next ? parseInt(lastPage.next) : undefined),
    enabled: fetchData,
    initialPageParam: undefined,
  });

  return {
    members: members?.pages.flatMap((page) => page.data) || [],
    isMembersLoading: isFetching,
    hasNextPage,
    fetchNextPage,
    removeMemberFromQuery,
  };
};

export default useMembers;
