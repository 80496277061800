import { BaseComponent } from "types/components/component.type";

type Props = {
  name: string;
} & BaseComponent;

export const ListItemName = ({ name, className }: Props) => {
  return (
    <div title={name} className={className}>
      <span>{name}</span>
    </div>
  );
};
