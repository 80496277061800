import { VaultMediaDto } from "@neolime-gmbh/api-gateway-client";
import Pill from "components/atoms/Pill";
import { useTranslation } from "react-i18next";
import { HiOutlineBanknotes, HiOutlinePaperAirplane } from "react-icons/hi2";
import useCurrency from "hooks/useCurrency.hook";

type Props = {
  media: VaultMediaDto;
};

const VaultMediaStatusPill = ({ media }: Props) => {
  const { t } = useTranslation();
  const { displayCurrencyWithThousands } = useCurrency();

  const getStatusConfig = () => {
    if (media.boughtForPrice) {
      return {
        content: (
          <>
            <span className="hidden lg:block">
              {t("vault.status.boughtForPriceDesktop", { price: displayCurrencyWithThousands(media.boughtForPrice) })}
            </span>
            <span className="block lg:hidden">{displayCurrencyWithThousands(media.boughtForPrice)}</span>
          </>
        ),
        bgColor: "bg-green-sea",
        Icon: HiOutlineBanknotes,
      };
    }

    if (media.sentForFree) {
      return {
        content: <span>{t("vault.status.sentForFree")}</span>,
        bgColor: "bg-red-900",
        Icon: HiOutlinePaperAirplane,
      };
    }

    if (media.sentForPrice) {
      return {
        content: (
          <>
            <span className="hidden lg:block">
              {t("vault.status.sentForPriceDesktop", { price: displayCurrencyWithThousands(media.sentForPrice) })}
            </span>
            <span className="block lg:hidden">{displayCurrencyWithThousands(media.sentForPrice)}</span>
          </>
        ),
        bgColor: "bg-gray-haiti",
        Icon: HiOutlinePaperAirplane,
      };
    }

    return null;
  };

  const config = getStatusConfig();
  if (!config) return null;

  return (
    <Pill
      size="sm"
      className="absolute bottom-2 right-2 flex items-center gap-1 text-white opacity-90"
      colors={config.bgColor}
    >
      <config.Icon className="h-4 w-4" />
      {config.content}
    </Pill>
  );
};

export default VaultMediaStatusPill;
