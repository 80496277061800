import classNames from "classnames";
import { LoadingMedia } from "components/molecules/LoadingMedia";
import { PropsWithChildren } from "react";
import { BaseComponent } from "types/components/component.type";

export const ThreeColMediaGrid = ({
  children,
  className,
}: PropsWithChildren<BaseComponent>) => {
  return (
    <div className={classNames("relative flex h-full flex-col", className)}>
      <div className="relative grid grid-cols-3 gap-px pb-16">{children}</div>
    </div>
  );
};

export const ThreeColMediaGridLoading = ({ className }: BaseComponent) => {
  return (
    <ThreeColMediaGrid className={className}>
      {Array.from({ length: 12 }).map((_, i) => (
        <LoadingMedia key={i} />
      ))}
    </ThreeColMediaGrid>
  );
};
