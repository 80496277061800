import { MediaUploadStatus, UploadType } from "@neolime-gmbh/api-gateway-client";
import { TUpload } from "types/uploads.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

function mapUploadToSelectedMedia(
  upload: TUpload,
  thumbnail: string | undefined,
  size: { width: number; height: number },
): TSelectedMedia {
  return {
    _id: upload.id,
    url: URL.createObjectURL(upload.media),
    type: upload.type === "image" ? UploadType.PICTURE : UploadType.VIDEO,
    uploadStatus: MediaUploadStatus.FINISHED,
    width: size.width,
    height: size.height,
    thumbnail: {
      url: thumbnail ?? URL.createObjectURL(upload.media),
      uploadStatus: MediaUploadStatus.FINISHED,
      type: upload.type === "image" ? UploadType.PICTURE : UploadType.VIDEO,
    },
  };
}

export { mapUploadToSelectedMedia };
