import { MessageDto } from "@neolime-gmbh/api-gateway-client";
import { useQueryClient } from "@tanstack/react-query";
import { InfiniteData } from "@tanstack/react-query";

export const useMessagesQueryManipulator = () => {
  const queryClient = useQueryClient();

  const addMessageToMessagesQuery = (message: MessageDto) => {
    queryClient.setQueryData(["fetch-messages", message.chat], (old: InfiniteData<{ data: MessageDto[] }>) => {
      if (!old) return old;

      return {
        ...old,
        pages: old.pages.map((page, index) => {
          if (index === 0) {
            return {
              ...page,
              data: [message, ...page.data],
            };
          }
          return page;
        }),
      };
    });
  };

  const replaceMessageInMessagesQuery = (message: MessageDto) => {
    queryClient.setQueryData(["fetch-messages", message.chat], (old: InfiniteData<{ data: MessageDto[] }>) => {
      if (!old) return old;

      const newPages = old.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((m) => (m._id === message._id ? message : m)),
        };
      });

      return {
        ...old,
        pages: newPages,
      };
    });
  };

  const updateOptimisticMessage = (message: MessageDto, optimisticMessageId: string) => {
    queryClient.setQueryData(["fetch-messages", message.chat], (old: InfiniteData<{ data: MessageDto[] }>) => {
      if (!old) return old;

      const idOfMessageToReplace = optimisticMessageId ?? message._id;

      const newPages = old.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((m) => (m._id === idOfMessageToReplace ? { ...m, _id: message._id } : m)),
        };
      });

      return {
        ...old,
        pages: newPages,
      };
    });
  };

  const markMessageAsFailed = ({ chatId, messageId }: { chatId: string; messageId: string }) => {
    queryClient.setQueryData(["fetch-messages", chatId], (old: InfiniteData<{ data: MessageDto[] }>) => {
      if (!old) return old;

      const newPages = old.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((m) => (m._id === messageId ? { ...m, failedDelivery: true } : m)),
        };
      });

      return {
        ...old,
        pages: newPages,
      };
    });
  };

  const refetchMessagesQuery = (chatId: string) => {
    queryClient.invalidateQueries({ queryKey: ["fetch-messages", chatId], refetchType: "all" });
  };

  const removeMessageFromMessagesQuery = ({ chatId, messageId }: { chatId: string; messageId: string }) => {
    queryClient.setQueryData(["fetch-messages", chatId], (old: InfiniteData<{ data: MessageDto[] }>) => {
      if (!old) return old;

      return {
        ...old,
        pages: old.pages.map((page) => {
          return {
            ...page,
            data: page.data.filter((m) => m._id !== messageId),
          };
        }),
      };
    });
  };

  const doesMessageExistInMessagesQuery = (chatId: string, messageId: string) => {
    const messages = queryClient.getQueryData<InfiniteData<{ data: MessageDto[] }>>(["fetch-messages", chatId]);
    return messages?.pages.flatMap((page) => page.data).some((m) => m._id === messageId);
  };

  const invalidateAndRefetchIfExistsInMessagesQuery = (chatId: string) => {
    const data = queryClient.getQueryData<InfiniteData<{ data: MessageDto[] }>>(["fetch-messages", chatId]);
    if (data) {
      queryClient.invalidateQueries({ queryKey: ["fetch-messages", chatId] });
    }
  };

  return {
    addMessageToMessagesQuery,
    refetchMessagesQuery,
    removeMessageFromMessagesQuery,
    replaceMessageInMessagesQuery,
    updateOptimisticMessage,
    markMessageAsFailed,
    doesMessageExistInMessagesQuery,
    invalidateAndRefetchIfExistsInMessagesQuery,
  };
};
