import { DisplayableMediaDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Media from "components/media/Media";
import { BaseComponent } from "types/components/component.type";

type Props = {
  media: DisplayableMediaDto;
  thumbnail: DisplayableMediaDto;
} & BaseComponent;

const CollectionMedia = ({ media, thumbnail, className }: Props) => {
  return (
    <div className={classNames("relative aspect-[3/4] w-full overflow-hidden", className)}>
      <Media media={media} thumbnail={thumbnail} />
    </div>
  );
};

export default CollectionMedia;

type CollectionMediaLoadingProps = {
  className?: string;
};

const CollectionMediaLoading = ({ className }: CollectionMediaLoadingProps) => {
  return (
    <div className={classNames("aspect-[3/4] w-full animate-pulse bg-gray-100", className)} />
  );
};

export { CollectionMediaLoading };
