import { InboxType } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import useMaloumOfficial from "hooks/useMaloumOfficial.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext } from "react";
import useQueryManipulator from "./useQueryManipulator";
import { useLocation } from "react-router-dom";

const useChat = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const navigate = useStatefulNavigate();
  const { state } = useLocation();
  const { maloumOfficial } = useMaloumOfficial();
  const { setChatData } = useQueryManipulator();

  const findOrCreateChat = async (member2Id: string) => {
    return await maloumClient.chats
      .findOrCreateChat({ member2: member2Id })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        // TODO handle error
        return err;
      });
  };

  const openChat = async (userId: string, replace?: boolean) => {
    const chat = await findOrCreateChat(userId);
    setChatData(chat._id, chat);
    navigate(`/chat/${chat._id}`, {
      replace: replace ? true : false,
      state: {
        ...(state ?? {}),
        ...(chat.inbox === InboxType.REQUEST
          ? {
              data: {
                mockPrimary: true,
              },
            }
          : {}),
      },
    });
  };

  const openChatWithMaloumOfficial = async (replace?: boolean) => {
    openChat(maloumOfficial!._id, replace);
  };

  return { openChat, openChatWithMaloumOfficial };
};

export default useChat;
