import { Tab } from "@headlessui/react";
import MyTab from "components/basics/MyTab";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Followers from "./Followers";
import Subscribers from "./Subscribers";

const Fans = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs = ["followers", "subscribers"];

  // we need to put the result of searchParams.get("tab") in separate const, because otherwise typescript-error is thrown  during useState assignment
  const tabName = searchParams.get("tab");
  const [selectedIndex, setSelectedIndex] = useState(tabName ? tabs.indexOf(tabName) : 0);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedIndex(tab);
      setSearchParams({ tab: tabs[tab] }, { replace: true });
    },
    [setSearchParams, tabs, setSelectedIndex],
  );

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("fans.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false}>
        <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
          <Tab.List className={"flex justify-around"}>
            <MyTab title={t("fans.followers") as string} />
            <MyTab title={t("fans.subscribers") as string} />
          </Tab.List>
          <Tab.Panels className="px-4 md:px-0">
            <Tab.Panel className={"focus:outline-none"}>
              <Followers className="mt-4" />
            </Tab.Panel>
            <Tab.Panel>
              <Subscribers className="mt-4" />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </Layout>
  );
};

export default Fans;
