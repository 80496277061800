import { useTranslation } from "react-i18next";

const useCurrency = () => {
  const { i18n } = useTranslation();

  const displayCurrency = (value: number) => {
    if (!value) return `${(0).toFixed(2)} €`;
    if (i18n.language?.includes("de")) {
      return `${value.toFixed(2).replace(".", ",")} €`;
    } else {
      return `${value.toFixed(2)} €`;
    }
  };

  const displayCurrencyWithThousands = (value?: number) => {
    if (!value) return "0 €";

    const isGerman = i18n.language?.includes("de");
    const thousandSeparator = isGerman ? "." : ",";

    if (value >= 10000) {
      const formatted = (value / 1000).toFixed(1);
      return `${formatted.replace(".", thousandSeparator)}k €`;
    }

    return (
      new Intl.NumberFormat(i18n.language, {
        maximumFractionDigits: 0,
      }).format(value) + " €"
    );
  };

  return { displayCurrency, displayCurrencyWithThousands };
};

export default useCurrency;
