import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import UploadSpinner from "components/media/UploadSpinner";
import useUpload from "hooks/useUpload.hook";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiPencil } from "react-icons/hi2";
import useUploadStore from "state/uploadState";
import useUserStore from "../../state/userState";
import ProfileImage from "../basics/ProfileImage";
import PopUp from "../popup/PopUp";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  selectedImage?: File | Blob | null;
  setSelectedImage: (value: File | Blob | null) => void;
  imageUrl?: string;
  setProfilePictureId: (value: string | null) => void;
  title: string;
  mediaIsUploaded: boolean;
  setMediaIsUploaded: (value: boolean) => void;
  userIsVerified: boolean;
};

const EditProfileImage = ({
  selectedImage,
  setSelectedImage,
  imageUrl,
  setProfilePictureId,
  title,
  mediaIsUploaded,
  setMediaIsUploaded,
  userIsVerified,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const hiddenInputField = useRef<HTMLInputElement>(null);
  const user = useUserStore((state) => state.user);

  const { handleUpload, imageMimeTypes, getMediaDimensions } = useUpload();
  const acceptTypes = imageMimeTypes.join(", ");
  const uploads = useUploadStore((state) => state.uploads);
  const resetUploads = useUploadStore((state) => state.resetUploads);

  const [showProfileImagePopup, setShowProfileImagePopup] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);
  const profilePictureOptions = [
    {
      title: t("profilePicture.upload"),
      onClick: () => selectImage(),
    },
    {
      title: t("profilePicture.remove"),
      onClick: () => removeImage(),
    },
  ];

  const uploadImage = async (media: File | Blob) => {
    if (!media) return;
    setSelectedImage(media);
    setMediaIsUploaded(false);
    const dimensions = await getMediaDimensions(media);
    await handleUpload({ media, dimensions }, async (mediaId) => {
      setProfilePictureId(mediaId);
      setMediaIsUploaded(true);
    });
  };

  const handleImageSelection = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageRemoved(false);
      // TODO check if image type is allowed
      uploadImage(e.target.files[0]);
    }
    setShowProfileImagePopup(false);
  };

  const selectImage = () => {
    hiddenInputField.current?.click();
  };

  const removeImage = () => {
    setProfilePictureId(null);
    setImageRemoved(true);
    setShowProfileImagePopup(false);
    setSelectedImage(null);
    setMediaIsUploaded(true);
    resetUploads();
  };

  const tryToOpenPopup = () => {
    if (user.isVerified) {
      setShowProfileImagePopup(true);
    } else {
      navigate("/verification/start", { replace: true });
    }
  };

  const handleClosePopup = useCallback(() => setShowProfileImagePopup(false), []);

  const DisplayMedia = useCallback(() => {
    if (imageRemoved) return <ProfileImage className={"h-24 w-24"} />;

    if (!mediaIsUploaded && selectedImage && uploads.length > 0) {
      return (
        <div className={"h-24 w-24"}>
          <UploadSpinner
            percent={Math.round((uploads[0].bytesUploaded / uploads[0].bytesTotal) * 100)}
            showText={false}
          />
        </div>
      );
    } else {
      return (
        <ProfileImage
          url={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
          className={"h-24 w-24"}
          key={selectedImage && "name" in selectedImage ? selectedImage.name : imageUrl}
          uploadStatus={user.profilePictureThumbnail?.uploadStatus}
          isCurrentUser={true}
        />
      );
    }
  }, [imageRemoved, mediaIsUploaded, selectedImage, imageUrl, uploads]);

  useEffect(() => {
    resetUploads();
  }, []);

  return (
    <div className={"mb-3 flex gap-x-6"}>
      <DisplayMedia />
      <input
        type="file"
        accept={acceptTypes}
        onChange={handleImageSelection}
        className="hidden"
        ref={hiddenInputField}
      />
      <div>
        <h1 className="notranslate mb-1 flex items-center gap-x-1 font-serif text-2xl">
          {title} {userIsVerified && <VerifiedUserIcon className={"mt-1 h-5 w-5"} />}
        </h1>
        <button onClick={tryToOpenPopup} className={"flex w-fit shrink items-center text-sm text-red-900"}>
          <span className={"py-2"}>{t("editProfilePicture")}</span>
          <HiPencil className={"ml-3 h-5 w-5"} />
        </button>
        <PopUp
          title={t("profilePicture.title") as string}
          options={profilePictureOptions}
          isOpen={showProfileImagePopup}
          onClose={handleClosePopup}
        />
      </div>
    </div>
  );
};

export default EditProfileImage;
