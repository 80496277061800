import { ChatListDto, UpdateChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import TextInput from "components/basics/TextInput";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useListsState from "state/lists/listsState";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import * as Yup from "yup";

type Props = { list: ChatListDto } & BaseComponent & PopUpComponent;

const RenameListPopup = ({ list, open, onClose, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { maloumClient } = useContext(MaloumClientContext);
  const { addOrReplaceList } = useListsState();

  const updateListMutation = useMutation({
    mutationFn: (updateList: UpdateChatListDto) => maloumClient.chatLists.update(list._id, updateList),
  });

  const handleUpdateList = (name: string) =>
    updateListMutation.mutate(
      { ...list, name: name },
      {
        onSuccess: (list: ChatListDto) => {
          queryClient.invalidateQueries({ queryKey: ["list", list._id] });
          addOrReplaceList(list);
          onClose();
        },
      },
    );

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("lists.rename.title") as string} data-testid={dataTestId}>
      <Formik
        initialValues={{
          name: list.name,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(65, ({ max }) => t("validation.max", { max: max }))
            .matches(/[^\s*].*[^\s*]/g, t("validation.required") as string)
            .required(t("validation.required") as string),
        })}
        onSubmit={(values) => handleUpdateList(values.name)}
      >
        <Form className={classNames("mt-4 text-left", className)}>
          <TextInput
            name="name"
            type="text"
            maxLength={65}
            label={t("lists.rename.listName")}
            placeholder={t("lists.rename.placeHolder") as string}
          />
          <Button type="submit" text={t("lists.rename.save")} disabled={updateListMutation.isPending} />
        </Form>
      </Formik>
    </PopUp>
  );
};

export default RenameListPopup;
