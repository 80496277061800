import UnprocessedMedia from "components/molecules/UnprocessedMedia";

type Props = {
  isSender: boolean;
};

const UnprocessedMediaMessage = ({ isSender }: Props) => {
  return (
    <div className="flex h-80 min-h-[10rem] items-center justify-center bg-gray-100 px-5 py-3">
      <UnprocessedMedia isSender={isSender} />
    </div>
  );
};

export default UnprocessedMediaMessage;
