import { PostDto } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useFeed = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const updatePost = (postToChange: PostDto) => {
    queryClient.setQueryData(["feed"], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.map((post: PostDto) => (post._id === postToChange._id ? postToChange : post)),
          };
        }),
      };
    });
  };

  const removePost = (postId: string) => {
    queryClient.setQueryData(["feed"], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.filter((post: PostDto) => post._id !== postId),
          };
        }),
      };
    });
  };

  const fetchFeed = async (next: any) => {
    return await maloumClient.content.feed(next.pageParam, 15);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["feed"],
    queryFn: fetchFeed,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
  });

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage, updatePost, removePost };
};

export default useFeed;
