import Card from "components/atoms/Card";
import useScheduledBroadcastMessages from "hooks/useScheduledBroadcastMessagesAmount.hook";
import { Trans } from "react-i18next";
import StatefulLink from "components/atoms/utils/StatefulLink";

const ScheduledBroadcastMessages = () => {
  const { scheduledBroadcastMessages, isLoading, isError } = useScheduledBroadcastMessages();

  const showScheduledBroadcastMessages = scheduledBroadcastMessages && !isLoading && !isError;

  return showScheduledBroadcastMessages ? (
    <div className="w-full px-4">
      <Card className="w-full">
        <Trans
          i18nKey="seeScheduledMessages"
          values={{ count: scheduledBroadcastMessages }}
          components={{
            1: <StatefulLink className="text-red-900" to="/queue?tab=messages" />,
            2: <span className="text-sm text-gray-500" />,
          }}
        />
      </Card>
    </div>
  ) : (
    <></>
  );
};

export default ScheduledBroadcastMessages;
