import { RelatedUserDto } from "@neolime-gmbh/api-gateway-client";
import RelatedUserItem, { RelatedUserItemLoading } from "components/molecules/lists/RelatedUserItem";
import NoResults from "components/utilities/NoResults";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import SearchInput from "components/molecules/basics/SearchInput";

type Props = {
  users: RelatedUserDto[];
  searchQuery?: string;
  onSearchQueryChange?: (value: string) => void;
  searchPlaceholder?: string;
  fetchNextPage: () => void;
  isTyping?: boolean;
  isLoading: boolean;
  renderAction?: (userId: string) => ReactNode;
};

const RelatedUsersList = ({
  users,
  searchQuery,
  onSearchQueryChange,
  searchPlaceholder,
  fetchNextPage,
  isTyping = false,
  isLoading,
  renderAction,
}: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  if (inView) fetchNextPage();

  return (
    <>
      {onSearchQueryChange && (
        <SearchInput value={searchQuery ?? ""} onChange={onSearchQueryChange} placeholder={searchPlaceholder} />
      )}
      <div className="relative mt-4 flex flex-col gap-3">
        {(isLoading || isTyping) && Array.from({ length: 10 }).map((_, i) => <RelatedUserItemLoading key={i} />)}
        {!isTyping &&
          !isLoading &&
          users.length > 0 &&
          users.map((user) => <RelatedUserItem key={user.user._id} member={user} renderAction={renderAction} />)}
        {!isTyping && !isLoading && !users.length && (
          <div>
            <NoResults searchedText={searchQuery ? searchQuery : undefined} />
            {searchQuery && (
              <div className="text-center text-sm text-gray-500">{t("lists.detail.memberSearchNoResult")}</div>
            )}
          </div>
        )}
        <div className="absolute bottom-40" ref={ref} />
      </div>
    </>
  );
};

export default RelatedUsersList;
