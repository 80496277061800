import { RadioGroup } from "@headlessui/react";
import { GenderDto, UpdateProfileDto, UserIntroductionDto } from "@neolime-gmbh/api-gateway-client";
import StickyBottomBar from "components/StickyBottomBar";
import Toggle from "components/atoms/Toggle";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import Layout from "components/layouts/Layout";
import RadioButtonCard from "components/molecules/RadioButtonCard";
import { supportEmail } from "const";
import userContext from "contexts/UserContext";
import { Form, Formik } from "formik";
import useGender from "hooks/useGender";
import SignUpHeader from "pages/signup/SignUpHeader";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronRight } from "react-icons/hi2";
import useUserStore from "state/userState";
import * as Yup from "yup";
import MoreGendersPage from "./MoreGenders";
import HeaderBar from "components/layouts/HeaderBar";

type Props = {
  initialGender?: string | null;
  isGenderPublic?: boolean;
  onNext?: () => void;
  onUpdate?: (gender: string | null) => void;
};

const SelectGender = ({
  initialGender,
  isGenderPublic,
  onNext,
  onShowMore,
  onUpdate,
  onGenderSelected,
  onIsGenderPublicChange,
}: Props & {
  onShowMore: (value: boolean) => void;
  onGenderSelected: (gender: string) => void;
  onIsGenderPublicChange: (value: boolean) => void;
}) => {
  const { updateProfile } = useContext(userContext);
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation();
  const { shortGenderList, isAllGendersLoading, findGenderObject } = useGender();

  const [selectedGender, setSelectedGender] = useState(initialGender ? initialGender : null);

  const updateGender = async (gender: GenderDto | null, isGenderPublic: boolean) => {
    const data: { gender: string | null; isGenderPublic: boolean } = {
      gender: gender ? gender?._id : null,
      isGenderPublic: isGenderPublic,
    };
    const response = await updateProfile(data as UpdateProfileDto, true, true);

    if (!(response instanceof Error)) {
      if (onNext) {
        onNext();
      } else if (onUpdate) {
        onUpdate(gender ? gender.name : null);
      }
    }
  };

  const onSkip = useCallback(async () => {
    useUserStore.setState({
      user: {
        ...user,
        introduction: {
          hasCompletedSetup: true,
        } as UserIntroductionDto,
        firstOpening: true,
      },
    });
    if (onNext) {
      onNext();
    }
  }, [user]);

  const copyShortGenderList: string[] = useMemo(() => {
    if (shortGenderList) {
      if (selectedGender && !shortGenderList.map((el) => el.name).includes(selectedGender)) {
        return [
          ...[...shortGenderList.map((gender) => gender.name)].filter(
            (_, index) => index < 4 || index !== copyShortGenderList.length - 1,
          ),
          selectedGender,
        ];
      }
      return [...shortGenderList.map((gender) => gender.name)];
    }
    return [];
  }, [shortGenderList, initialGender, selectedGender]);

  if (!shortGenderList || !copyShortGenderList || isAllGendersLoading) {
    return (
      <>
        <Container hasStickyBottom isLoading>
          <article className="flex flex-col">
            <div className="mb-1 mt-4 flex h-7 w-1/2 animate-pulse self-center rounded-full bg-gray-200"></div>
            <div className="mb-1 mt-5 flex h-4 w-2/3 animate-pulse self-center rounded-full bg-gray-200"></div>
            <div className="mb-1 mt-2 flex h-4 w-2/3 animate-pulse self-center rounded-full bg-gray-200"></div>
            {[...Array(4)].map((_, i) => (
              <div
                key={`loading-genders-${i}`}
                className="mt-6 cursor-pointer items-center rounded-md border border-transparent p-2 shadow focus:outline-none"
              >
                <div className="flex flex-1 justify-between">
                  <div className="mt-2 flex h-4 w-1/6 animate-pulse rounded-full bg-gray-200"></div>
                  <div className="flex h-8 w-8 animate-pulse rounded-full bg-gray-200"></div>
                </div>
              </div>
            ))}
            <div className="mt-6 flex flex-1 justify-between">
              <div className="mt-2 flex h-4 w-2/6 animate-pulse rounded-full bg-gray-200"></div>
              <div className="flex h-8 w-8 animate-pulse rounded-full bg-gray-200"></div>
            </div>
            <div className="my-8 flex flex-col xxs:mb-8 xxs:mt-12">
              <div className="mt-4 flex h-2 w-1/3 animate-pulse self-center rounded-full bg-gray-200"></div>
              <div className="mt-2 flex h-2 w-1/2 animate-pulse self-center rounded-full bg-gray-200"></div>
            </div>
          </article>
        </Container>
        <StickyBottomBar>
          {onNext ? (
            <div className="flex gap-x-2">
              <div className="flex h-10 w-full animate-pulse rounded-md bg-gray-200"></div>
              <div className="flex h-10 w-full animate-pulse rounded-md bg-gray-200"></div>
            </div>
          ) : (
            <div className="flex h-10 w-full animate-pulse rounded-md bg-gray-200"></div>
          )}
        </StickyBottomBar>
      </>
    );
  }

  return (
    <Formik
      initialValues={{
        gender: selectedGender,
        isGenderPublic: isGenderPublic || true,
      }}
      validationSchema={Yup.object().shape({
        gender: Yup.string().required(t("validation.required") || ""),
      })}
      onSubmit={(values) => {
        let genderObject: GenderDto | undefined;

        if (values.gender !== null) {
          genderObject = findGenderObject(values.gender);
        }

        updateGender(genderObject || null, values.isGenderPublic);
      }}
      validateOnMount={true}
    >
      {({ values, setFieldValue, isValid, handleSubmit }) => (
        <>
          <Container hasStickyBottom>
            <Form>
              <div className="flex flex-col">
                <h2 className="text-3xl font-semibold text-gray-900">{t("gender.selectYourGender")}</h2>
                <p className="mt-2 text-sm text-gray-500 xxs:text-base">
                  {t("gender.selectYourGenderDescription")} {onNext && t("gender.adjustmentHint")}
                </p>
                <RadioGroup
                  name="gender"
                  value={values.gender}
                  onChange={(value: string) => {
                    setFieldValue("gender", value);
                    setSelectedGender(value);
                    onGenderSelected(value);
                  }}
                >
                  <ul className="mt-6 grid gap-y-2">
                    {copyShortGenderList.map((gender, i) => (
                      <li key={gender}>
                        <RadioButtonCard value={gender} data-testid={i === 0 ? "first-gender-select-button" : ""}>
                          {t(`gender.${gender}`)}
                        </RadioButtonCard>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
                <button
                  data-testid="more-genders-button"
                  onClick={() => onShowMore(true)}
                  className="mt-2 flex cursor-pointer items-center justify-between rounded-md border border-gray-100 p-3 text-sm focus:outline-none xxs:text-base"
                >
                  <span>{t("more")}</span>
                  <HiChevronRight className="-mr-1 h-6 w-6 text-gray-500" />
                </button>
                <div className="mt-6 flex items-center justify-between">
                  <p className="text-sm xxs:text-base">{t("gender.showMyGenderPublicly")}</p>
                  <Toggle
                    onChange={(value) => {
                      setFieldValue("isGenderPublic", value);
                      onIsGenderPublicChange(value);
                    }}
                    isChecked={values.isGenderPublic}
                    name="isGenderPublic"
                    data-testid="is-gender-public"
                  />
                </div>
                <div className="mt-8 text-center text-sm xxs:mb-8 xxs:mt-12 xxs:text-base">
                  <p className="text-gray-500">{t("gender.tellUs")}</p>
                  <a className="text-gray-500 underline" href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </a>
                </div>
              </div>
            </Form>
          </Container>
          <StickyBottomBar>
            {onNext ? (
              <div className="flex gap-x-2">
                <Button variant="secondary" text={t("skip")} type="button" onClick={onSkip} />
                <Button text={t("next")} type="submit" disabled={!isValid} onClick={handleSubmit} />
              </div>
            ) : (
              <div className="flex gap-x-2">
                <Button
                  variant={"primary"}
                  disabled={!isValid}
                  text={t("save")}
                  onClick={handleSubmit}
                  data-testid="save-button"
                />
              </div>
            )}
          </StickyBottomBar>
        </>
      )}
    </Formik>
  );
};

type PageProps = {
  onBack?: () => void;
} & Props;

const SelectGenderPage = ({ onBack, onNext, initialGender, isGenderPublic, onUpdate }: PageProps) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [selectedGender, setSelectedGender] = useState(initialGender ? initialGender : null);
  const [isGenderPublicState, setIsGenderPublicState] = useState(isGenderPublic);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [showMore]);

  if (showMore) {
    return (
      <MoreGendersPage
        onBack={() => setShowMore(false)}
        onSave={(value) => {
          setSelectedGender(value);
          setShowMore(false);
        }}
        initialGender={selectedGender}
        isPartOfSignUpFlow={onNext ? true : false}
      />
    );
  }
  return (
    <>
      {onNext ? (
        <div className="pr-safe pl-safe pb-safe flex flex-1 grow flex-col md:order-2">
          <SignUpHeader showSeperator={false} />
          <SelectGender
            onNext={onNext}
            onShowMore={() => setShowMore(true)}
            initialGender={selectedGender}
            isGenderPublic={isGenderPublicState}
            onGenderSelected={setSelectedGender}
            onIsGenderPublicChange={setIsGenderPublicState}
          />
        </div>
      ) : (
        <Layout hideNavigationMobile hideVerificationButton hasStickyBottom>
          <HeaderBar className="mb-3">
            <HeaderBar.SubPage>
              <HeaderBar.Left>
                <HeaderBar.Cancel onClick={onBack} />
              </HeaderBar.Left>
              <HeaderBar.Center>
                <HeaderBar.Title>{t("gender.yourGender")}</HeaderBar.Title>
              </HeaderBar.Center>
              <HeaderBar.Right />
            </HeaderBar.SubPage>
          </HeaderBar>
          <SelectGender
            initialGender={selectedGender}
            isGenderPublic={isGenderPublicState}
            onShowMore={() => setShowMore(true)}
            onUpdate={(gender: string | null) => onUpdate && onUpdate(gender)}
            onGenderSelected={setSelectedGender}
            onIsGenderPublicChange={setIsGenderPublicState}
          />
        </Layout>
      )}
    </>
  );
};

export default SelectGenderPage;
