import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useBalance = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchBalance = async () => {
    return await maloumClient.users.getBalance().then((response) => {
      if (response instanceof Error) {
        Promise.reject(balance);
      }
      return response.balance;
    });
  };

  const {
    data: balance,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["fetch-balance"],
    queryFn: fetchBalance,
  });
  return { balance: balance, isLoading, isError };
};

export default useBalance;
