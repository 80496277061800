import { Popover } from "@headlessui/react";
import { CategoryDto, CreatePostDto, PrivateUserDto, UpdatePostDto } from "@neolime-gmbh/api-gateway-client";
import Callout from "components/atoms/Callout";
import Card from "components/atoms/Card";
import SelectCategoryPreview from "components/category/SelectCategoryPreview";
import ContentMediaSelect from "components/molecules/ContentMediaSelect";
import ValidationError from "components/utilities/ValidationError";
import { Form, Formik } from "formik";
import { formatSchedulingDate } from "helper/dateAndTimeHelper";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineClock, HiOutlineInformationCircle } from "react-icons/hi2";
import { usePopper } from "react-popper";
import { useLocation } from "react-router-dom";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import * as Yup from "yup";
import useUserStore from "../../state/userState";
import Button from "../basics/Button";
import SelectButton from "../basics/SelectButton";
import Textarea from "../basics/Textarea";
import Container from "../layouts/Container";
import ExplanationPricePopup from "../subscription/ExplanationPricePopup";
import DatePickerPopup from "./DatePickerPopup";

type Props = {
  submit: (value: CreatePostDto) => Promise<void> | ((value: UpdatePostDto) => Promise<void>);
  isEditMode: boolean;
};

type TFormValues = {
  caption: string;
  categories: CategoryDto[];
};

const EditPostData = ({ submit, isEditMode = false }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const { pathname, state } = useLocation();
  const selectedMedia = (state?.selectedMedia ?? []) as TSelectedMedia[];

  const initialFormValues = {
    caption: state?.data?.caption ?? "",
    categories: state?.data?.categories ?? [],
  };

  const [showNoMediaSelectedError, setShowNoMediaSelectedError] = useState(false);

  const [formValues, setFormValues] = useState<TFormValues>();

  const isPublic = state?.data?.public === undefined ? true : state?.data?.public;
  const [isPublicPost, setIsPublicPost] = useState(isPublic);
  const [selectedDate, setSelectedDate] = useState(
    state?.data?.selectedDate ? new Date(state?.data?.selectedDate) : new Date(),
  );

  const [showSetPricePopup, setShowSetPricePopup] = useState(false);
  const [showSchedulingPopUp, setShowSchedulingPopUp] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectPublic = useCallback(() => setIsPublicPost(true), [setIsPublicPost]);

  const selectSubscribers = useCallback(() => {
    if (!user.subscriptionPrice) {
      setShowSetPricePopup(true);
    } else {
      setIsPublicPost(false);
    }
  }, [user, setShowSetPricePopup, setIsPublicPost]);

  const [popupElement, setPopupElement] = useState<HTMLButtonElement | null>(null);
  const [popupPanelElement, setPopupPanelElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(popupElement, popupPanelElement, {
    placement: "top-end",
  });

  const handleOnSubmit = async (values: { caption: string; categories: CategoryDto[] }) => {
    // if no media is selected and not in edit mode show error
    if (!selectedMedia.length && !isEditMode) {
      setShowNoMediaSelectedError(true);
      return;
    }
    // set loading to true to prevent double submit
    setIsLoading(true);

    // create tmp post
    const postTmp = {
      caption: values.caption,
      categories: values.categories.map((c) => c._id),
      public: isPublicPost,
      mediaIds: selectedMedia.map((media) => media._id),
    } as CreatePostDto;

    // get relevant schedule dates
    const now = new Date();
    const scheduledDate = new Date(selectedDate);

    if (
      (isEditMode && new Date(state?.data?.originalSelectedDate) > new Date()) ||
      scheduledDate.valueOf() > now.valueOf()
    )
      postTmp.scheduledAt = scheduledDate.toISOString();
    // submit post for creation or update
    await submit(postTmp);
    // navigate to profile
    navigate(`/creator/${user.username}`);
  };

  const handleRedirectToVault = () => {
    const data = {
      caption: formValues?.caption,
      categories: formValues?.categories,
      isPublic: isPublicPost,
      scheduledAt: selectedDate.toISOString(),
      originalScheduledAt: state?.data?.originalSelectedDate,
    };
    setShowNoMediaSelectedError(false);
    navigate("/vault", { state: { redirectTo: pathname, data, allowMultipleSelect: false }, replace: true });
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={Yup.object().shape({
        caption: Yup.string()
          .trim()
          .required(t("errorMessage.story") as string),
        categories: Yup.array()
          .of(Yup.object())
          .min(1, t("errorMessage.minOneCategory") as string)
          .max(3, ({ max }) => t("validation.selectMax", { max: max })),
      })}
      validate={() => {
        if (!selectedMedia.length) setShowNoMediaSelectedError(true);
      }}
      onSubmit={handleOnSubmit}
    >
      {({ values }: { values: TFormValues }) => {
        // store form values to be able to access them
        setFormValues(values);

        // return form
        return (
          <Form className="flex flex-1 flex-col pb-4">
            {!isEditMode && (
              <>
                <ContentMediaSelect
                  handleRedirectToVault={handleRedirectToVault}
                  processingMessage={t("processingStates.post") as string}
                />
                {showNoMediaSelectedError && (
                  <div className="mx-auto w-full max-w-xl px-4 md:px-0">
                    <ValidationError className="mt-2" message={t("errorMessage.media")} hasBackground={false} />
                  </div>
                )}
              </>
            )}

            <Container hasPadding={false} noDesktopPadding className="mt-4 px-4 md:px-0">
              <div className="flex flex-1 flex-col gap-4 pb-4 md:flex-grow-0">
                <Textarea
                  label={t("addStory") as string}
                  type="text"
                  name="caption"
                  placeholder={t("writeStory") as string}
                  maxLength={2000}
                  className="mb-0"
                  data-testid="post-caption-input"
                />

                <SelectCategoryPreview name="categories" minCategories={1} maxCategories={3} type="POST" />

                <div className="mb-2 flex items-center text-sm text-gray-600">
                  <span>{t("visibleTo")}</span>
                  <Popover className="relative ml-1 flex">
                    <Popover.Button ref={setPopupElement}>
                      <HiOutlineInformationCircle className="h-5 w-5 text-gray-700" />
                    </Popover.Button>
                    <Popover.Panel
                      ref={setPopupPanelElement}
                      className="w-screen max-w-[16.25rem]"
                      style={styles.popper}
                      {...attributes.popper}
                    >
                      <div className="mb-2 w-full rounded-md bg-white p-2 font-medium shadow-[0px_1px_6px] shadow-gray-100">
                        {t("visibleToExplanation")}
                      </div>
                    </Popover.Panel>
                  </Popover>
                </div>

                <div className="flex space-x-2">
                  <SelectButton
                    value={t("everyone")}
                    selected={isPublicPost}
                    setSelected={handleSelectPublic}
                    disabled={isEditMode && !state?.data?.public}
                    data-testid="public-post-button"
                  />

                  <SelectButton
                    value={t("subscribers")}
                    selected={!isPublicPost}
                    setSelected={selectSubscribers}
                    data-testid="private-post-button"
                  />
                  <ExplanationPricePopup setIsOpen={setShowSetPricePopup} isOpen={showSetPricePopup} />
                </div>
                {isPublicPost && <Callout className="font-semibold">{t("posts.publicRestrictions")}</Callout>}
                {(!isEditMode || new Date(state?.data?.originalSelectedDate) > new Date()) && (
                  <button onClick={() => setShowSchedulingPopUp(true)} type="button">
                    <Card className="flex justify-between gap-x-2">
                      <HiOutlineClock className="h-6 w-6" />
                      <dl className="flex flex-1 flex-col items-start">
                        <dt className="text-sm">{t("publishingDate")}</dt>
                        <dd>
                          {formatSchedulingDate(
                            new Date(selectedDate) > new Date() ? new Date(selectedDate) : new Date(),
                          )}
                        </dd>
                      </dl>
                      <span className="text-sm text-red-900">{t("change")}</span>
                    </Card>
                  </button>
                )}
              </div>

              {!isEditMode && <span className="mb-1 text-sm text-gray-500">{t("posts.publishConsent")}</span>}

              <Button
                data-testid="create-post-button"
                variant="primary"
                type="submit"
                text={!isEditMode ? t("publish") : t("publishChanges")}
                disabled={isLoading}
              />
            </Container>

            {showSchedulingPopUp && (
              <DatePickerPopup
                onClose={() => setShowSchedulingPopUp(false)}
                onSave={setSelectedDate}
                date={selectedDate}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditPostData;
