import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { QueryFunctionContext, useInfiniteQuery, useQuery } from "@tanstack/react-query";

const useSubscribers = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchNextSubscribers = async (query: QueryFunctionContext<string[], any>) => {
    return await maloumClient.users.getIncomingSubscriptions(query?.pageParam);
  };

  const {
    data: subscribers,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["subscribers"],
    queryFn: fetchNextSubscribers,
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: undefined,
  });

  const countIncomingSubscriptions = async () => {
    return await maloumClient.users.getIncomingSubscriptionCount();
  };

  const { data: subscribersCount } = useQuery({
    queryKey: ["subscribersCount"],
    queryFn: countIncomingSubscriptions,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });

  return {
    subscribers,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    subscribersCount: subscribersCount?.subscriberCount,
  };
};

export default useSubscribers;
