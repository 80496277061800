import { PostDto } from "@neolime-gmbh/api-gateway-client";
import Pill from "components/atoms/Pill";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import { formatSchedulingDate, getDateRelativeToNow } from "helper/dateAndTimeHelper";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiEllipsisHorizontal, HiOutlineChatBubbleOvalLeft, HiOutlineHeart, HiOutlineLockOpen } from "react-icons/hi2";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useUserStore from "../../state/userState";
import { ProfileImageWithLink } from "../basics/ProfileImage";
import PopUp from "../popup/PopUp";
import ContentReportPopUp from "../product/ContentReportPopUp";
import PostMedia from "./PostMedia";
import PostMetaInformation from "./PostMetaInformation";
import PrivateMedia from "./PrivateMedia";
import LinkifiedText, { canUserDisplayLinks } from "components/atoms/utils/LinkifiedText";
import classNames from "classnames";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import StatefulLink from "components/atoms/utils/StatefulLink";
import { useFirstline } from "@first-line/firstline-react";

type Props = {
  post: PostDto;
  updateCallback: (post: PostDto) => void;
  deletedCallback?: () => void;
  viewedByGuest?: boolean;
};

const PostItem = ({ post, updateCallback, deletedCallback, viewedByGuest = false }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { maloumClient } = useContext(MaloumClientContext);
  const { user: authUser } = useFirstline();

  const [modifyMenuOpen, setModifyMenuOpen] = useState(false);
  const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);
  const reducePublicPostCount = useUserStore((state) => state.reducePublicPostCount);
  const reducePrivatePostCount = useUserStore((state) => state.reducePrivatePostCount);

  const createdByUser = post.createdBy;
  const isOwnContent = post.createdBy.username === authUser?.username;
  const now = new Date();

  const deletePost = useCallback(async () => {
    if (post) {
      setConfirmDeletionOpen(false);
      setModifyMenuOpen(false);

      await maloumClient.posts.delete(post._id).then(() => {
        if (post.public) reducePublicPostCount();
        else reducePrivatePostCount();
        if (deletedCallback) deletedCallback();
      });
    }
  }, [post, deletedCallback]);

  const handleClosePopup = useCallback(
    () => (confirmDeletionOpen ? setConfirmDeletionOpen(false) : setModifyMenuOpen(false)),
    [confirmDeletionOpen, setConfirmDeletionOpen, setModifyMenuOpen],
  );

  const getPopupTitle = () => {
    return confirmDeletionOpen ? t("posts.deleteConfirmation") : t("posts.modify");
  };

  const modifyOptions = [
    {
      title: t("edit"),
      onClick: () => navigate(`/post/${post?._id}/edit`),
    },
    {
      title: t("delete"),
      testId: "delete-post",
      onClick: () => {
        setConfirmDeletionOpen(true);
      },
    },
  ];

  const deleteOptions = [
    {
      title: t("delete"),
      testId: "confirm-delete-post",
      onClick: deletePost,
    },
  ];

  const profileLink = `/creator/${post.createdBy.username}`;
  const showOpenLockIcon = !post.public && post.isVisible;
  const showSubscribeBanner = !post.public && !post.isVisible && !isOwnContent;

  return (
    <div className="py-3" data-testid="post-item">
      <div className="flex w-full items-center px-4 pb-1 md:px-0">
        <ProfileImageWithLink
          to={profileLink}
          url={post.createdBy.profilePictureThumbnail?.url}
          className="mr-3 h-8 w-8"
          uploadStatus={post.createdBy.profilePictureThumbnail?.uploadStatus}
          isCurrentUser={isOwnContent}
          spinnerClassName="w-5 h-5"
        />
        <div className="flex h-10 min-w-0 flex-1 items-center">
          <StatefulLink to={profileLink} className="notranslate flex min-w-0 flex-1 items-center gap-x-1">
            <span className="truncate">{post.createdBy.username}</span>
            {post.createdBy.isVerified && <VerifiedUserIcon className="flex-shrink-0" />}
          </StatefulLink>
          {showOpenLockIcon && <HiOutlineLockOpen className="ml-3 h-5 w-5 flex-shrink-0 stroke-2" data-testid="post-openlock-icon" />}
        </div>
        {post.publishedAt && new Date(post.publishedAt) >= now && (
          <Pill size="sm" colors="bg-gray-100" className="flex-shrink-0">
            {formatSchedulingDate(new Date(post.publishedAt))}
          </Pill>
        )}
        <button className="py-2 pl-2 flex-shrink-0" onClick={() => setModifyMenuOpen(true)} data-testid="modify-item">
          <HiEllipsisHorizontal className="h-7 w-7" />
        </button>
      </div>

      <div className="bg-beige-400">
        {showSubscribeBanner ? (
          <PrivateMedia
            media={post.media[0]}
            thumbnail={post.thumbnail}
            user={post.createdBy}
            data-testid="private-media"
          />
        ) : (
          <PostMedia post={post} setPost={updateCallback} />
        )}
      </div>

      <PostMetaInformation post={post} setPost={updateCallback} viewedByGuest={viewedByGuest} className="my-2" />
      <p className="overflow-hidden px-4 text-sm md:px-0">
        <StatefulLink to={`/creator/${createdByUser?.username}`} className="notranslate mr-1 font-semibold">
          {createdByUser.username}
        </StatefulLink>
        <LinkifiedText className="notranslate break-words" displayAsLink={canUserDisplayLinks(post.createdBy)}>
          {post.caption}
        </LinkifiedText>
      </p>
      {new Date(post.publishedAt) < new Date() && (
        <span className="px-4 text-xs text-gray-300 md:px-0">{getDateRelativeToNow(new Date(post.createdAt))}</span>
      )}

      {isOwnContent ? (
        <PopUp
          isOpen={modifyMenuOpen || confirmDeletionOpen}
          onClose={handleClosePopup}
          title={getPopupTitle()}
          options={confirmDeletionOpen ? deleteOptions : modifyOptions}
        />
      ) : (
        <ContentReportPopUp id={post._id} isOpen={modifyMenuOpen} setIsOpen={setModifyMenuOpen} />
      )}
    </div>
  );
};

export default PostItem;

type PostItemLoadingProps = {
  className?: string;
  "data-testid"?: string;
};

const PostItemLoading = ({ className, "data-testid": dataTestId }: PostItemLoadingProps) => {
  return (
    <div className={classNames("animate-pulse py-3", className)} data-testid={dataTestId}>
      <div className="flex items-center px-4 pb-1 md:px-0">
        <div className="mr-3 h-8 w-8 rounded-full bg-gray-200" />
        <div className="flex flex-1">
          <div className="h-3 w-[200px] rounded-full bg-gray-200" />
        </div>
        <div className="py-2 pl-2">
          <HiEllipsisHorizontal className="h-7 w-7 text-gray-300" />
        </div>
      </div>
      <div className="aspect-square w-full bg-gray-200" />
      <div className="flex items-center justify-between px-4 pb-1 md:px-0">
        <div className="flex items-center">
          <div className="p-2 pl-0">
            <HiOutlineHeart className="h-6 w-6 text-gray-300" />
          </div>
          <div className="p-2">
            <HiOutlineChatBubbleOvalLeft className="h-6 w-6 text-gray-300" />
          </div>
        </div>
      </div>

      <div className="px-4 md:px-0">
        <div className="mb-2 h-2 rounded-full bg-gray-200" />
        <div className="h-2 w-3/4 rounded-full bg-gray-200" />
      </div>
    </div>
  );
};

export { PostItemLoading };
