import { VaultMediaDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import SelectionButton from "components/atoms/vault/SelectionButton";
import Media from "components/media/Media";
import VaultMediaStatusPill from "components/molecules/vault/media/VaultMediaStatusPill";
import { useMemo } from "react";
import { BaseComponent } from "types/components/component.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

type Props = {
  media: VaultMediaDto;
  selectedMedia: TSelectedMedia[];
  handleSelectMedia: (media: TSelectedMedia) => void;
} & BaseComponent;

const VaultMedia = ({ media, selectedMedia, handleSelectMedia, className, "data-testid": dataTestId }: Props) => {
  const isSelected = useMemo(() => {
    if (!media?.upload) return false;
    if (selectedMedia.findIndex((m) => m._id === media.upload._id) !== -1) return true;
    return false;
  }, [media, selectedMedia]);

  const handleOnSelection = () => {
    if (!media?.upload) return;
    handleSelectMedia({ _id: media.upload._id, ...media.media, thumbnail: media.thumbnail });
  };

  return (
    <div className={classNames("relative aspect-[3/4] w-full overflow-hidden", className)} data-testid={dataTestId}>
      <Media media={media.media} thumbnail={media.thumbnail} />
      <SelectionButton isSelected={isSelected} onClick={handleOnSelection} className="absolute left-0 top-0" />
      <VaultMediaStatusPill media={media} />
    </div>
  );
};

export default VaultMedia;
