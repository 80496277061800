import classNames from "classnames";
import { HiOutlineCheck } from "react-icons/hi2";
import { BaseComponent, ChildrenComponent } from "types/components/component.type";

type Props = {
  isSelected: boolean;
  onClick?: () => void;
} & BaseComponent &
  ChildrenComponent;

const BaseSelectionItem = ({ isSelected, onClick, children, className, "data-testid": dataTestId }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "flex w-full items-center justify-between rounded-md border px-3 py-2",
        { "border-red-900": isSelected, "border-gray-100": !isSelected },
        className,
      )}
      data-testid={dataTestId}
    >
      <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">{children}</span>
      <div
        className={classNames("ml-2 flex h-5 w-5 items-center justify-center rounded-[4px] border-[1.5px]", {
          "border-red-900 bg-red-900": isSelected,
          "border-gray-400": !isSelected,
        })}
      >
        {isSelected && <HiOutlineCheck className="stroke-[3px] text-white" />}
      </div>
    </button>
  );
};

const LoadingBaseSelectionItem = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return (
    <div className={classNames("w-full animate-pulse rounded-md bg-gray-100", className)} data-testid={dataTestId} />
  );
};

export default BaseSelectionItem;
export { LoadingBaseSelectionItem };
