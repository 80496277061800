import { MessageDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowUpRight, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import { isChatProductMessageContent, isTipMessageContent } from "types/messages/message.type";

type MenuItemProps = {
  Icon: React.ComponentType<{ className?: string }>;
  title: string;
  onClick: () => void;
};
const MenuItem = ({ Icon, title, onClick }: MenuItemProps) => {
  return (
    <button className="flex items-center gap-2 p-4 hover:bg-beige-300 active:bg-beige-500" onClick={onClick}>
      <Icon className="h-6 w-6" />
      <div>{title}</div>
    </button>
  );
};

type Props = {
  message: MessageDto;
  visible: boolean;
  onDelete: () => void;
  onEditPrice: () => void;
  onCloseSettings: () => void;
  className?: string;
};

const MessageMenuPopup = ({
  message,
  visible,
  onDelete,
  onEditPrice,
  onCloseSettings,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [onBottom, setOnBottom] = useState(false);
  const [topOrBottomSet, setTopOrBottomSet] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useStatefulNavigate();

  // set positioning of popup if visible or reset if not visible
  useEffect(() => {
    if (ref.current) {
      if (
        window.innerHeight - ref.current.getBoundingClientRect().top - ref.current.getBoundingClientRect().height <
        30
      )
        setOnBottom(false);
      setTopOrBottomSet(true);
    }
    if (!visible) {
      setOnBottom(true);
      setTopOrBottomSet(false);
    }
  }, [visible]);

  useEffect(() => {
    const containerDiv = document.getElementById("messages-list-container");
    if (!topOrBottomSet || !ref.current || !containerDiv) return;

    const rect = ref.current.getBoundingClientRect();
    const containerRect = containerDiv.getBoundingClientRect();

    const isInViewport = rect.top >= containerRect.top && rect.bottom <= containerRect.bottom;
    if (!isInViewport) {
      ref.current.scrollIntoView({ behavior: "smooth", block: onBottom ? "end" : "start" });
    }
  }, [topOrBottomSet, onBottom]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current || !event.target || !visible) return;
      if (event.target === ref.current || ref?.current?.contains(event.target as Node)) return;
      onCloseSettings();
    };

    if (visible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible, onCloseSettings]);

  const canDelete = useMemo(
    () =>
      !isTipMessageContent(message.content) && !message.isBought,
    [message],
  );
  
  const canChangePrice = useMemo(
    () =>
      isChatProductMessageContent(message.content) && !message.isBroadcasted && !message.isBought,
    [message],
  );

  const menuItems = useMemo(
    () =>
      [
        canDelete && {
          Icon: HiOutlineTrash,
          title: t("deleteMessage"),
          onClick: () => {
            onDelete();
            onCloseSettings();
          },
        },
        canChangePrice && {
          Icon: HiOutlinePencil,
          title: t("changeChatProductPrice.editPrice"),
          onClick: () => {
            onEditPrice();
            onCloseSettings();
          },
        },
        message.isBroadcasted && {
          Icon: HiOutlineArrowUpRight,
          title: t("toMassMessageOverview"),
          onClick: () => {
            onCloseSettings();
            navigate("/chat/broadcasts");
            
          },
        },
      ].filter(Boolean) as MenuItemProps[],
    [],
  );

  return (
    <>
      <div
        className={classNames(
          "absolute p-px",
          className,
          { "-bottom-4 translate-y-full": onBottom, "-top-4 -translate-y-full": !onBottom },
          { hidden: !visible },
          { "opacity-0": !topOrBottomSet },
        )}
        ref={ref}
      >
        <div className="grid w-72 grid-cols-1 overflow-hidden rounded-md border bg-white drop-shadow-sm">
          {menuItems.map((item, idx) => (
            <Fragment key={idx}>
              {idx !== 0 && <div className="mx-auto h-px w-[90%] rounded-full bg-gray-200" />}
              <MenuItem {...item} />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default MessageMenuPopup;
