import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useScheduledBroadcastMessagesAmount = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchScheduledBroadcastMessagesLength = async () =>
    await maloumClient.scheduledBroadcasts.getScheduledBroadcastsLength();

  const {
    data: scheduledBroadcastMessages,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["fetch-scheduled-broadcast-messages-length"],
    queryFn: fetchScheduledBroadcastMessagesLength,
    initialData: 0,
  });

  return { scheduledBroadcastMessages, isLoading, isError };
};

export default useScheduledBroadcastMessagesAmount;
