const Logo = ({ className }: { className?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1299 202.47" className={className} fill="fill-current">
      <g>
        <path d="M111,196.54Q89,196.83,73.14,181T57.33,143.17a58.54,58.54,0,0,1,2-13.84l17.21-65.8a22.37,22.37,0,0,0,.86-5.93,22.91,22.91,0,0,0-6.92-16.8,23.92,23.92,0,0,0-16.8-7.2h-.28a23.9,23.9,0,0,0-16.8,7.2,22.91,22.91,0,0,0-6.92,16.8V197.94H0V57.6A51.86,51.86,0,0,1,15.67,19.76Q31.35,3.95,53.37,4h.28q22,0,37.7,15.81A51.86,51.86,0,0,1,107,57.6a55.34,55.34,0,0,1-1.57,13.59L87.94,137a20.62,20.62,0,0,0-1,6.21A22.58,22.58,0,0,0,94,160,23.33,23.33,0,0,0,111,166.89,23,23,0,0,0,127.63,160a22.66,22.66,0,0,0,7.06-16.8,22.91,22.91,0,0,0-.86-6.21l-17.21-65.8a51.36,51.36,0,0,1-1.69-13.55A51.86,51.86,0,0,1,130.6,19.77Q146.28,4,168.3,4T206,19.77a51.86,51.86,0,0,1,15.67,37.84V198H192V57.61a23,23,0,0,0-6.78-16.94,23.85,23.85,0,0,0-33.88,0,22.93,22.93,0,0,0-6.78,16.92,19.17,19.17,0,0,0,.69,5.93l17.51,65.8a61.2,61.2,0,0,1,1.57,13.87A51.86,51.86,0,0,1,148.68,181Q133,196.81,111,196.54Z" />
        <path d="M414.83,198l-15-43.72H314L299,198H266.17l70-194h42.36l70,194Zm-91.55-70.51h67.23L382,103.67q-1.64-5.19-5.47-15.3-12-34.16-19.95-59.85-5.47,18.58-19.68,59.85L331.47,104Z" />
        <path d="M524.42,3.92V170.35h88V198H491.9V3.92Z" />
        <path d="M642.47,100.81q0-39.24,29.51-70T740.18,0q39,0,68.48,30.78t29.51,70.6q0,40.67-29.37,70.88t-68.62,30.21q-39.25,0-68.48-30.21T642.47,100.81Zm97.71,72q27.39,0,47.87-21.74t20.48-49.7q0-28.23-20.48-50T740.18,29.66q-27.39,0-47.72,21.89t-20.33,49.84q0,28,20.33,49.7T740.18,172.82Z" />
        <path d="M1037.47,3.92V134q0,33.88-19.4,51.1t-56.3,17.22q-36.9,0-56.3-17.22T886.07,134V3.92h32.25V132.64q0,20,11.07,30.61t32.38,10.66q21.32,0,32.38-10.66t11.07-30.61V3.92Z" />
        <path d="M1248.17,3.92H1299V198h-31.7V100.12q0-5.19.55-48.37l.27-23.5q-2.46,8.74-10.93,35.25-1.92,5.75-3.55,10.93t-3.14,9.43c-1,2.83-1.6,4.51-1.78,5.06q-.83,2.19-2,5.88t-1.78,5.33L1212.37,198h-28.15l-33.07-97.83-5.74-17.22q-11.75-32.79-18-54.11.54,31.15.55,71.6V198h-30.88V3.93h51.1l27.87,82q14.76,44.55,22.41,71.87,8.74-31.16,22.41-71.87Z" />
      </g>
    </svg>
  );
};

export default Logo;
