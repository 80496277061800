import { Tab } from "@headlessui/react";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import MyTab from "../components/basics/MyTab";
import Container from "../components/layouts/Container";
import Layout from "../components/layouts/Layout";
import IncomingSubscriptions from "../components/subscription/IncomingSubscriptions";
import OutgoingSubscriptions from "../components/subscription/OutgoingSubscriptions";
import useUserStore from "../state/userState";
import HeaderBar from "components/layouts/HeaderBar";

const Subscriptions = () => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const tabs = ["incoming", "outgoing"];
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab");
  const [selectedIndex, setSelectedIndex] = useState(tabName ? tabs.indexOf(tabName) : 0);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedIndex(tab);
      setSearchParams({ tab: tabs[tab] }, { replace: true });
    },
    [setSearchParams, tabs],
  );

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("subscription.name_plural")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      {user.isCreator ? (
        <Container hasPadding={false}>
          <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
            <Tab.List className={"flex justify-around"}>
              <MyTab title={t("subscription.sales") as string} />
              <MyTab title={t("subscription.purchases") as string} />
            </Tab.List>
            <Tab.Panels className="px-4 md:px-0">
              <Tab.Panel className={"focus:outline-none"}>
                <IncomingSubscriptions />
              </Tab.Panel>
              <Tab.Panel>
                <OutgoingSubscriptions />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Container>
      ) : (
        <Container>
          <OutgoingSubscriptions />
        </Container>
      )}
    </Layout>
  );
};

export default Subscriptions;
