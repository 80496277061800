import { useCallback, useContext } from "react";
import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useInfiniteQuery } from "@tanstack/react-query";

type Props = {
  selectedCategory: CategoryDto | null;
};

const useDiscoverShop = ({ selectedCategory }: Props) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchProducts = useCallback(
    async (next: { pageParam?: string }) => {
      return maloumClient.products.shop(
        [selectedCategory?._id as string, selectedCategory?._id as string],
        next.pageParam,
      );
    },
    [maloumClient, selectedCategory],
  );

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["shop-discover", selectedCategory?._id],
    queryFn: fetchProducts,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage };
};

export default useDiscoverShop;
