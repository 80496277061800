import {
  ChatProductMessageDto,
  PaymentMethod,
  ProductDto,
  PublicPriceDto,
  PublicUserDto,
} from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import MaloumClientContext from "../../contexts/MaloumClientContext";

export const useCheckoutItem = (
  id: string | null,
  type: "SUBSCRIPTION" | "PRODUCT" | "TIP" | "CHAT_PRODUCT" | "CHAT_UNLOCK" | null,
  amount: number | null,
  selectedCountry?: string,
  selectedPaymentMethod?: PaymentMethod,
) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchItem = useCallback(async (): Promise<PublicUserDto | ProductDto | ChatProductMessageDto | null> => {
    if (!id) throw Error;

    switch (type) {
      case "TIP":
        return null;
      case "CHAT_UNLOCK":
      case "SUBSCRIPTION":
        return maloumClient.users.getProfileByUsername(id) as Promise<PublicUserDto>;
      case "PRODUCT":
        return maloumClient.products.getById(id);
      case "CHAT_PRODUCT":
        return maloumClient.messages.getChatProductMessage(id);
      default:
        throw Error;
    }
  }, [type, id]);

  const {
    data: item,
    isLoading: itemIsLoading,
    isError: itemIsError,
  } = useQuery({
    queryKey: ["checkout-item", { type, id }],
    queryFn: fetchItem,
  });

  const calculatePrice = useCallback(
    async (creatorId: string, netAmount: number): Promise<PublicPriceDto> => {
      return maloumClient.pricing.calculateBuyingPrice({
        creator: creatorId,
        netAmount: netAmount,
        country: selectedCountry,
        paymentMethod: selectedPaymentMethod,
      });
    },
    [selectedCountry, selectedPaymentMethod],
  );

  const fetchPrice = useCallback(async (): Promise<PublicPriceDto> => {
    if (!id) throw Error;

    if (type === "TIP" && amount) {
      return calculatePrice(id, amount);
    }

    if (!item) throw Error;

    if (type === "CHAT_UNLOCK" && amount) {
      const user = item as PublicUserDto;
      return calculatePrice(user._id, amount);
    }

    if (type === "PRODUCT") {
      const product = item as ProductDto;
      return calculatePrice(product.createdBy._id, product.price.net);
    }

    if (type === "SUBSCRIPTION") {
      const creator = item as PublicUserDto;
      return await calculatePrice(creator?._id, creator?.subscriptionPrice?.net);
    }

    if (type === "CHAT_PRODUCT") {
      const message = item as ChatProductMessageDto;
      return await calculatePrice(message.senderId, message.priceNet);
    }

    throw Error;
  }, [id, amount, item, selectedCountry, selectedPaymentMethod]);

  const {
    data: price,
    isLoading: priceIsLoading,
    isError: priceIsError,
  } = useQuery({
    queryKey: ["checkout-price", { id, amount, item, selectedCountry, selectedPaymentMethod }],
    queryFn: fetchPrice,
  });

  return { item, price, isLoading: itemIsLoading || priceIsLoading, isError: itemIsError || priceIsError };
};
