import { RadioGroup } from "@headlessui/react";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import RadioOption from "components/basics/RadioOption";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import UserContext from "contexts/UserContext";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";

const CommentSettings = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const currentCanCreatorsComment = useUserStore<boolean>((state) => state.user.contentSettings.canCreatorsComment);
  const [canCreatorsComment, setCanCreatorsComment] = useState(currentCanCreatorsComment);
  const { updateProfile } = useContext(UserContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  useEffect(() => {
    if (!user.isVerified) navigate("/verification/start");
  }, [user]);

  const handleSave = async () => {
    await updateProfile({
      contentSettings: {
        canCreatorsComment,
      },
    });
    navigate(-1);
  };

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <h1 className="text-center text-sm">{t("comment.plural")}</h1>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container>
        <p className="mt-6 text-3xl font-semibold">{t("comment.settings.header")}</p>
        <p className="mt-2">{t("comment.settings.description")}</p>

        <p className="mb-2 mt-6 font-bold">{t("comment.settings.whoCanComment.title")}</p>
        <RadioGroup name="exclusiveChatType" value={canCreatorsComment} onChange={setCanCreatorsComment}>
          <RadioOption key={"all"} value={true} title={t("comment.settings.whoCanComment.everybody")} />
          <RadioOption key={"fans"} value={false} title={t("comment.settings.whoCanComment.onlyFans")} />
        </RadioGroup>
        <div className="grow" />
        <Button onClick={handleSave} className="mb-4" text={t("save")} />
      </Container>
    </Layout>
  );
};

export default CommentSettings;
