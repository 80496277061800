import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";

const DEFAULT_STALE_TIME = 1000 * 60 * 3; // 3 minutes

export const useMessagesQuery = (chatId: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchMessages = async (next: QueryFunctionContext<string[], string | undefined>) => {
    return maloumClient.chats.getMessages(chatId, next?.pageParam);
  };

  return useInfiniteQuery({
    queryKey: ["fetch-messages", chatId],
    queryFn: fetchMessages,
    getNextPageParam: (lastPage) => lastPage.next,
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STALE_TIME,
    initialPageParam: undefined,
  });
};
