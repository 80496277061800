import { ChatDtoKeysetResponse, InboxType } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import MaloumClientContext from "contexts/MaloumClientContext";
import useUserStore from "state/userState";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import useChatFilterState from "state/chatFilterState";
import { getChatQueryKey } from "helper/chatsQueryHelper";

const DEFAULT_STALE_TIME = 1000 * 60 * 3; // 3 minutes

export type ChatsQueryData = InfiniteData<ChatDtoKeysetResponse, string | undefined>;

export const useChatsQuery = (type: InboxType) => {
  const user = useUserStore((state) => state.user);
  const filter = useChatFilterState((state) => state.filter);
  const maloumClient = useContext(MaloumClientContext).maloumClient;
  const queryClient = useQueryClient();

  const queryKey = getChatQueryKey({ type, isCreator: user?.isCreator, filter });

  return useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      if (!maloumClient) {
        throw new Error("MaloumClient not initialized");
      }

      if (user.isCreator) {
        return maloumClient.chats.getChatOverviewForCreator(pageParam, filter, 15);
      } else {
        return maloumClient.chats.getChatOverviewByInbox(type, pageParam, filter, 15);
      }
    },
    getNextPageParam: (lastPage: ChatDtoKeysetResponse) => lastPage.next,
    initialPageParam: undefined,
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!maloumClient,
    placeholderData: () => {
      if (!filter) return undefined;

      const chatsData = queryClient.getQueryData<ChatsQueryData>(getChatQueryKey({ type, isCreator: user?.isCreator }));

      if (!chatsData) return undefined;

      return {
        ...chatsData,
        pages: chatsData.pages.map((page) => ({
          ...page,
          data: page.data.filter((c) => c.unreadMessages),
        })),
      };
    },
  });
};
