import { InboxType } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useSingleChatQuery } from "./queries/useSingleChatQuery.hook";
import { useChatsQueryManipulator } from "./queries/useChatsQueryManipulator.hook";

const useChatItem = (id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const { removeChatFromQuery, invalidateChatsQueryAndRefetch } = useChatsQueryManipulator();

  const moveChatToPrimary = async () => {
    if (chat?.inbox !== InboxType.REQUEST) return;
    await maloumClient.chats.update(id, { inbox: InboxType.PRIMARY });

    chat.unreadMessages = false;
    chat.inbox = InboxType.PRIMARY;

    refetch();
    removeChatFromQuery(id, InboxType.REQUEST);
    invalidateChatsQueryAndRefetch(InboxType.PRIMARY);
  };

  const moveChatToRequest = async () => {
    if (chat?.inbox !== InboxType.PRIMARY) return;
    await maloumClient.chats.update(id, { inbox: InboxType.REQUEST });

    chat.unreadMessages = false;
    chat.inbox = InboxType.REQUEST;

    refetch();
    removeChatFromQuery(id, InboxType.PRIMARY);
    invalidateChatsQueryAndRefetch(InboxType.REQUEST);
  };

  const updateNicknameForChatPartner = async (nickname: string) => {
    await maloumClient.chats.updateNicknameForChatPartner(id, { nickname });
    refetch();
  };

  const updateNotesOnChatPartner = async (notes: string) => {
    await maloumClient.chats.updateNotesOnChatPartner(id, { notes });
    refetch();
  };

  const { data: chat, isLoading, isError, refetch } = useSingleChatQuery(id);

  return {
    chat,
    moveChatToPrimary,
    moveChatToRequest,
    updateNicknameForChatPartner,
    updateNotesOnChatPartner,
    isLoading,
    isError,
  };
};
export default useChatItem;
