import { create } from "zustand";

export type ChatFilter = "unread" | undefined;

type ChatFilterState = {
  filter: ChatFilter;
  setFilter: (filter: ChatFilter) => void;
};

const useChatFilterState = create<ChatFilterState>((set) => ({
  filter: undefined,
  setFilter: (filter) => set({ filter }),
}));

export default useChatFilterState;
