import { ChatListDto, CreateChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import TextInput from "components/basics/TextInput";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { Form, Formik } from "formik";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import useListsState from "state/lists/listsState";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import * as Yup from "yup";

type Props = { replaceStateOnSave?: boolean } & PopUpComponent & BaseComponent;

const CreateNewListPopup = ({
  replaceStateOnSave = false,
  open,
  onClose,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { addOrReplaceList } = useListsState();
  const navigate = useStatefulNavigate();
  const { state } = useLocation();

  const createListMutation = useMutation({
    mutationFn: (newChatList: CreateChatListDto) => maloumClient.chatLists.create(newChatList),
  });

  const handleCreateList = (name: string) =>
    createListMutation.mutate(
      { name },
      {
        onSuccess: (list: ChatListDto) => {
          addOrReplaceList(list, true);
          navigate(".", { state: { ...state, listId: list._id }, replace: replaceStateOnSave });
          onClose();
        },
      },
    );

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("lists.new.title") as string} data-testid={dataTestId}>
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(65, ({ max }) => t("validation.max", { max: max }))
            .matches(/[^\s*].*[^\s*]/g, t("validation.required") as string)
            .required(t("validation.required") as string),
        })}
        onSubmit={(values) => handleCreateList(values.name)}
      >
        <Form className={classNames("mt-4 text-left", className)}>
          <TextInput
            name="name"
            type="text"
            maxLength={65}
            label={t("lists.new.listName")}
            placeholder={t("lists.new.placeHolder") as string}
          />
          <Button type="submit" text={t("lists.new.create")} disabled={createListMutation.isPending} />
        </Form>
      </Formik>
    </PopUp>
  );
};

export default CreateNewListPopup;
