import PopUp from "components/popup/PopUp";
import { useState } from "react";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import RenameListPopup from "./RenameListPopup";
import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import DeleteListPopup from "./DeleteListPopup";
import { useTranslation } from "react-i18next";

type Props = { list: ChatListDto } & BaseComponent & PopUpComponent;

const ListMenuPopup = ({ list, open, onClose, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();

  const [renamePopupOpen, setRenamePopupOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const options = [
    {
      title: t("lists.editMenu.rename"),
      onClick: () => {
        onClose();
        setRenamePopupOpen(true);
      },
    },
    {
      title: t("lists.editMenu.delete"),
      onClick: () => {
        onClose();
        setDeletePopupOpen(true);
      },
    },
  ];

  if (renamePopupOpen)
    return <RenameListPopup list={list} open={renamePopupOpen} onClose={() => setRenamePopupOpen(false)} />;

  if (deletePopupOpen)
    return <DeleteListPopup listId={list._id} open={deletePopupOpen} onClose={() => setDeletePopupOpen(false)} />;

  return (
    <PopUp
      isOpen={open}
      onClose={onClose}
      title={t("lists.editMenu.title") as string}
      options={options}
      data-testid={dataTestId}
    />
  );
};

export default ListMenuPopup;
