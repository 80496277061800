import classNames from "classnames";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import ListName from "components/atoms/lists/ListName";
import HeaderBar from "components/layouts/HeaderBar";
import ListMenuPopup from "components/molecules/lists/popups/ListMenuPopup";
import MembersList from "components/organisms/lists/MembersList";
import useDebounceQuery from "hooks/common/useDebounceQuery.hook";
import useMembers from "hooks/lists/members/useMembers.hook";
import useList from "hooks/lists/useList.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiEllipsisHorizontal, HiOutlinePencilSquare, HiOutlinePlus } from "react-icons/hi2";
import useSelectedListsState from "state/lists/chat/selectedListsState";

type Props = {
  listId: string;
};

const ListDetail = ({ listId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { list } = useList(listId);
  const { addOrRemoveList, reset } = useSelectedListsState();
  const { query, debounceQuery, isTyping, setDebounceQuery } = useDebounceQuery("memberQuery");
  const { members, isMembersLoading, hasNextPage, fetchNextPage } = useMembers(listId, query, true);

  const [menuOpen, setMenuOpen] = useState(false);

  if (!list) return <ListDetailLoading />;

  const handleGoToAddMembers = () => navigate(`/lists/add/${list._id}`);

  const handleSendBroadcast = () => {
    reset();
    addOrRemoveList(list, "include");
    navigate("/chat/new", { state: { keepSelection: true } });
  };

  return (
    <>
      <div>
        <HeaderBar alwaysShowDividerXL>
          <HeaderBar.SubPage>
            <HeaderBar.Left className="basis-0 md:ml-0 md:basis-full">
              <ListName list={list} className="hidden text-xl font-semibold md:block" />
              <HeaderBar.BackButton className="md:hidden" />
            </HeaderBar.Left>
            <HeaderBar.Center className="flex basis-full justify-center md:hidden">
              <HeaderBar.Title>
                <ListName list={list} />
              </HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right className="mr-0 basis-0 md:basis-px">
              <div className="h-10" />
              <HeaderBar.Button
                className={classNames("flex items-center py-1.5", { "pr-0": !list.isManaged })}
                onClick={handleSendBroadcast}
              >
                <HiOutlinePencilSquare className="h-5 w-5" />
              </HeaderBar.Button>
              {!list.isManaged && (
                <HeaderBar.Button className="py-1.5" onClick={() => setMenuOpen(true)}>
                  <HiEllipsisHorizontal className="h-6 w-6" />
                </HeaderBar.Button>
              )}
            </HeaderBar.Right>
          </HeaderBar.SubPage>
        </HeaderBar>
        <div className="px-4 md:px-0">
          <div className="mt-4 flex items-center justify-between">
            <div className="text-xl font-semibold">{t("lists.item.members", { count: list.totalMemberCount })}</div>
            {!list.isManaged && (
              <TernaryButton variant="red" size="sm" className="px-0 py-0.5" onClick={handleGoToAddMembers}>
                <div>{t("lists.detail.addMembers")}</div>
                <HiOutlinePlus className="ml-1 h-5 w-5" />
              </TernaryButton>
            )}
          </div>
          {list.totalMemberCount > 0 && (
            <MembersList
              className="mt-4 pb-12"
              listId={list._id}
              isManaged={list.isManaged}
              members={members}
              isLoading={isMembersLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              query={debounceQuery}
              setQuery={setDebounceQuery}
              isTyping={isTyping}
            />
          )}
          {list.totalMemberCount === 0 && <div className="mt-4 text-gray-500">{t("lists.detail.noMembers")}</div>}
        </div>
      </div>
      <ListMenuPopup list={list} open={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
};

export default ListDetail;

const ListDetailLoading = () => {
  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:ml-0">
            <div className="hidden h-6 w-32 rounded-md bg-gray-100 text-xl font-semibold md:block" />
            <HeaderBar.BackButton className="md:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center className="md:hidden">
            <div className="mx-auto h-5 w-24 rounded-md bg-gray-100" />
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0">
            <div className="m-2 h-6 w-6 rounded-md bg-gray-100" />
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="px-4 md:px-0">
        <div className="mt-5 flex items-center justify-between">
          <div className="h-6 w-28 rounded-md bg-gray-100" />
          <div className="h-5 w-32 rounded-md bg-gray-100" />
        </div>
        <div className="mt-4 flex h-10 w-full items-center rounded-md bg-gray-100 px-4" />
        <div className="mt-4 flex flex-col gap-3">
          {Array.from({ length: 10 }).map((_, i) => (
            <div key={i} className="h-10 w-full rounded-md bg-gray-100" />
          ))}
        </div>
      </div>
    </div>
  );
};
