import classNames from "classnames";
import Button from "components/basics/Button";
import TextInput from "components/basics/TextInput";
import PopUp from "components/popup/PopUp";
import { Form, Formik } from "formik";
import useVaultFolders from "hooks/vault/useVaultFolders.hook";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FolderDto } from "@neolime-gmbh/api-gateway-client";

type Props = {
  isOpen: boolean;
  close: (folderId?: string) => void;
  className?: string;
  "data-testid"?: string;
};

type NewVaultFolderType = {
  folderName: string;
};

const initialValues: NewVaultFolderType = {
  folderName: "",
};

// TODO: handle error cases (general error, folder name already exists)
const CreateNewFolderPopup = ({ isOpen, close, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { createFolderMutation } = useVaultFolders("", false);
  const handleOnSubmit = (values: NewVaultFolderType) =>
    createFolderMutation.mutate(values.folderName.trim(), { onSuccess: (folder: FolderDto) => close(folder._id) });

  const schema = Yup.object().shape({
    folderName: Yup.string()
      .min(1, "")
      .max(65, "")
      .required(t("validation.required") as string),
  });

  return (
    <PopUp isOpen={isOpen} onClose={close} title={t("vault.newFolder.title") as string} data-testid={dataTestId}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleOnSubmit}>
        <Form className={classNames("mt-4 text-left", className)}>
          <TextInput
            name="folderName"
            maxLength={65}
            placeholder={t("vault.newFolder.title") as string}
            label={t("vault.newFolder.label") as string}
          />
          <Button
            type="submit"
            className="mt-6"
            text={t("vault.newFolder.create")}
            disabled={createFolderMutation.isPending}
          />
        </Form>
      </Formik>
    </PopUp>
  );
};

export default CreateNewFolderPopup;
