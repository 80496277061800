import { CreatePostDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/layouts/Layout";
import EditPostData from "../components/post/EditPostData";
import MaloumClientContext from "../contexts/MaloumClientContext";
import useUserStore from "../state/userState";
import HeaderBar from "components/layouts/HeaderBar";

const CreatePostPage = () => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { maloumClient } = useContext(MaloumClientContext);
  const bumpPublicPostCount = useUserStore((state) => state.bumpPublicPostCount);
  const bumpPrivatePostCount = useUserStore((state) => state.bumpPrivatePostCount);

  const submitPost = async (post: CreatePostDto) => {
    await maloumClient.posts.create(post).then(() => {
      if (post.public) bumpPublicPostCount();
      else bumpPrivatePostCount();

      // Google Tag Manager: New Post Event
      // @ts-expect-error - dataLayer is not defined in the global scope
      window.dataLayer = window.dataLayer || [];
      // @ts-expect-error - dataLayer is not defined in the global scope
      window.dataLayer.push({
        event: "new_post",
        email: user?.email,
        user_id: user?._id,
        is_post_public: post.public,
      });
    });
  };

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("posts.add")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <EditPostData submit={submitPost} isEditMode={false} />
    </Layout>
  );
};

export default CreatePostPage;
