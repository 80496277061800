import classNames from "classnames";
import { HiOutlineCheck } from "react-icons/hi2";

type Props = {
  isSelected: boolean;
  onClick: () => void;
  className?: string;
  "data-testid"?: string;
};

const SelectionButton = ({ isSelected, onClick, className, "data-testid": dataTestId }: Props) => {
  return (
    <button
      className={classNames("pb-6 pl-3 pr-6 pt-3 md:pb-12 md:pr-12", className)}
      data-testid={dataTestId}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {isSelected ? (
        <div className="flex h-5 w-5 items-center justify-center rounded-md bg-red-900">
          <HiOutlineCheck className="stroke-[3px] text-white" />
        </div>
      ) : (
        <div className="h-5 w-5 rounded-md border border-gray-200 bg-white" />
      )}
    </button>
  );
};

export default SelectionButton;
