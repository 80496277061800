import { MessageDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useEffect, useState } from "react";
import { useMessagesQuery } from "./queries/useMessagesQuery.hook";
import { sortMessages } from "helper/messageHelper";
import { useChatsContext } from "contexts/ChatsContext";
import { debounce } from "lodash";

const DEBOUNCE_MS = 50;

const useMessages = (id: string) => {
  const { data: messagesData, isLoading, fetchNextPage, hasNextPage, isFetching } = useMessagesQuery(id);

  const [messages, setMessages] = useState<MessageDto[]>(() =>
    messagesData ? sortMessages({ newMessages: messagesData.pages.flatMap((p) => p.data), chatId: id }) : [],
  );

  const debouncedSetMessages = useCallback(
    debounce((messages: MessageDto[]) => {
      setMessages(messages);
    }, DEBOUNCE_MS),
    [],
  );

  const { markChatAsRead } = useChatsContext();

  const sortAndSetMessages = (messagesToAdd: MessageDto[]) => {
    // mark chat as read
    markChatAsRead(id);

    const newMessages = sortMessages({
      newMessages: messagesToAdd,
      chatId: id,
    });

    debouncedSetMessages(newMessages);
  };

  // move messages into state, flatMap and load chat products
  useEffect(() => {
    const loadAndSetMessages = () => {
      if (!messagesData || messagesData.pages.length === 0 || messagesData.pages[0].data.length === 0)
        debouncedSetMessages([]);
      else sortAndSetMessages(messagesData.pages.flatMap((p) => p.data));
    };
    loadAndSetMessages();

    return () => {
      debouncedSetMessages?.cancel();
    };
  }, [messagesData]);

  return {
    messages,
    isLoading,
    isFetching,
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
  };
};
export default useMessages;
