import { InboxType } from "@neolime-gmbh/api-gateway-client";
import { ChatFilter } from "state/chatFilterState";

type BaseKeyGenerationProps = {
  type: InboxType;
  isCreator?: boolean;
};

export const getChatQueryKey = ({
  type,
  isCreator,
  filter,
}: BaseKeyGenerationProps & {
  filter?: ChatFilter;
}) => ["chats", type, isCreator, filter] as const;

export const getAllChatQueryKeysForInbox = ({ type, isCreator }: BaseKeyGenerationProps) => {
  const keys = [getChatQueryKey({ type, isCreator })];

  // only Primary inbox has unread filter
  if (type === InboxType.PRIMARY) {
    keys.push(getChatQueryKey({ type, isCreator, filter: "unread" }));
  }

  return keys;
};
