import SearchInput from "components/molecules/basics/SearchInput";
import NoResults from "components/utilities/NoResults";
import useDebounceQuery from "hooks/common/useDebounceQuery.hook";
import useVaultFolders from "hooks/vault/useVaultFolders.hook";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { BaseComponent } from "types/components/component.type";
import { useTranslation } from "react-i18next";
import { ListItem, LoadingListEntryItem } from "components/molecules/ListItem";

type Props = {
  selectedFolderId?: string;
  onFolderSelection: (folderId: string) => void;
} & BaseComponent;

const FolderList = ({ selectedFolderId, onFolderSelection, className }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { query, debounceQuery, isTyping, setDebounceQuery } = useDebounceQuery();

  const { folders, isLoading, hasNextPage, fetchNextPage } = useVaultFolders(query);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading) fetchNextPage();
  }, [inView, isLoading, hasNextPage]);

  return (
    <div className={className}>
      <SearchInput
        value={debounceQuery}
        onChange={setDebounceQuery}
        placeholder={t("vault.search.placeholder") || ""}
        className="mb-4"
      />
      <div className="relative flex flex-col gap-1.5 pb-16 xl:gap-0">
        {!isTyping &&
          folders.map((folder) => (
            <ListItem
              name={folder.isManaged ? t(`vault.managedFolders.${folder.name}`) : folder.name}
              selected={selectedFolderId === folder._id}
              onSelect={() => onFolderSelection(folder._id)}
              key={folder._id}
              thumbnails={folder.mostRecentMediaThumbnails}
              videoCount={folder.videoCount}
              pictureCount={folder.pictureCount}
            />
          ))}
        {!!debounceQuery && query && !isTyping && !folders.length && !isLoading && (
          <div>
            <NoResults searchedText={debounceQuery} />
            <div className="text-center text-sm text-gray-500">{t("vault.search.noResults")}</div>
          </div>
        )}
        {(isLoading || isTyping) &&
          Array.from(Array(10).keys()).map((i) => <LoadingListEntryItem key={i} className="xl:mt-1.5" />)}
        <div className="absolute bottom-40" ref={ref} />
      </div>
    </div>
  );
};

export default FolderList;
