import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import useQueryManipulator from "hooks/useQueryManipulator";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

type Props = {
  chatId: string;
  userId: string;
  open: boolean;
  onClose: () => void;
  "data-testid"?: string;
};

const BlockUserPopup = ({ chatId, userId, open, onClose, "data-testid": dataTestId }: Props) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { invalidateChat } = useQueryManipulator();
  const { t } = useTranslation();

  const handleBlockUser = useMutation({
    mutationFn: () => maloumClient.users.blockUsers({ userIds: [userId] }),
    onSuccess: () => {
      invalidateChat(chatId);
      onClose();
    },
  });

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("chatSettings.block.blockUser") as string} data-testid={dataTestId}>
      <div className="mt-2">{t("chatSettings.block.blockUserConfirmation")}</div>
      <div className="mt-4 flex items-center gap-2">
        <Button text={t("cancel")} variant="secondary" onClick={onClose} disabled={handleBlockUser.isPending} />
        <Button
          text={t("chatSettings.block.block")}
          onClick={handleBlockUser.mutate}
          disabled={handleBlockUser.isPending}
        />
      </div>
    </PopUp>
  );
};

export default BlockUserPopup;
