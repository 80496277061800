import { useFirstline } from "@first-line/firstline-react";
import { ChatProductMessageContentDto, DeleteMessageDto, InboxType, ObjectId } from "@neolime-gmbh/api-gateway-client";
import { DeleteMessageUpdateDto } from "@neolime-gmbh/api-gateway-client";
import { MessageDto } from "@neolime-gmbh/api-gateway-client";
import { env } from "env";
import { createContext, ReactNode, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import unreadChatsCounts from "state/unreadChatsState";

export interface ServerToClientEvents {
  receive_message: (message: MessageDto & { inboxType: InboxType }) => void;
  upload_completed: (message: MessageDto) => void;
  delete_message_update: (
    message: DeleteMessageUpdateDto & { inbox: InboxType; lastRelevantMessageForMember: ObjectId },
  ) => void;
  total_spend_updated: (data: { chatId: string; totalSpend: number }) => void;
  chat_inbox_updated: (data: { chatId: string; inbox: InboxType }) => void;
  refresh_unread_chats: (data: { unreadChatsCount: number; unreadRequestsCount: number }) => void;
  send_message_error: (data: { chatId: string; messageId: string }) => void;
  update_message_price: (
    message: Exclude<MessageDto, "optimisticMessageId"> & {
      inboxType: InboxType;
      content: ChatProductMessageContentDto;
    },
  ) => void;
  broadcast_sent: (data: { chatIds: string[] }) => void;
}

interface ClientToServerEvents {
  authenticate: (message: string) => void;
  read_messages: (data: { chat: string }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send_message: (data: { chat: ObjectId; optimisticMessageId?: string; content: Record<string, any> }) => void;
  delete_message: (data: DeleteMessageDto) => void;
}

const SocketContext = createContext<Socket<ServerToClientEvents, ClientToServerEvents> | undefined>(undefined);
export default SocketContext;

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessToken } = useFirstline();
  const [socket, setSocket] = useState<Socket<ServerToClientEvents, ClientToServerEvents>>();

  const setUpWebsocket = async () => {
    const socket: Socket<ServerToClientEvents, ClientToServerEvents> = await connectWebSocket();
    setSocket(socket);

    socket.on("connect", onConnect(socket));
    socket.on("refresh_unread_chats", onRefreshUnreadChats);
    return socket;
  };

  const connectWebSocket = async () => {
    return io(env.VITE_NEOLIME_WS_BACKEND_URL, {
      upgrade: true,
      transports: ["websocket"],
      auth: {
        authorization: `Bearer ${await getAccessToken()}`,
      },
    });
  };

  const onConnect = (socket: Socket) => () => {
    socket?.emit("authenticate", "ack");
  };

  const onRefreshUnreadChats = ({
    unreadChatsCount,
    unreadRequestsCount,
  }: {
    unreadChatsCount: number;
    unreadRequestsCount: number;
  }) => {
    unreadChatsCounts.setState({ unreadChatsCount, unreadRequestsCount });
  };

  useEffect(() => {
    const socket = setUpWebsocket();
    return () => {
      socket.then((socket) => {
        socket.off("connect", onConnect(socket));
        socket.off("refresh_unread_chats", onRefreshUnreadChats);
      });
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
