import MaloumClientContext from "contexts/MaloumClientContext";
import { useCallback, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

const useSuggestedCreators = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchSuggestedCreators = useCallback(() => {
    return maloumClient.users.getSuggestedCreators();
  }, [maloumClient]);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["suggested-creators"],
    queryFn: fetchSuggestedCreators,
    staleTime: 60 * 10 * 1000 /* 10 minutes */,
  });

  return {
    suggestedCreators: data,
    isLoading,
    isError,
  };
};

export default useSuggestedCreators;
