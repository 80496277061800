import classNames from "classnames";
import Media from "components/media/Media";
import MediaRemoveButton from "components/media/MediaRemoveButton";
import { useTranslation } from "react-i18next";
import { MouseEvent } from "react";
import { useLocation } from "react-router-dom";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

type Props = {
  handleRedirectToVault: () => void;
  processingMessage?: string;
  className?: string;
  "data-testid"?: string;
};

const ContentMediaSelect = ({
  handleRedirectToVault,
  processingMessage,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();

  // get selected media from location state
  const { state } = useLocation();
  const selectedMedia = (state?.selectedMedia ?? []) as TSelectedMedia[];
  const media = selectedMedia[0];

  const handleOnMediaSelect = (event: MouseEvent<HTMLButtonElement>) => {
    // needed or it will bubble up to the parent and submit forms
    event.preventDefault();
    event.stopPropagation();
    // only if no media is selected
    if (media) return;
    // handle store state and set origin
    handleRedirectToVault();
  };

  return (
    <button
      className={classNames("relative mx-auto aspect-[1.5/1] min-h-fit w-full max-w-xl bg-beige-400", className, {
        "flex flex-col items-center justify-center": !media,
      })}
      onClick={handleOnMediaSelect}
      data-testid={dataTestId}
    >
      {media ? (
        <>
          {
            <Media
              media={media}
              thumbnail={media.thumbnail}
              cover={false}
              processingMessage={processingMessage}
              className={classNames("")}
              data-testid={dataTestId}
            />
          }
          <MediaRemoveButton selectedMediaId={media._id} className="z-[10]" />
        </>
      ) : (
        <>
          <img src="/images/addimage.svg" alt="" />
          <span className="text-red-900">{t("add-media")}</span>
        </>
      )}
    </button>
  );
};

export default ContentMediaSelect;
