import { useContext, useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useLikes = (_id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchLikes = async ({ pageParam = undefined }: { pageParam?: string }) => {
    return maloumClient.posts.getLikes(_id, pageParam, 30);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["likes", _id],
    queryFn: fetchLikes,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
  });

  const dataFlatMap = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((l) => l.data);
  }, [data]);

  return { likes: dataFlatMap, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage };
};

export default useLikes;
