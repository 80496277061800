import { Tab } from "@headlessui/react";
import { InboxType } from "@neolime-gmbh/api-gateway-client";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import MyTab from "components/basics/MyTab";
import { PhotoIcon as PhotoIconOutline } from "@heroicons/react/24/outline";
import ChatRequestActions from "components/chats/ChatRequestActions";
import ChatSettingsPopup from "components/chats/chatSettings/ChatSettingsPopup";
import FanInfo from "components/chats/FanInfo";
import LoadingChat from "components/chats/LoadingChat";
import MessageBar, { MessageBarRef } from "components/chats/MessageBar";
import MessageList, { MessageListRef } from "components/chats/MessageList";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import ChatUserCard from "components/molecules/ChatUserCard";
import { useChatsContext } from "contexts/ChatsContext";
import useChatItem from "hooks/useChatItem.hook";
import useMaloumOfficial from "hooks/useMaloumOfficial.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiChatBubbleOvalLeft,
  HiEllipsisHorizontal,
  HiInformationCircle,
  HiOutlineChatBubbleOvalLeft,
  HiOutlineInformationCircle,
} from "react-icons/hi2";
import { useLocation, useParams } from "react-router-dom";
import { useChatScrollStore } from "state/chatScrollState";
import useUserStore from "state/userState";

type Props = {
  messageBarRef?: RefObject<MessageBarRef>;
};

const Chat = ({ messageBarRef }: Props) => {
  const { id } = useParams();
  const messageListRef = useRef<MessageListRef>(null);
  const { maloumOfficial } = useMaloumOfficial();
  const navigate = useStatefulNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [settingsPopupIsOpen, setSettingsPopupIsOpen] = useState(false);
  const user = useUserStore((state) => state.user);

  if (!id) return <StatefulNavigate to="/chat" />;

  const { chat } = useChatItem(id);
  const chatPartner = chat?.chatPartner;
  const { markChatAsRead } = useChatsContext();

  const handleNavigationBack = () => {
    const useFallback = state?.forceFallback || state?.host !== window.location.hostname;

    if (useFallback) navigateToChatsInbox();
    else if (state?.fromInbox && state?.fromInbox !== chat?.inbox) navigateToChatsInbox();
    else navigate(-1);
  };

  const navigateToChatsInbox = () => {
    const path = chat?.inbox === InboxType.REQUEST ? "/chat?tab=request" : "/chat";
    navigate(path, { replace: true });
  };

  const navigateToGallery = () => {
    if (state?.lastViewedPage === "gallery") {
      navigate(-1);
    } else {
      navigate(`/collection/${chatPartner?._id}`, { state: { lastViewedPage: "chat" } });
    }
  };

  const resetMessageScroll = useChatScrollStore((state) => state.resetMessageScroll);

  useEffect(() => {
    if (id) {
      markChatAsRead(id);
    }
    return () => {
      resetMessageScroll();
    };
  }, [id]);

  if (!chatPartner) return <LoadingChat />;

  const chatIsPrimary = chat.inbox === InboxType.PRIMARY;

  const renderMessageInterface = () => (
    <>
      <MessageList ref={messageListRef} chatId={id} chat={chat} key={`message-list-${id}`} />
      {chat.chatPartner.isBlocked || chat.chatPartner.hasBlocked || chatIsPrimary || state?.data?.mockPrimary ? (
        <MessageBar ref={messageBarRef} chatId={id} key={`message-bar-${id}`} />
      ) : (
        <ChatRequestActions chat={chat} userId={chat.chatPartner._id} key={`request-actions-${id}`} />
      )}
    </>
  );

  const chatPartnerIsNotMaloum = chatPartner._id !== maloumOfficial?._id;
  const displayMediaGallery =
    !user.isCreator && chatPartner.isCreator && chatPartnerIsNotMaloum && chatIsPrimary && chat.hasMediaGallery;

  return (
    <Layout hasNavigation={false} hideNavigationMobile hideVerificationButton growFullHeight>
      <Container className="flex h-full max-h-screen flex-col md:max-w-chat" hasPadding={false}>
        <Tab.Group onChange={() => messageListRef.current?.saveScroll()}>
          <HeaderBar className="md:max-w-chat" alwaysShowDivider>
            <HeaderBar.SubPage className="sm:!pt-4">
              <HeaderBar.Left className="flex basis-2/12 items-center">
                <HeaderBar.BackButton onClick={handleNavigationBack} />
              </HeaderBar.Left>

              <HeaderBar.Center className="flex basis-8/12 items-center">
                <ChatUserCard
                  url={chatPartner.profilePictureThumbnail?.url}
                  username={chatPartner.username}
                  isCreator={chatPartner.isCreator}
                  uploadStatus={chatPartner.profilePictureThumbnail?.uploadStatus}
                  userIsVerified={chatPartner.isVerified}
                  nickname={chatPartner.nickname}
                />
              </HeaderBar.Center>

              <HeaderBar.Right className="flex basis-2/12 items-center gap-2 px-2">
                {displayMediaGallery && (
                  <HeaderBar.Button className="!mx-0 !px-0" onClick={navigateToGallery}>
                    <PhotoIconOutline className="h-6 w-6" />
                  </HeaderBar.Button>
                )}

                {!chatPartner.isBlocked && chatPartnerIsNotMaloum && (
                  <HeaderBar.Button className="!mx-0 !px-0" onClick={() => setSettingsPopupIsOpen(true)}>
                    <HiEllipsisHorizontal className="h-6 w-6" />
                  </HeaderBar.Button>
                )}
              </HeaderBar.Right>
            </HeaderBar.SubPage>

            {user?.isCreator && (
              <Tab.List className="flex chatBreakpoint:hidden">
                <MyTab
                  title={t("message.title") as string}
                  defaultIcon={<HiOutlineChatBubbleOvalLeft className="mr-1 h-5 w-5" />}
                  activeIcon={<HiChatBubbleOvalLeft className="mr-1 h-5 w-5" />}
                />
                <MyTab
                  title={t("fans.info") as string}
                  defaultIcon={<HiOutlineInformationCircle className="mr-1 h-5 w-5" />}
                  activeIcon={<HiInformationCircle className="mr-1 h-5 w-5" />}
                />
              </Tab.List>
            )}
          </HeaderBar>

          {user?.isCreator ? (
            <Tab.Panels className="relative flex-1 overflow-hidden md:max-w-chat">
              <Tab.Panel className="absolute inset-0 flex flex-col px-4">{renderMessageInterface()}</Tab.Panel>
              <Tab.Panel className="absolute inset-0 overflow-hidden px-4">
                <FanInfo />
              </Tab.Panel>
            </Tab.Panels>
          ) : (
            <div className="relative flex-1 overflow-hidden">
              <div className="absolute inset-0 flex flex-col px-4">{renderMessageInterface()}</div>
            </div>
          )}

          <ChatSettingsPopup chat={chat} open={settingsPopupIsOpen} onClose={() => setSettingsPopupIsOpen(false)} />
        </Tab.Group>
      </Container>
    </Layout>
  );
};

export default Chat;
