import { ChatDto, MessagePreviewDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import useCurrency from "hooks/useCurrency.hook";
import { useTranslation } from "react-i18next";
import { HiOutlinePhoto } from "react-icons/hi2";
import { generateTimeTag } from "../../helper/dateAndTimeHelper";
import ProfileImage from "../basics/ProfileImage";
import { useParams, useSearchParams } from "react-router-dom";
import Pill from "components/atoms/Pill";

type ChatMessageItemProps = {
  message?: MessagePreviewDto;
};

const ChatMessageItem = ({ message }: ChatMessageItemProps) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const tip = message?.tip ? displayCurrency(message.tip) : "";
  switch (message?.type) {
    case "chat_product":
      return (
        <>
          <HiOutlinePhoto />
          <span title={t("exclusiveContent") as string}>{t("exclusiveContent")}</span>
        </>
      );

    case "media":
      return (
        <>
          <HiOutlinePhoto />
          <span title={t("mediaSingular") as string}>{t("mediaSingular")}</span>
        </>
      );
    case "text":
      return (
        <span className="truncate" title={message.text}>
          {message.text}
        </span>
      );
    case "tip":
      return <span title={tip}>{tip}</span>;
    default:
      return <span>{t("no-messages")}</span>;
  }
};

type Props = {
  chatItem: ChatDto;
};

const ChatItem = ({ chatItem }: Props) => {
  const { id } = useParams();

  const isSelected = chatItem._id === id;

  const user = chatItem.chatPartner;
  const chatDate = chatItem.lastRelevantMessage
    ? generateTimeTag(new Date(chatItem.lastRelevantMessage.sentAt))
    : generateTimeTag(new Date(chatItem.createdAt));

  // get "tab" from searchParams
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const tabSearchParam = tab === "request" ? `?tab=request` : "";

  const { displayCurrencyWithThousands } = useCurrency();

  const hasPills = Boolean(chatItem.chatPartner.totalSpendForCreator);

  return (
    <>
      <StatefulLink
        className={classNames(
          "flex min-w-0 items-start gap-0.5 px-4 hover:bg-beige-300",
          isSelected ? "rounded-sm bg-beige-400 active:bg-beige-500" : "bg-white hover:bg-beige-300 active:bg-white",
          hasPills ? "py-2" : "py-3",
        )}
        to={`/chat/${chatItem._id}${tabSearchParam}`}
        state={{ fromInbox: chatItem.inbox }}
        key={`listitemDynamic-${chatItem._id}`}
      >
        <div id="avatarAndText" className="flex min-w-0 flex-grow items-center gap-3">
          <ProfileImage
            url={user?.profilePictureThumbnail?.url}
            className={"h-[60px] w-[60px] flex-none shrink-0"}
            uploadStatus={user?.profilePictureThumbnail?.uploadStatus}
            key={`profile-image-${user?.profilePictureThumbnail?.url}`}
          />
          <div id="messageAndInfo" className="flex min-w-0 flex-grow flex-col items-start gap-1">
            <div id="usernameAndMessage" className="flex flex-col items-start self-stretch">
              <div className="flex w-full items-center">
                <div
                  id="usernameAndVerifiedBadge"
                  className={classNames(
                    "notranslate line-clamp-1 flex grow items-center gap-1 pr-2 text-base",
                    chatItem.unreadMessages && "font-semibold",
                  )}
                >
                  <span className="truncate">{user?.nickname || user?.username}</span>{" "}
                  {user?.isVerified && <VerifiedUserIcon />}
                </div>
              </div>
              <div className="flex w-full min-w-0 items-center justify-between">
                <div
                  className={classNames("flex grow items-center gap-0.5 self-stretch truncate text-sm", {
                    "font-medium": chatItem.unreadMessages,
                    "text-gray-500": !chatItem.unreadMessages && !isSelected,
                  })}
                >
                  <ChatMessageItem message={chatItem.lastRelevantMessage} />
                </div>
              </div>
            </div>
            {hasPills && (
              <div id="rankAndSpending" className="flex items-start gap-1.5">
                <Pill size="xs" colors="bg-[#45404A] text-white" className="px-2.5 py-0.5">
                  {displayCurrencyWithThousands(chatItem.chatPartner.totalSpendForCreator)}
                </Pill>
              </div>
            )}
          </div>
        </div>
        <div id="dateAndIndicator" className="flex flex-col items-end gap-1">
          <span
            className={classNames(
              "line-clamp-1 flex-shrink-0 text-sm",
              !isSelected && "text-gray-500",
              chatItem.unreadMessages && "font-medium text-red-900",
            )}
          >
            {chatDate}
          </span>
          {chatItem.unreadMessages && (
            <div className="h-4 w-4 shrink-0 rounded-full bg-red-900" data-testid={"unread-messages-indicator"} />
          )}
        </div>
      </StatefulLink>
      <div className="mx-4 box-border border-b border-gray-100" />
    </>
  );
};

export default ChatItem;
