import { ChatDto } from "@neolime-gmbh/api-gateway-client";
import { useQueryClient } from "@tanstack/react-query";

export const useSingleChatQueryManipulator = () => {
  const queryClient = useQueryClient();

  const updateTotalSpendForSingleChatItem = ({ chatId, totalSpend }: { chatId: string; totalSpend: number }) => {
    queryClient.setQueryData(["fetch-chat", chatId], (old: ChatDto) => {
      if (!old) return old;

      return {
        ...old,
        chatPartner: { ...old.chatPartner, totalSpendForCreator: totalSpend },
      };
    });
  };

  return { updateTotalSpendForSingleChatItem };
};
