import classNames from "classnames";
import VaultMedia from "components/molecules/vault/media/VaultMedia";
import useVaultMedia from "hooks/vault/useVaultMedia.hook";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import MediaUpload from "components/molecules/vault/MediaUpload";
import useUploadStore from "state/uploadState";
import VaultMediaUpload from "components/molecules/vault/media/VaultMediaUpload";
import { BaseComponent } from "types/components/component.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import { FolderDto } from "@neolime-gmbh/api-gateway-client";
import { useLocation } from "react-router-dom";
import { ThreeColMediaGrid } from "../ThreeColMediaGrid";
import { LoadingMedia } from "components/molecules/LoadingMedia";

type Props = {
  folder: FolderDto;
  selectedMedia: TSelectedMedia[];
  handleSelectMedia: (media: TSelectedMedia) => void;
} & BaseComponent;

const VaultMediaGrid = ({ folder, selectedMedia, handleSelectMedia, className, "data-testid": dataTestId }: Props) => {
  const { ref, inView } = useInView();
  const { state } = useLocation();
  const fanId = state?.fanId as string | undefined;

  const { media, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useVaultMedia(folder._id, fanId);

  // uploads
  const { uploads } = useUploadStore();

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) fetchNextPage();
  }, [inView, isLoading]);

  const relevantUploads = folder.isManaged ? uploads : uploads.filter((u) => u.folderIds?.includes(folder._id));

  return (
    <ThreeColMediaGrid className={className} data-testid={dataTestId}>
      <MediaUpload folderId={folder._id} />
      {[...relevantUploads].reverse().map((u) => (
        <VaultMediaUpload upload={u} selectedMedia={selectedMedia} handleSelectMedia={handleSelectMedia} key={u.id} />
      ))}
      {media.map((m) => (
        <VaultMedia media={m} selectedMedia={selectedMedia} handleSelectMedia={handleSelectMedia} key={m.upload._id} />
      ))}
      {(isLoading || isFetchingNextPage) && Array.from({ length: 8 }).map((_, i) => <LoadingMedia key={i} />)}
      {!isLoading && <div className="absolute bottom-40" ref={ref} />}
    </ThreeColMediaGrid>
  );
};

export default VaultMediaGrid;
