import NoResults from "components/utilities/NoResults";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { BaseComponent } from "types/components/component.type";
import { useTranslation } from "react-i18next";
import { useMediaGallery } from "hooks/media-gallery/useMediaGallery.hook";
import { ListItem, LoadingListEntryItem } from "components/molecules/ListItem";

type Props = BaseComponent & {
  onMediaGallerySelection: (mediaGalleryId: string) => void;
  selectedMediaGalleryId?: string;
};

export const CollectionList = ({ className, onMediaGallerySelection, selectedMediaGalleryId }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const { mediaGallery, hasNextPage, fetchNextPage, isLoading } = useMediaGallery();

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading) fetchNextPage();
  }, [inView, isLoading, hasNextPage]);

  return (
    <div className={className}>
      <div className="relative flex flex-col gap-1.5 pb-16 xl:gap-0">
        {mediaGallery?.map((media) => {
          const creatorId = media.creatorId?.toString() ?? "all";
          return (
            <ListItem
              name={media.isGeneralCollection ? t("collection.allCreators") : media.creatorUsername}
              thumbnails={media.last3MediaThumbnails}
              videoCount={media?.counts?.videos}
              pictureCount={media?.counts?.images}
              selected={selectedMediaGalleryId === creatorId}
              onSelect={() => onMediaGallerySelection(creatorId)}
              key={creatorId}
            />
          );
        })}

        {!mediaGallery?.length && !isLoading && (
          <div>
            <NoResults />
            <div className="text-center text-sm text-gray-500">{t("vault.search.noResults")}</div>
          </div>
        )}

        {isLoading && Array.from(Array(10).keys()).map((i) => <LoadingListEntryItem key={i} className="xl:mt-1.5" />)}

        <div className="absolute bottom-40" ref={ref} />
      </div>
    </div>
  );
};
