import TwoColumnLayout from "components/layouts/TwoColumnLayout";
import { Collections } from "./Collections";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionDetail } from "./CollectionDetail";
import { EmptyGallerySelection } from "components/molecules/media-gallery/EmptyGallerySelection";
import { useTranslation } from "react-i18next";
export const CollectionParent = () => {
  const { mediaGalleryId } = useParams();

  const navigate = useNavigate();

  const onMediaGallerySelection = (mediaGalleryId: string) => {
    navigate(`/collection/${mediaGalleryId}`);
  };

  const onBack = () => {
    navigate(-1);
  };

  const { t } = useTranslation();

  const center = (
    <div className="flex h-full min-h-screen grow flex-col">
      {mediaGalleryId ? (
        <CollectionDetail creatorId={mediaGalleryId} onBack={onBack} />
      ) : (
        <>
          <div className="hidden h-screen md:block">
            <EmptyGallerySelection description={t("collection.emptyGallery.noSelectionDescription")} />
          </div>
          <div className="h-full md:hidden">
            <Collections
              onMediaGallerySelection={onMediaGallerySelection}
              onBack={onBack}
              selectedMediaGalleryId={mediaGalleryId}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <TwoColumnLayout
      hideNavigationOnMobile
      leftColumn={
        <Collections
          onMediaGallerySelection={onMediaGallerySelection}
          onBack={onBack}
          selectedMediaGalleryId={mediaGalleryId}
        />
      }
      rightColumn={center}
    />
  );
};
