import { Dialog } from "@headlessui/react";
import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import InterestBadge from "components/basics/InterestBadge";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import ValidationError from "components/utilities/ValidationError";
import { useField } from "formik";
import SelectCategoriesPage from "pages/SelectCategories";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  minCategories: number;
  maxCategories: number;
  type: "POST" | "PRODUCT";
};
const SelectCategoryPreview = ({ name, minCategories, maxCategories, type }: Props) => {
  const { t } = useTranslation();
  const [showCategories, setShowCategories] = useState(false);

  const [, meta, helpers] = useField<CategoryDto[]>(name);
  const { value } = meta;
  const { setValue } = helpers;

  const removeCategory = useCallback(
    (category: CategoryDto) => setValue(value.filter((c) => c !== category)),
    [setValue, value],
  );

  const saveCategories = useCallback(
    (categories: CategoryDto[]) => {
      setValue(categories);
      setShowCategories(false);
    },
    [setShowCategories, setValue],
  );

  return (
    <>
      <div>
        <label className={"mb-2 text-sm text-gray-600"}>
          {t("categories.selectUpToXsmall", { count: maxCategories })}
        </label>
        <SelectItemUnderline
          value={t("categories.select")}
          onClick={() => setShowCategories(true)}
          data-testid={"select-categories-button"}
        />

        {value?.length > 0 && (
          <div className={"mt-3 flex flex-wrap gap-2"}>
            {value.map((category) => (
              <InterestBadge
                interest={t(`categories.${category.name}`)}
                onRemove={() => removeCategory(category)}
                data-testid={"selected-category"}
                key={category._id}
              />
            ))}
          </div>
        )}
        {meta.error && meta.touched && (
          <ValidationError message={meta.error} hasBackground={false} className={"mt-2"} />
        )}
      </div>

      <Dialog unmount open={showCategories} onClose={() => setShowCategories(false)} className="relative z-50">
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto">
          <Dialog.Panel className="w-full bg-white">
            <SelectCategoriesPage
              minCategories={minCategories}
              maxCategories={maxCategories}
              selected={value || []}
              onSubmit={saveCategories}
              onBack={() => setShowCategories(false)}
              type={type}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default SelectCategoryPreview;
