import { usePostHog } from "posthog-js/react";
import { v4 as uuidv4 } from "uuid";

export enum CheckoutPosthogEvents {
  //TIP
  TipPopupOpen = "tip-popup-open",
  TipCheckout = "tip-checkout",
  TipPaymentStart = "tip-payment-start",
  TipPaymentError = "tip-payment-error",

  //PRODUCT
  ProductCheckout = "product-checkout",
  ProductPaymentStart = "product-payment-start",
  ProductPaymentError = "product-payment-error",

  //CHAT_PRODUCT
  ChatProductCheckout = "chat_product-checkout",
  ChatProductPaymentStart = "chat_product-payment-start",
  ChatProductPaymentError = "chat_product-payment-error",

  //SUBSCRIPTION
  SubscriptionPopupOpen = "subscription-popup-open",
  SubscriptionCheckout = "subscription-checkout",
  SubscriptionPaymentStart = "subscription-payment-start",
  SubscriptionPaymentError = "subscription-payment-error",
}

const relevantEventsForCheckoutIdReset = [
  CheckoutPosthogEvents.TipPaymentStart,
  CheckoutPosthogEvents.TipPaymentError,
  CheckoutPosthogEvents.ProductPaymentStart,
  CheckoutPosthogEvents.ProductPaymentError,
  CheckoutPosthogEvents.ChatProductPaymentStart,
  CheckoutPosthogEvents.ChatProductPaymentError,
  CheckoutPosthogEvents.SubscriptionPaymentStart,
  CheckoutPosthogEvents.SubscriptionPaymentError,
];

const resetCheckoutIdOnCheckout = (event: CheckoutPosthogEvents) => {
  if (relevantEventsForCheckoutIdReset.includes(event)) {
    localStorage.removeItem("posthog-checkout-id");
  }
};

export const useCheckoutPosthog = () => {
  const posthog = usePostHog();

  let posthogCheckoutId = localStorage.getItem("posthog-checkout-id");

  if (!posthogCheckoutId) {
    posthogCheckoutId = uuidv4();
    localStorage.setItem("posthog-checkout-id", posthogCheckoutId);
  }

  const capture = (event: CheckoutPosthogEvents, properties?: Record<string, string>) => {
    posthog?.capture(event.toString(), {
      ...properties,
      "posthog-checkout-id": posthogCheckoutId,
    });
    resetCheckoutIdOnCheckout(event);
  };

  return { capture };
};
