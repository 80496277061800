import { BroadcastOverviewFilter } from "@neolime-gmbh/api-gateway-client";
import PillSelection from "components/atoms/PillSelection";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import EmptySentBroadcasts from "components/molecules/broadcast/EmptySentBroadcasts";
import BroadcastCardLoading from "components/organisms/broadcast/BroadcastCardLoading";
import SentBroadcastCard from "components/organisms/broadcast/SentBroadcastCard";
import useSentBroadcasts from "hooks/chats/useSentBroadcasts.hook";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";

const SentBroadcastsPage = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = (searchParams.get("filter") as BroadcastOverviewFilter) || BroadcastOverviewFilter.ALL;
  
  const [broadcastFilter, setBroadcastFilter] = useState<BroadcastOverviewFilter>(initialFilter);
  const { broadcasts, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useSentBroadcasts(broadcastFilter);

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isLoading, isFetchingNextPage]);

  const handleFilterChange = (filter: BroadcastOverviewFilter) => {
    setBroadcastFilter(filter);
    setSearchParams({ filter }, { replace: true });
  };

  return (
    <>
      <Layout hideNavigationMobile>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("chats.broadcasts.title")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right className="pr-2">
              <HeaderBar.Link to="../new">
                <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
            </HeaderBar.Right>
          </HeaderBar.SubPage>
        </HeaderBar>

        <Container hasPadding={false} className="py-4">
          <div className="flex grow flex-col gap-3 px-4 md:px-0">
            <div className="flex items-center gap-2">
              {Object.keys(BroadcastOverviewFilter).map((filter) => (
                <PillSelection
                  key={filter}
                  text={t(`chats.broadcasts.filter.${filter}`)}
                  selected={broadcastFilter === filter}
                  onClick={() => handleFilterChange(filter as BroadcastOverviewFilter)}
                />
              ))}
            </div>
            {broadcasts.map((broadcast) => (
              <SentBroadcastCard broadcast={broadcast} key={broadcast._id} />
            ))}
            {(isLoading || isFetchingNextPage) && (
              <>
                {Array(5)
                  .fill(null)
                  .map((_, i) => (
                    <BroadcastCardLoading key={i} />
                  ))}
              </>
            )}
            {!isLoading && !isFetchingNextPage && broadcasts.length === 0 && <EmptySentBroadcasts />}
            <div className="absolute bottom-[10rem]" ref={ref} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default SentBroadcastsPage;
