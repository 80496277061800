import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { MessageContentType, PrivateUserDto, UploadType } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ContentTypeIcon from "components/atoms/icons/ContentTypeIcon";
import LinkifiedText from "components/atoms/utils/LinkifiedText";
import { formatDurationInSecondsIntoHoursMinutesSeconds, generateTimeTag } from "helper/dateAndTimeHelper";
import useCurrency from "hooks/useCurrency.hook";
import useLongPress from "hooks/useLongPress";
import { MouseEvent, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  isChatProductMessageContent,
  isMediaMessageContent,
  isTextMessageContent,
  isTipMessageContent,
  MessageContentDto,
} from "types/messages/message.type";
import useUserStore from "../../state/userState";
import ChatProductMessage from "./ChatProductMessage";
import EditChatProductPricePopup from "./EditChatProductPricePopup";
import MediaMessage from "./MediaMessage";
import MessageMenuPopup from "./MessageMenuPopup";
import TipMessage from "./TipMessage";
import { MessageDtoWithDeliveryInfo } from "helper/messageHelper";
import { HiOutlineExclamationCircle } from "react-icons/hi";

type MessageContentProps = {
  content: MessageContentDto;
  isChatProductBought: boolean;
  messageId: string;
  senderId: string;
  displayLinks?: boolean;
  failedDelivery?: boolean;
};

const MessageContent = ({
  content,
  isChatProductBought,
  messageId,
  senderId,
  displayLinks,
  failedDelivery,
}: MessageContentProps) => {
  if (isTextMessageContent(content))
    return (
      <LinkifiedText className="notranslate" displayAsLink={!!displayLinks}>
        {content.text}
      </LinkifiedText>
    );
  else if (isTipMessageContent(content)) return <TipMessage price={content.price} senderId={senderId} />;
  else if (isChatProductMessageContent(content))
    return (
      <ChatProductMessage
        chatProduct={content}
        isChatProductBought={isChatProductBought}
        messageId={messageId}
        senderId={senderId}
        failedDelivery={failedDelivery}
      />
    );
  else if (isMediaMessageContent(content))
    return (
      <MediaMessage
        media={content.media}
        thumbnails={content.thumbnails}
        senderId={senderId}
        failedDelivery={failedDelivery}
      />
    );

  return null;
};

type MessageFooterProps = {
  content: MessageContentDto;
  sentAt: string;
  senderId: string;
  isBought?: boolean;
  failedDelivery?: boolean;
};

const MessageFooter = ({ content, senderId, sentAt, isBought, failedDelivery }: MessageFooterProps) => {
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const date = generateTimeTag(new Date(sentAt), true);

  const purchaseStatus = useMemo(() => {
    if (isChatProductMessageContent(content)) {
      if (!isBought) return `${t("chatProduct.for")} ${content.price && displayCurrency(content.price.net)}`;
      else if (senderId === user._id)
        return (
          <span>
            <Trans i18nKey="chatProduct.soldFor">
              <span className="font-semibold">sold</span> for
            </Trans>{" "}
            {content.price ? displayCurrency(content.price.net) : ""}
          </span>
        );
      else if (content.price?.gross)
        return (
          <span>
            <Trans i18nKey="chatProduct.boughtFor">
              <span className="font-semibold">bought</span> for
            </Trans>{" "}
            {displayCurrency(content.price.gross)}
          </span>
        );
    }
    return undefined;
  }, [isBought, content]);

  const contentAmountText = useMemo(() => {
    if (isChatProductMessageContent(content)) {
      if (content.media.length === 1) {
        switch (content.media[0].type) {
          case UploadType.PICTURE:
            return "1";
          case UploadType.VIDEO:
            return formatDurationInSecondsIntoHoursMinutesSeconds(content.media[0].length);
        }
      } else {
        const imagesAmount = content.media.filter((m) => m.type === UploadType.PICTURE).length;
        const videosAmount = content.media.filter((m) => m.type === UploadType.VIDEO).length;
        const imageText =
          imagesAmount > 0 &&
          (imagesAmount > 1 ? t("chatProduct.images", { amount: imagesAmount }) : t("chatProduct.image"));
        const videoText =
          videosAmount > 0 &&
          (videosAmount > 1 ? t("chatProduct.videos", { amount: videosAmount }) : t("chatProduct.video"));

        if (imagesAmount > 0 && videosAmount === 0) return imageText;
        else if (imagesAmount > 0 && videosAmount > 0) return `${imageText} ${t("chatProduct.and")} ${videoText}`;
        else return videoText;
      }
    }
    return undefined;
  }, [content]);

  return (
    <div className={classNames("mt-1 flex flex-col text-xs", { "items-end": senderId === user._id })}>
      {failedDelivery && (
        <div className="flex items-center gap-1 text-red-error">
          <HiOutlineExclamationCircle className="h-4 w-4 text-red-error" />
          <span>{t("message.failedDelivery")}</span>
        </div>
      )}
      {isChatProductMessageContent(content) && (
        <div className="flex items-center text-gray-700">
          <ContentTypeIcon type={content?.media?.length === 1 ? content?.media?.at(0)?.type : "picture"} />
          <span className="ml-0.5">
            {contentAmountText} {purchaseStatus}
          </span>
        </div>
      )}
      <span className="text-gray-400">{date}</span>
    </div>
  );
};

type MessageProps = {
  message: MessageDtoWithDeliveryInfo;
  displayLinks?: boolean;
  onDelete: () => void;
  settingsOpenForMessage?: string;
  onOpenSettings: (messageId: string) => void;
  onCloseSettings: () => void;
};

//TODO: Rework this component and all underlying components
const Message = ({
  message,
  displayLinks,
  onDelete,
  settingsOpenForMessage,
  onOpenSettings,
  onCloseSettings,
}: MessageProps) => {
  // long press listener
  const longPress = useLongPress({
    callback: () => {
      showMenuPopUp ? onOpenSettings(customMessageIdentifier) : onCloseSettings();
    },
  });
  // check if message is from current user
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const isOwnMessage = user._id === message.senderId;
  const customMessageIdentifier = message._id + message.content.type;

  // menu popup open
  const areSettingsOpen = settingsOpenForMessage === customMessageIdentifier;
  // change price popup open
  const [changePricePopupOpen, setChangePricePopupOpen] = useState(false);

  const showMenuPopUp =
    isOwnMessage &&
    !isTipMessageContent(message.content) &&
    !(isChatProductMessageContent(message.content) && message.isBought);

  const isTextOrTip = useMemo(
    () => message.content.type === MessageContentType.TEXT || message.content.type === MessageContentType.TIP,
    [message],
  );

  return (
    <>
      <div
        className={classNames("flex flex-col py-1", {
          "opacity-30 blur-[2px]": settingsOpenForMessage && !areSettingsOpen,
          "items-end": isOwnMessage,
          "relative z-[9999] cursor-pointer": areSettingsOpen,
        })}
        data-testid={"message"}
        onClick={() => onCloseSettings()}
      >
        <div
          className={classNames(
            "group relative max-w-[70%] overflow-hidden whitespace-pre-wrap break-words rounded-t-[1.5rem]",
            {
              "max-w-sm": message.content.type !== "text",
              "rounded-l-[1.5rem]": isOwnMessage,
              "rounded-tr-[1.5rem]": !isOwnMessage,
              "rounded-br-[1.5rem]":
                (!isChatProductMessageContent(message.content) ||
                  (isChatProductMessageContent(message.content) && message.content.media?.length === 1)) &&
                !isOwnMessage,
              "w-fit px-4 py-3": isTextOrTip,
              "bg-beige-400": isOwnMessage && message.content.type === "text",
              "bg-gray-100": !isOwnMessage && message.content.type === "text",
              "bg-gray-800": message.content.type === "tip",
              "w-[70%]": message.content.type === "media" || message.content.type === "chat_product",
              "border-[1.5px] border-red-error": message.failedDelivery && message.content.type === "text",
            },
          )}
          // @ts-expect-error fix this
          onClickCapture={(e: MouseEvent) => {
            if (areSettingsOpen) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          {...longPress}
        >
          <div className={"group flex flex-col justify-center"}>
            <MessageContent
              messageId={message._id}
              content={message.content}
              senderId={message.senderId}
              isChatProductBought={message.isBought}
              displayLinks={isOwnMessage ? user.isTrusted : displayLinks}
              failedDelivery={message.failedDelivery}
            />
            {showMenuPopUp && !areSettingsOpen && !settingsOpenForMessage && (
              <button
                className={classNames("absolute right-0 top-0 hidden sm:group-hover:block", {
                  "pb-6 pl-6 pr-3 pt-3 text-white": !isTextOrTip,
                  "p-3": isTextOrTip,
                })}
                style={{
                  background: isTextOrTip
                    ? "radial-gradient(43.75% 43.75% at 50% 50%, #F6F4F0 0%, rgba(246, 244, 240, 0.96) 61.98%, rgba(246, 244, 240, 0.00) 100%)"
                    : "radial-gradient(100.00% 82.11% at 73.08% 20.41%, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.06) 60.19%, rgba(255, 255, 255, 0.00) 75%)",
                }}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  onOpenSettings(customMessageIdentifier);
                }}
              >
                <EllipsisHorizontalIcon className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>
        <MessageFooter
          content={message.content}
          senderId={message.senderId}
          sentAt={message.sentAt}
          isBought={message.isBought}
          failedDelivery={message.failedDelivery}
        />
        <MessageMenuPopup
          message={message}
          visible={areSettingsOpen}
          onDelete={onDelete}
          onEditPrice={() => setChangePricePopupOpen(true)}
          onCloseSettings={() => onCloseSettings()}
        />
        {isChatProductMessageContent(message.content) && !message.isBroadcasted && (
          <EditChatProductPricePopup
            messageId={message._id}
            chatProduct={message.content}
            open={changePricePopupOpen}
            onClose={() => setChangePricePopupOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default Message;
