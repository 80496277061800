import { ComponentType, ReactNode } from "react";
import classNames from "classnames";

type Props = {
  Icon: React.ElementType;
  text: ReactNode;
  handleOnClick: () => void;
  "data-testid"?: string;
  hasLeftSpacing?: boolean;
};

const MenuItem = ({ Icon, text, handleOnClick, "data-testid": dataTestId, hasLeftSpacing = false }: Props) => {
  return (
    <div
      className={classNames("flex cursor-pointer items-center border-b-2 border-gray-100 py-4", {
        "pl-5": hasLeftSpacing,
      })}
      onClick={handleOnClick}
      data-testid={dataTestId}
    >
      <Icon className={"h-6 w-6"} />
      <p className={"ml-3 flex items-center"}>{text}</p>
    </div>
  );
};

export default MenuItem;
