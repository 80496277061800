import Layout from "components/layouts/Layout";
import { useEffect, useState } from "react";
import { HiBars3, HiEllipsisHorizontal } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import Container from "../../components/layouts/Container";
import CreatorProfileInfo from "../../components/profile/CreatorProfileInfo";
import CreatorProfilePopUp from "../../components/profile/CreatorProfilePopUp";
import ProfileInfoButtons from "../../components/profile/ProfileInfoButtons";
import ProfileTabs from "../../components/profile/ProfileTabs";
import useCreator from "hooks/useCreator.hook";
import useUserStore from "state/userState";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { Helmet } from "react-helmet";
import HeaderBar from "components/layouts/HeaderBar";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  isCurrentUser?: boolean;
};

const CreatorProfile = ({ isCurrentUser = false }: Props) => {
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);
  const params = useParams();
  const navigate = useStatefulNavigate();

  const usernameInRoute = params.username;
  isCurrentUser = isCurrentUser || usernameInRoute === currentUser.username;
  const { creator, isError } = useCreator(usernameInRoute, isCurrentUser);

  const [popUpOpen, setPopUpOpen] = useState(false);

  useEffect(() => {
    if (!usernameInRoute || isError) {
      navigate("/404");
    }
  }, [usernameInRoute, isError]);

  if (!creator)
    return (
      <Layout>
        <HeaderBar className="mb-2">
          <HeaderBar.SubPage>
            <HeaderBar.Left>{!isCurrentUser && <HeaderBar.BackButton fallbackRoute="/" />}</HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>
                <div className="h-5 w-[8rem] animate-pulse rounded-md bg-gray-200" />
              </HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container hasGrow={false} hasPadding={false} className={"px-4 md:px-0"}>
          <CreatorProfileInfo isCurrentUser={isCurrentUser} />
          <div className={"mt-5 flex animate-pulse space-x-2"}>
            <div className={"h-12 w-full rounded-md bg-gray-200"} />
            <div className={"h-12 w-full rounded-md bg-gray-200"} />
          </div>
        </Container>
        <Container hasPadding={false} hasGrow={false}>
          <ProfileTabs />
        </Container>
      </Layout>
    );

  return (
    <Layout>
      <Helmet>
        <title>{`${creator.username} - MALOUM`}</title>
        <meta name="description" content={creator.bio} />
        <link rel="canonical" href={`https://app.maloum.com/creator/${creator.username}`} />
      </Helmet>
      <HeaderBar className="mb-2">
        <HeaderBar.SubPage>
          <HeaderBar.Left>{!isCurrentUser && <HeaderBar.BackButton fallbackRoute="/" />}</HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>
              <span className="max-w-full truncate block">{creator.username}</span>
            </HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right>
            {!isCurrentUser ? (
              <HeaderBar.Button onClick={() => setPopUpOpen(true)}>
                <HiEllipsisHorizontal className={"h-6 w-6"} />
              </HeaderBar.Button>
            ) : (
              <HeaderBar.Link to="/menu">
                <HiBars3 className={"h-6 w-6"} />
              </HeaderBar.Link>
            )}
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <CreatorProfilePopUp id={creator._id} isOpen={popUpOpen} setIsOpen={setPopUpOpen} />
      <Container hasGrow={false} hasPadding={false} className={"px-4 md:px-0"}>
        <CreatorProfileInfo user={creator} isCurrentUser={isCurrentUser} />
        <ProfileInfoButtons user={creator} isCurrentUser={isCurrentUser} />
      </Container>
      <Container hasPadding={false} hasGrow={false}>
        <ProfileTabs user={creator} />
      </Container>
    </Layout>
  );
};

export default CreatorProfile;
