// hooks/automated-messages/useAutomatedMessages.hook.tsx
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

const useAutomatedMessagesOverview = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchAutomatedMessages = async () => {
    return await maloumClient.automatedMessages.getAutomatedMessagesOverview();
  };

  const {
    data: automatedMessagesOverview,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["automated-messages-overview"],
    queryFn: fetchAutomatedMessages,
    refetchOnWindowFocus: false,
  });

  return {
    automatedMessagesOverview,
    isLoading,
    isError,
    refetch,
  };
};

export default useAutomatedMessagesOverview;
