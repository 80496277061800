import { GenderDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useCallback, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

const useGender = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchAllGenders = useCallback(async (): Promise<GenderDto[]> => {
    return await maloumClient.genders.getAll().then((genders: GenderDto[]) => {
      if (genders instanceof Error) {
        Promise.reject(genders);
      }
      return genders;
    });
  }, []);

  const genderKeysList = ["cisWoman", "cisMan", "nonBinary", "preferNotToSay"];

  const {
    data: allGenders,
    isLoading: isAllGendersLoading,
    isError: isAllGendersError,
  } = useQuery({
    queryKey: ["fetch-all-genders"],
    queryFn: fetchAllGenders,
  });

  const shortGenderList = useMemo(() => {
    return allGenders
      ? [...genderKeysList.flatMap((key) => allGenders.filter((gender) => gender.name === key))]
      : undefined;
  }, [allGenders]);

  const unsortedLongGenderList = useMemo(() => {
    return allGenders
      ? [
          ...allGenders
            .filter((gender) => gender.parentGender === null && gender.name !== "preferNotToSayMain")
            .map((parentGender) => ({
              _id: parentGender._id,
              category: parentGender.name,
              values: allGenders.filter(
                (gender) => gender.parentGender !== null && gender.parentGender._id === parentGender._id,
              ),
            })),
        ]
      : undefined;
  }, [allGenders]);

  const longGenderList = useMemo(() => {
    if (!shortGenderList || !unsortedLongGenderList) {
      return undefined;
    }

    return shortGenderList.flatMap((el) =>
      unsortedLongGenderList
        .filter((item) => {
          return item._id === el.parentGender._id;
        })
        .map((item) => {
          return {
            _id: item._id,
            category: item.category,
            values: [el, ...item.values.filter((gender) => gender.name !== el.name)],
          };
        }),
    );
  }, [shortGenderList, unsortedLongGenderList]);

  return {
    parentGenderList: allGenders?.filter(
      (gender) => gender.parentGender === null && gender.name !== "preferNotToSayMain",
    ),
    shortGenderList,
    longGenderList,
    allGenders,
    isAllGendersLoading,
    isAllGendersError,
    findGenderObject: (genderKey: string) => allGenders?.find((genderObj) => genderObj.name === genderKey),
  };
};

export default useGender;
