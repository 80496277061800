import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { CategoryDto } from "@neolime-gmbh/api-gateway-client";

const useCategories = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchCategories = async () => {
    return maloumClient.categories
      .getAll()
      .then((res) => res.filter((category: CategoryDto) => category.name !== "__default__"));
  };

  const { data: categories, isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
    staleTime: 60 * 60 * 1000 /* 60 minutes */,
  });
  return { categories: categories ?? [], isLoading };
};
export default useCategories;
