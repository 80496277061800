import { OrderDto, UpdateOrderDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useOrderDetails = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { orderId } = useParams();

  const fetchOrderDetail = useCallback(async (): Promise<OrderDto> => {
    return await maloumClient.orders.getById(orderId as string).then((order: OrderDto) => {
      if (order instanceof Error) {
        Promise.reject(order);
      }
      return order;
    });
  }, [orderId]);

  const updateOrderStatus = useCallback(
    async ({ status, trackingNumber }: UpdateOrderDto) => {
      if (!orderId) {
        return;
      }

      await maloumClient.orders.update(orderId, {
        status: status,
        trackingNumber: trackingNumber,
      });
    },
    [orderId],
  );

  const {
    data: order,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [`fetch-order-details-${orderId}`, orderId],
    queryFn: fetchOrderDetail,
    enabled: !!orderId,
  });

  return { order, isError, isLoading, updateOrderStatus };
};

export default useOrderDetails;
