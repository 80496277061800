import { ProductDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useProduct = (_id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchProduct = useCallback(async (): Promise<ProductDto> => {
    return await maloumClient.products.getById(_id).then((product: ProductDto) => {
      if (product instanceof Error) {
        throw Error;
      }
      return product;
    });
  }, [_id]);

  const {
    data: product,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [`fetch-product-${_id}`],
    queryFn: fetchProduct,
  });

  return { product, isLoading, isError };
};

export default useProduct;
