import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { MediaGalleryOverviewDtoKeysetResponse } from "@neolime-gmbh/api-gateway-client";

export type MediaGalleryQueryData = InfiniteData<MediaGalleryOverviewDtoKeysetResponse, string | undefined>;

export const useMediaGallery = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const getMediaGallery = async (next: any) =>
    maloumClient.mediaGallery.getMediaGalleryOverviewForFan(next.pageParam, 15);

  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery({
    queryKey: ["mediaGallery"],
    queryFn: getMediaGallery,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
  });

  const getSelectedMediaGallery = (creatorId: string) => {
    const existingData = queryClient.getQueryData<MediaGalleryQueryData>(["mediaGallery"]);

    if (!existingData) return undefined;

    const flatMap = existingData.pages.flatMap((page) => page.data);

    if (creatorId === "all") {
      return flatMap.find((item) => item.isGeneralCollection);
    }

    return flatMap.find((item) => item.creatorId === creatorId);
  };

  return {
    mediaGallery: data?.pages.flatMap((page) => page.data),
    hasNextPage,
    fetchNextPage,
    isLoading,
    getSelectedMediaGallery,
  };
};
