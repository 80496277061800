import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { MediaGalleryCollectionMediaDtoKeysetResponse, MediaGalleryFilter } from "@neolime-gmbh/api-gateway-client";

export type MediaGalleryQueryData = InfiniteData<MediaGalleryCollectionMediaDtoKeysetResponse, string | undefined>;

export const useMediaGalleryByCreator = (creatorId: string, filter?: MediaGalleryFilter) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const getMediaGalleryByCreator = async (next: any) =>
    maloumClient.mediaGallery.getMediaGalleryByCreator(
      next.pageParam,
      15,
      creatorId === "all" ? undefined : creatorId,
      filter,
    );

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ["mediaGalleryByCreator", creatorId, filter],
    queryFn: getMediaGalleryByCreator,
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    initialPageParam: undefined,
    placeholderData: () => {
      // get all data without filter
      const existingData = queryClient.getQueryData<MediaGalleryQueryData>([
        "mediaGalleryByCreator",
        creatorId,
        undefined,
      ]);

      if (!existingData || !filter) return existingData;

      return {
        ...existingData,
        pages: existingData.pages.map((page) => ({
          ...page,
          data: page.data.filter((m) => (filter === "free" ? m.sentForFree : m.boughtForPrice)),
        })),
      };
    },
  });

  return {
    media: data?.pages.flatMap((page) => page.data),
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};
