import { BaseComponent } from "types/components/component.type";

type Props = BaseComponent;

export const EmptyGalleryIllustration = ({ className }: Props) => {
  return (
    <svg
      width="196"
      height="150"
      viewBox="0 0 196 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M26.157 146.603H29.2768L30.7613 134.568L26.1562 134.569L26.157 146.603Z" fill="#FFB8B8" />
      <path
        d="M25.3633 145.584L31.5077 145.584H31.5079C32.0222 145.584 32.5313 145.685 33.0064 145.882C33.4815 146.079 33.9132 146.367 34.2768 146.731C34.6404 147.094 34.9288 147.526 35.1256 148.001C35.3224 148.476 35.4237 148.985 35.4237 149.5V149.627L25.3635 149.627L25.3633 145.584Z"
        fill="#2F2E41"
      />
      <path d="M13.4344 146.603H16.5542L18.0386 134.568L13.4336 134.569L13.4344 146.603Z" fill="#FFB8B8" />
      <path
        d="M12.6367 145.584L18.7811 145.584H18.7814C19.2956 145.584 19.8048 145.685 20.2799 145.882C20.7549 146.079 21.1866 146.367 21.5502 146.731C21.9138 147.094 22.2023 147.526 22.3991 148.001C22.5958 148.476 22.6971 148.985 22.6971 149.5V149.627L12.6369 149.627L12.6367 145.584Z"
        fill="#2F2E41"
      />
      <path
        d="M14.032 141.437C13.7407 141.436 13.4606 141.324 13.2486 141.125C13.0367 140.925 12.9087 140.652 12.8908 140.361L10.125 95.1309L28.738 97.1392L31.4828 137.683C31.5007 137.959 31.4183 138.232 31.2509 138.453C31.0834 138.673 30.8421 138.825 30.5713 138.882L26.8782 139.643C26.7288 139.674 26.5747 139.674 26.4251 139.645C26.2754 139.615 26.1331 139.556 26.0067 139.47C25.8802 139.385 25.772 139.275 25.6886 139.148C25.6051 139.02 25.548 138.877 25.5206 138.727L21.0082 113.99C21.0043 113.96 20.9886 113.931 20.9644 113.912C20.9401 113.892 20.9092 113.883 20.8783 113.886C20.8473 113.886 20.8175 113.898 20.7949 113.919C20.7723 113.94 20.7586 113.969 20.7565 114L18.0625 139.532C18.0391 139.756 17.9501 139.968 17.8068 140.142C17.6635 140.315 17.4722 140.442 17.2568 140.508L14.3672 141.387C14.2585 141.42 14.1456 141.437 14.032 141.437Z"
        fill="#2F2E41"
      />
      <path
        d="M14.3873 61.8313C17.8394 61.8313 20.6379 59.0328 20.6379 55.5807C20.6379 52.1286 17.8394 49.3301 14.3873 49.3301C10.9352 49.3301 8.13672 52.1286 8.13672 55.5807C8.13672 59.0328 10.9352 61.8313 14.3873 61.8313Z"
        fill="#FFB8B8"
      />
      <path
        d="M9.96732 97.9338L5.74503 75.2402C5.30146 72.8918 5.74934 70.4623 7.00118 68.4264C8.25302 66.3905 10.2189 64.8944 12.5148 64.2304C13.8279 63.8467 15.2077 63.7471 16.5623 63.9382C17.9169 64.1293 19.2153 64.6066 20.3711 65.3385C21.5269 66.0704 22.5135 67.0401 23.2654 68.1829C24.0173 69.3258 24.5172 70.6157 24.7318 71.9668L28.7676 97.3781L9.96732 97.9338Z"
        fill="#F33800"
      />
      <path
        d="M12.6603 101.925C12.3481 101.718 12.0849 101.446 11.8892 101.127C11.6935 100.809 11.5701 100.451 11.5277 100.079C11.4854 99.7072 11.525 99.3308 11.6439 98.976C11.7627 98.6213 11.9579 98.2969 12.2157 98.0258L8.04297 89.9453L12.7421 90.4468L15.9842 98.0432C16.411 98.5065 16.653 99.1102 16.6645 99.74C16.6759 100.37 16.4559 100.982 16.0462 101.46C15.6365 101.939 15.0655 102.25 14.4415 102.336C13.8174 102.421 13.1836 102.275 12.6603 101.925Z"
        fill="#FFB8B8"
      />
      <path
        d="M11.4981 96.605C11.3379 96.5898 11.1828 96.5411 11.0427 96.462C10.9026 96.3829 10.7807 96.2752 10.685 96.146L5.70911 89.4191C4.00755 87.1194 3.19924 84.28 3.43454 81.4289L4.20845 72.035C4.14248 71.5548 4.17181 71.0662 4.29476 70.5973C4.41772 70.1284 4.63188 69.6883 4.92501 69.3022C5.21814 68.9161 5.58449 68.5915 6.00311 68.3471C6.42174 68.1027 6.88444 67.9431 7.36476 67.8776C7.84508 67.8121 8.3336 67.8419 8.8024 67.9653C9.2712 68.0886 9.71109 68.3032 10.0969 68.5967C10.4828 68.8902 10.807 69.2568 11.051 69.6757C11.2951 70.0946 11.4542 70.5574 11.5192 71.0378L11.0666 85.9815L14.2573 93.5445C14.3483 93.7616 14.3704 94.0013 14.3207 94.2314C14.2711 94.4614 14.1519 94.6707 13.9794 94.8308L12.3829 96.306C12.2117 96.4641 11.9963 96.5663 11.7654 96.5989C11.6769 96.6115 11.5871 96.6135 11.4981 96.605Z"
        fill="#F33800"
      />
      <path
        d="M50.3826 52.1749C50.3145 52.5428 50.1666 52.8913 49.9492 53.1958C49.7319 53.5003 49.4504 53.7535 49.1246 53.9374C48.7989 54.1214 48.4367 54.2317 48.0637 54.2605C47.6907 54.2894 47.3159 54.2361 46.9657 54.1045L41.1516 61.0975L39.7812 56.5747L45.5125 50.6274C45.7726 50.0538 46.2342 49.5955 46.8097 49.3394C47.3852 49.0833 48.0346 49.0473 48.6349 49.238C49.2352 49.4288 49.7447 49.8331 50.0668 50.3744C50.3889 50.9157 50.5013 51.5563 50.3826 52.1749Z"
        fill="#FFB8B8"
      />
      <path
        d="M45.9357 55.319C45.9842 55.4724 45.9998 55.6343 45.9816 55.7941C45.9634 55.954 45.9118 56.1082 45.83 56.2468L41.5756 63.4515C40.1213 65.9151 37.8217 67.7665 35.1046 68.6615L26.1524 71.6114C25.7358 71.8597 25.2744 72.0234 24.7946 72.0932C24.3147 72.163 23.8258 72.1375 23.3557 72.0182C22.8857 71.8989 22.4439 71.6881 22.0554 71.3979C21.6669 71.1076 21.3395 70.7437 21.0918 70.3268C20.8441 69.9099 20.681 69.4482 20.6119 68.9683C20.5428 68.4883 20.5689 67.9994 20.6889 67.5296C20.8089 67.0597 21.0204 66.6181 21.3112 66.2301C21.6019 65.842 21.9664 65.5151 22.3836 65.268L36.3212 59.8584L42.0417 53.9714C42.2061 53.803 42.4182 53.6891 42.6494 53.6452C42.8806 53.6013 43.1198 53.6294 43.3345 53.7258L45.3154 54.6208C45.5278 54.7168 45.7058 54.8754 45.8258 55.0752C45.872 55.1519 45.9088 55.2337 45.9357 55.319Z"
        fill="#F33800"
      />
      <path
        opacity="0.1"
        d="M9.31641 77.2559L9.5709 85.3996L14.2589 93.5462L10.3443 84.4674L9.31641 77.2559Z"
        fill="black"
      />
      <path
        d="M15.1021 56.7551C15.4947 56.8061 15.8924 56.7129 16.2215 56.4929C16.5505 56.2728 16.7885 55.9408 16.8913 55.5584C16.9904 55.1184 17.2074 54.7136 17.519 54.3875C17.8306 54.0614 18.2251 53.8263 18.6602 53.7074C19.8068 53.4454 21.2082 53.9851 22.0484 53.1164C22.2283 52.918 22.3584 52.6798 22.4281 52.4213C22.4978 52.1628 22.5051 51.8914 22.4493 51.6295C22.2946 50.7853 21.6907 50.1476 21.0335 49.6375C19.6505 48.5691 17.9769 47.9436 16.2322 47.8431C15.3291 47.817 14.4254 47.862 13.5293 47.9776C12.5911 48.0368 11.6631 48.2064 10.7646 48.4829C9.74096 48.8692 8.83121 49.5073 8.11944 50.3382C7.40766 51.1691 6.91682 52.166 6.69229 53.2369C6.48693 54.311 6.51847 55.4169 6.78471 56.4776C7.05095 57.5383 7.54548 58.528 8.23377 59.3778C9.05776 60.4039 10.1202 61.2128 11.3285 61.7341C11.6164 61.87 11.9462 61.8867 12.2463 61.7806C12.9989 61.4814 12.8759 60.5491 12.5328 59.9273C12.1655 59.2619 11.6005 58.6176 11.8958 57.8077C12.0438 57.4322 12.328 57.1263 12.6917 56.9511C13.4398 56.5695 14.2767 56.6598 15.081 56.7527L15.1021 56.7551Z"
        fill="#2F2E41"
      />
      <path
        d="M191.125 143.528H79.9123C78.7653 143.526 77.6656 143.07 76.8545 142.259C76.0435 141.448 75.5872 140.348 75.5859 139.201V4.32051C75.5872 3.17348 76.0435 2.0738 76.8545 1.26273C77.6656 0.451658 78.7653 -0.00456879 79.9123 -0.00585938H191.125C192.272 -0.00456879 193.372 0.451658 194.183 1.26273C194.994 2.0738 195.45 3.17348 195.452 4.32051V139.201C195.45 140.348 194.994 141.448 194.183 142.259C193.372 143.07 192.272 143.526 191.125 143.528ZM79.9123 0.503125C78.9002 0.50427 77.9299 0.906827 77.2143 1.62248C76.4986 2.33813 76.0961 3.30842 76.0949 4.32051V139.201C76.0961 140.213 76.4986 141.184 77.2143 141.899C77.9299 142.615 78.9002 143.018 79.9123 143.019H191.125C192.137 143.018 193.108 142.615 193.823 141.899C194.539 141.184 194.942 140.213 194.943 139.201V4.32051C194.942 3.30842 194.539 2.33813 193.823 1.62248C193.108 0.906827 192.137 0.50427 191.125 0.503125H79.9123Z"
        fill="#3F3D56"
      />
      <path d="M195.201 10.5781H75.8438V11.0871H195.201V10.5781Z" fill="#3F3D56" />
      <path
        d="M81.6961 7.62851C82.8205 7.62851 83.732 6.71699 83.732 5.59258C83.732 4.46816 82.8205 3.55664 81.6961 3.55664C80.5717 3.55664 79.6602 4.46816 79.6602 5.59258C79.6602 6.71699 80.5717 7.62851 81.6961 7.62851Z"
        fill="#F33800"
      />
      <path
        d="M88.0594 7.62851C89.1838 7.62851 90.0953 6.71699 90.0953 5.59258C90.0953 4.46816 89.1838 3.55664 88.0594 3.55664C86.935 3.55664 86.0234 4.46816 86.0234 5.59258C86.0234 6.71699 86.935 7.62851 88.0594 7.62851Z"
        fill="#F33800"
      />
      <path
        d="M94.4187 7.62851C95.5432 7.62851 96.4547 6.71699 96.4547 5.59258C96.4547 4.46816 95.5432 3.55664 94.4187 3.55664C93.2943 3.55664 92.3828 4.46816 92.3828 5.59258C92.3828 6.71699 93.2943 7.62851 94.4187 7.62851Z"
        fill="#F33800"
      />
      <path
        d="M173.89 29.2613H94.4881C93.6106 29.2613 92.7691 28.9128 92.1487 28.2923C91.5283 27.6719 91.1797 26.8304 91.1797 25.9529C91.1797 25.0755 91.5283 24.234 92.1487 23.6135C92.7691 22.9931 93.6106 22.6445 94.4881 22.6445H173.89C174.767 22.6445 175.609 22.9931 176.229 23.6135C176.849 24.234 177.198 25.0755 177.198 25.9529C177.198 26.8304 176.849 27.6719 176.229 28.2923C175.609 28.9128 174.767 29.2613 173.89 29.2613ZM94.4881 23.1535C93.7456 23.1535 93.0336 23.4485 92.5086 23.9734C91.9836 24.4984 91.6887 25.2105 91.6887 25.9529C91.6887 26.6954 91.9836 27.4074 92.5086 27.9324C93.0336 28.4574 93.7456 28.7523 94.4881 28.7523H173.89C174.632 28.7523 175.344 28.4574 175.869 27.9324C176.394 27.4074 176.689 26.6954 176.689 25.9529C176.689 25.2105 176.394 24.4984 175.869 23.9734C175.344 23.4485 174.632 23.1535 173.89 23.1535H94.4881Z"
        fill="#3F3D56"
      />
      <path
        d="M172.623 66.9031H95.2577C94.1444 66.9018 93.0771 66.459 92.2899 65.6718C91.5027 64.8846 91.0599 63.8173 91.0586 62.704V46.162C91.0599 45.0487 91.5027 43.9814 92.2899 43.1942C93.0771 42.407 94.1444 41.9641 95.2577 41.9629H172.623C173.737 41.9642 174.804 42.407 175.591 43.1942C176.378 43.9814 176.821 45.0487 176.822 46.162V62.704C176.821 63.8173 176.378 64.8846 175.591 65.6718C174.804 66.459 173.737 66.9018 172.623 66.9031Z"
        fill="#E6E6E6"
      />
      <path
        d="M172.623 98.9677H95.2577C94.1444 98.9664 93.0771 98.5236 92.2899 97.7364C91.5027 96.9492 91.0599 95.8819 91.0586 94.7686V78.2266C91.0598 77.1133 91.5026 76.0459 92.2898 75.2587C93.0771 74.4714 94.1444 74.0286 95.2577 74.0273H172.623C173.737 74.0286 174.804 74.4715 175.591 75.2587C176.378 76.0459 176.821 77.1133 176.822 78.2266V94.7686C176.821 95.8818 176.378 96.9492 175.591 97.7364C174.804 98.5236 173.737 98.9664 172.623 98.9677Z"
        fill="#E6E6E6"
      />
      <path
        d="M172.623 131.034H95.2577C94.1444 131.033 93.0771 130.59 92.2899 129.803C91.5027 129.015 91.0599 127.948 91.0586 126.835V110.293C91.0599 109.18 91.5027 108.112 92.2899 107.325C93.0771 106.538 94.1444 106.095 95.2577 106.094H172.623C173.737 106.095 174.804 106.538 175.591 107.325C176.378 108.112 176.821 109.18 176.822 110.293V126.835C176.821 127.948 176.378 129.015 175.591 129.803C174.804 130.59 173.737 131.033 172.623 131.034Z"
        fill="#E6E6E6"
      />
      <path
        d="M128.342 64.866H50.9765C49.8632 64.8647 48.7958 64.4219 48.0086 63.6347C47.2214 62.8475 46.7786 61.7802 46.7773 60.6669V44.1249C46.7786 43.0116 47.2214 41.9443 48.0086 41.1571C48.7958 40.3698 49.8632 39.927 50.9765 39.9258H128.342C129.455 39.9271 130.523 40.3699 131.31 41.1571C132.097 41.9443 132.54 43.0116 132.541 44.1249V60.6669C132.54 61.7802 132.097 62.8475 131.31 63.6347C130.523 64.4219 129.455 64.8647 128.342 64.866Z"
        fill="white"
      />
      <path
        d="M128.344 64.9935H50.9787C49.8317 64.9923 48.732 64.536 47.9209 63.725C47.1099 62.9139 46.6536 61.8142 46.6523 60.6672V44.1252C46.6536 42.9782 47.1099 41.8785 47.9209 41.0674C48.732 40.2563 49.8317 39.8001 50.9787 39.7988H128.344C129.491 39.8001 130.591 40.2563 131.402 41.0674C132.213 41.8785 132.669 42.9782 132.671 44.1252V60.6672C132.669 61.8142 132.213 62.9139 131.402 63.725C130.591 64.536 129.491 64.9923 128.344 64.9935ZM50.9787 40.3078C49.9666 40.309 48.9963 40.7115 48.2807 41.4272C47.565 42.1428 47.1625 43.1131 47.1613 44.1252V60.6672C47.1625 61.6793 47.565 62.6496 48.2807 63.3652C48.9963 64.0809 49.9666 64.4834 50.9787 64.4846H128.344C129.356 64.4834 130.327 64.0809 131.042 63.3652C131.758 62.6496 132.161 61.6793 132.162 60.6672V44.1252C132.161 43.1131 131.758 42.1428 131.042 41.4272C130.327 40.7115 129.356 40.309 128.344 40.3078H50.9787Z"
        fill="#3F3D56"
      />
      <path
        d="M118.928 60.7951H60.3944C59.2811 60.7938 58.2138 60.351 57.4266 59.5638C56.6394 58.7766 56.1966 57.7093 56.1953 56.596V47.9433C56.1966 46.83 56.6394 45.7626 57.4266 44.9754C58.2138 44.1882 59.2811 43.7454 60.3944 43.7441H118.928C120.041 43.7454 121.108 44.1882 121.895 44.9754C122.683 45.7626 123.125 46.83 123.127 47.9433V56.596C123.125 57.7093 122.683 58.7766 121.895 59.5638C121.108 60.351 120.041 60.7938 118.928 60.7951Z"
        fill="#F2F2F2"
      />
      <path
        d="M72.9905 56.1313C75.7639 56.1313 78.0122 53.883 78.0122 51.1096C78.0122 48.3362 75.7639 46.0879 72.9905 46.0879C70.217 46.0879 67.9688 48.3362 67.9688 51.1096C67.9688 53.883 70.217 56.1313 72.9905 56.1313Z"
        fill="#FF6584"
      />
      <path
        d="M115.291 60.2886L108.202 54.3438L101.237 48.5015C101.053 48.3474 100.82 48.2631 100.58 48.2636C100.34 48.264 100.108 48.3492 99.9252 48.5041L92.9818 54.3731L91.2859 55.8067L88.7287 53.662L80.9572 47.1443C80.7734 46.9901 80.541 46.9058 80.3011 46.9063C80.0612 46.9067 79.8292 46.9919 79.646 47.1468L71.8997 53.6944L64.0316 60.3452C63.9918 60.3789 63.9632 60.424 63.9499 60.4745C63.9365 60.5249 63.939 60.5783 63.957 60.6273C63.975 60.6762 64.0076 60.7185 64.0504 60.7484C64.0932 60.7782 64.1442 60.7941 64.1964 60.794L80.3278 60.763L85.4339 60.7531L85.384 60.7954L100.605 60.7661L115.128 60.7381C115.18 60.738 115.231 60.7219 115.273 60.6919C115.316 60.6619 115.349 60.6195 115.366 60.5704C115.384 60.5213 115.386 60.468 115.373 60.4176C115.359 60.3672 115.331 60.3221 115.291 60.2886Z"
        fill="#3F3D56"
      />
      <path
        d="M49.6638 149.993H0.801367C0.733872 149.993 0.66914 149.967 0.621414 149.919C0.573687 149.871 0.546875 149.806 0.546875 149.739C0.546875 149.671 0.573687 149.607 0.621414 149.559C0.66914 149.511 0.733872 149.484 0.801367 149.484H49.6638C49.7313 149.484 49.7961 149.511 49.8438 149.559C49.8915 149.607 49.9183 149.671 49.9183 149.739C49.9183 149.806 49.8915 149.871 49.8438 149.919C49.7961 149.967 49.7313 149.993 49.6638 149.993Z"
        fill="#3F3D56"
      />
    </svg>
  );
};
