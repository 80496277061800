import classNames from "classnames";
import useUpload from "hooks/useUpload.hook";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePlus } from "react-icons/hi2";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  folderId: string;
  className?: string;
  "data-testid"?: string;
};

const MediaUpload = ({ folderId, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { handleUpload, imageMimeTypes, videoMimeTypes, getMediaDimensions, isValidAspectRatio } = useUpload();
  const acceptedMediaTypes = imageMimeTypes.concat(videoMimeTypes).join(", ");
  const hiddenInputField = useRef<HTMLInputElement>(null);

  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    setIsDragOver(false);
    // if files then upload
    if (e.dataTransfer?.files) uploadMedia(e.dataTransfer?.files);
  };

  const handleMediaSelection = (e: ChangeEvent<HTMLInputElement>) => {
    // if files then upload
    if (e.target.files) uploadMedia(e.target.files);
  };

  const uploadMedia = async (files: FileList) => {
    // store selected media
    const mediasTmp = Array.from(files);
    // this starts uploads in succession as starting them all at once (eg .map) axios cancels the requests (url generation)
    for (const media of mediasTmp) {
      const dimensions = await getMediaDimensions(media);
      if (isValidAspectRatio(dimensions.width, dimensions.height)) {
        await handleUpload({ media, dimensions, folderId });
      } else {
        // show upload error because of invalid aspect ratio
      }
    }
    // invalidate media count query
    queryClient.invalidateQueries({ queryKey: ["vaultMediaCount"] });
  };

  useEffect(() => {
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);
    return () => {
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);
  return (
    <>
      <button
        className={classNames("flex aspect-[3/4] items-center justify-center bg-beige-400", className)}
        onClick={() => hiddenInputField.current?.click()}
        data-testid={dataTestId}
      >
        <HiOutlinePlus className="h-8 w-8 text-red-900" />
      </button>
      {isDragOver && (
        <div className="fixed left-0 top-0 z-[9999] flex h-full w-full justify-end p-4">
          <div className="flex h-full w-full items-center justify-center rounded-lg border border-dashed border-red-900 bg-beige-400/90 sm:w-[calc(100%-5rem)] md:w-[calc(100%-15rem)]">
            <div>
              <img src="/images/addimage.svg" alt="" className="mx-auto h-[5.5rem] w-[5.5rem]" />
              <div className="mt-2 font-semibold">{t("vault.dragAndDrop")}</div>
            </div>
          </div>
        </div>
      )}
      <input
        type="file"
        multiple
        accept={acceptedMediaTypes}
        onInput={handleMediaSelection}
        className="hidden"
        ref={hiddenInputField}
      />
    </>
  );
};

export default MediaUpload;
