import HeaderBar from "components/layouts/HeaderBar";
import { CollectionList } from "components/organisms/collection/CollectionList";
import { useTranslation } from "react-i18next";

export const Collections = ({
  onMediaGallerySelection,
  onBack,
  selectedMediaGalleryId,
}: {
  onMediaGallerySelection: (mediaGalleryId: string) => void;
  onBack: () => void;
  selectedMediaGalleryId?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col">
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage className="sm:!pt-4 md:p-0">
          <HeaderBar.Left className="md:!ml-0 md:!pl-0 md:pb-2 md:pt-1">
            <span className="hidden pt-1 text-xl font-semibold md:block">{t("collection.title")}</span>
            <HeaderBar.BackButton className="md:hidden" onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center className="md:hidden">
            <HeaderBar.Title>{t("collection.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right className="md:basis-0"></HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <CollectionList
        className="mt-4 px-4 md:px-0"
        onMediaGallerySelection={onMediaGallerySelection}
        selectedMediaGalleryId={selectedMediaGalleryId}
      />
    </div>
  );
};
