import { useQueryClient } from "@tanstack/react-query";

const useQueryManipulator = () => {
  const queryClient = useQueryClient();

  const invalidateMessages = (chatId: string) => {
    invalidateQueries(["fetch-messages", chatId]);
  };

  const invalidateChat = (chatId: string) => {
    invalidateQueries(["fetch-chat", chatId]);
  };

  const setChatData = (chatId: string, data: unknown) => {
    setQueryData(["fetch-chat", chatId], data);
  };

  //keep this private
  const invalidateQueries = (key: string | string[]) => {
    queryClient.invalidateQueries({ queryKey: key });
  };

  //keep this private
  const setQueryData = (key: string[], value: unknown) => {
    queryClient.setQueryData(key, value);
  };

  return { invalidateChat, setChatData, invalidateMessages };
};

export default useQueryManipulator;
