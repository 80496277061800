import { create } from "zustand";
import { PrivateUserDto, UserIntroductionDto } from "@neolime-gmbh/api-gateway-client";

type PrivateUserWithInfo = Exclude<PrivateUserDto, "introduction"> & {
  firstOpening?: boolean;
  introduction?: Partial<UserIntroductionDto>;
};

interface UserState {
  user: PrivateUserWithInfo;
  bumpPublicPostCount: () => void;
  bumpPrivatePostCount: () => void;
  reducePublicPostCount: () => void;
  reducePrivatePostCount: () => void;
}

type SetState = (
  partial: UserState | Partial<UserState> | ((state: UserState) => UserState | Partial<UserState>),
  replace?: boolean,
) => void;

const modifyPublicPostCount = (set: SetState, modifyBy: number) => {
  return set((state: UserState) => ({
    user: {
      ...state.user,
      publicPostCount: (state.user.publicPostCount ?? 0) + modifyBy,
    },
  }));
};

const modifyPrivatePostCount = (set: SetState, modifyBy: number) => {
  return set((state: UserState) => ({
    user: {
      ...state.user,
      privatePostCount: (state.user.privatePostCount ?? 0) + modifyBy,
    },
  }));
};

const useUserStore = create<UserState>((set) => ({
  // todo: fix user state handling - it should check if user is defined when used
  user: null as unknown as PrivateUserWithInfo,
  bumpPublicPostCount: () => modifyPublicPostCount(set, 1),
  bumpPrivatePostCount: () => modifyPrivatePostCount(set, 1),
  reducePublicPostCount: () => modifyPublicPostCount(set, -1),
  reducePrivatePostCount: () => modifyPrivatePostCount(set, -1),
}));

export default useUserStore;
