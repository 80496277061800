import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import useListsState from "state/lists/listsState";

const useLists = (query: string) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { lists, addOrReplaceLists } = useListsState();

  const fetchLists = async ({ pageParam }: { pageParam: number | undefined }) =>
    await maloumClient.chatLists.me(undefined, pageParam, 25);

  const {
    data: listsData,
    isFetching: baseIsFetching,
    hasNextPage: baseHasNextPage,
    fetchNextPage: baseFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["lists"],
    queryFn: fetchLists,
    getPreviousPageParam: (firstPage) => (firstPage.next ? parseInt(firstPage.next) : undefined),
    getNextPageParam: (lastPage) => (lastPage.next ? parseInt(lastPage.next) : undefined),
    enabled: !query,
    initialPageParam: undefined,
  });

  useEffect(() => {
    if (listsData) addOrReplaceLists(listsData.pages.flatMap((page) => page.data));
  }, [listsData]);

  const fetchSearchLists = async ({ pageParam }: { pageParam: number | undefined }) => {
    return await maloumClient.chatLists.me(query, pageParam, 25);
  };

  const {
    data: searchResult,
    isFetching: searchIsFetching,
    hasNextPage: searchHasNextPage,
    fetchNextPage: searchFetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["searchList", query],
    queryFn: fetchSearchLists,
    getPreviousPageParam: (firstPage) => (firstPage.next ? parseInt(firstPage.next) : undefined),
    getNextPageParam: (lastPage) => (lastPage.next ? parseInt(lastPage.next) : undefined),
    enabled: !!query,
    initialPageParam: undefined,
  });

  const isSearching = !!query;

  if (isSearching) {
    const results = searchResult?.pages.flatMap((page) => page.data) || [];
    return {
      lists: results,
      isLoading: searchIsFetching,
      hasNextPage: searchHasNextPage ?? true,
      fetchNextPage: searchFetchNextPage,
    };
  } else
    return {
      lists,
      isLoading: baseIsFetching,
      hasNextPage: baseHasNextPage ?? true,
      fetchNextPage: baseFetchNextPage,
    };
};

export default useLists;
