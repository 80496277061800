import { useCallback, useLayoutEffect, useMemo, useState } from "react";

type ScrollDirectionType = "up" | "down";
function isBrowser() {
  return typeof window === "object";
}

// TODO: add throttling and debouncing
const useScrollPosition = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType | null>(null);

  const updatePosition = useCallback(
    (e: Event) => {
      const window = e.currentTarget as Window;

      if (window !== null) {
        if (scrollYPosition > window.scrollY) {
          setScrollDirection("up");
        } else if (scrollYPosition < window.scrollY) {
          setScrollDirection("down");
        }
        setScrollYPosition(window.scrollY);
      }
    },
    [scrollYPosition],
  );

  useLayoutEffect(() => {
    if (!isBrowser()) {
      return;
    }

    setScrollYPosition(window.scrollY);

    window.addEventListener("scroll", updatePosition);
    return () => window.removeEventListener("scroll", updatePosition);
  }, [updatePosition]);

  const isAtPageTop = useMemo(() => {
    if (scrollYPosition <= 0) {
      return true;
    } else {
      return false;
    }
  }, [scrollYPosition]);

  return { scrollDirection, isAtPageTop, scrollYPosition };
};

export default useScrollPosition;
