import ErrorPage from "pages/basics/Error";
import { Helmet } from "react-helmet";
import { PostHogProvider } from "posthog-js/react";
import { env } from "env";
import { AuthorizationProvider } from "contexts/AuthorizationContext";
import { MaloumClientProvider } from "contexts/MaloumClientContext";
import { StartupProvider } from "contexts/StartupContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserProvider } from "contexts/UserContext";
import { RouterProvider } from "react-router-dom";
import { router } from "router/router";
import { ErrorBoundary } from "react-error-boundary";
import { GuestProvider } from "contexts/GuestContext";
import { FirstlineProvider } from "@first-line/firstline-react";
import { SocketProvider } from "contexts/SocketContext";
import { ChatsProvider } from "contexts/ChatsContext";
const queryClient = new QueryClient();

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Helmet>
        <title>MALOUM - Entdecke deine Vorliebe</title>
        <meta
          name="description"
          content="Finde deine sexuellen Vorliebe auf MALOUM. Registriere dich jetzt und begib dich auf die Reise, deine Sexualität zu erkunden."
        />
      </Helmet>
      <PostHogProvider
        apiKey={env.VITE_POSTHOG_API_KEY}
        options={{
          api_host: env.VITE_POSTHOG_API_HOST,
          autocapture: false,
          capture_pageview: false,
          capture_pageleave: false,
        }}
      >
        <FirstlineProvider
          clientOptions={{
            domain: env.VITE_DOMAIN,
            audience: env.VITE_AUDIENCE,
            client_id: env.VITE_CLIENT_ID,
            redirect_uri: window.location.origin,
            logout_uri: window.location.origin,
          }}
        >
          <AuthorizationProvider>
            <MaloumClientProvider>
              <StartupProvider>
                <QueryClientProvider client={queryClient}>
                  <GuestProvider>
                    <UserProvider>
                      <SocketProvider>
                        <ChatsProvider>
                          <RouterProvider router={router} />
                        </ChatsProvider>
                      </SocketProvider>
                    </UserProvider>
                  </GuestProvider>
                </QueryClientProvider>
              </StartupProvider>
            </MaloumClientProvider>
          </AuthorizationProvider>
        </FirstlineProvider>
      </PostHogProvider>
    </ErrorBoundary>
  );
};

export default App;
