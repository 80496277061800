import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import MaloumClientContext from "contexts/MaloumClientContext";

export const useSingleChatQuery = (id: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  // only fetch every 10 minutes max
  return useQuery({
    queryKey: ["fetch-chat", id],
    queryFn: () => maloumClient.chats.getChat(id),
    staleTime: 10 * 60 * 1000,
  });
};
