import { TransactionCategory } from "@neolime-gmbh/api-gateway-client";
import ProtectedRoute from "components/ProtectedRoute";
import VerifiedRoute from "components/VerifiedRoute";
import AutomatedMessagesParent from "pages/AutomatedMessages/AutomatedMessagesParent";
import Comments from "pages/Comments";
import CreatePostPage from "pages/CreatePost";
import CreateProductPage from "pages/CreateProduct";
import CreatorMobileMenu from "pages/CreatorMenuMobile";
import DiscoverFeed from "pages/DiscoverFeed";
import DiscoverWizard from "pages/DiscoverWizard";
import EditPostPage from "pages/EditPost";
import EditProduct from "pages/EditProduct";
import FeedPage from "pages/Feed";
import Likes from "pages/Likes";
import OrderDetailPage from "pages/OrderDetail";
import PayoutInformationExplanation from "pages/PayoutInformation";
import PersonalDataPage from "pages/PersonalData";
import ProductDetailPage from "pages/ProductDetail";
import Queue from "pages/Queue";
import SearchPage from "pages/Search";
import ShopPage from "pages/Shop";
import Subscriptions from "pages/Subscriptions";
import TopCreators from "pages/TopCreators";
import NotFoundPage from "pages/basics/404";
import ErrorPage from "pages/basics/Error";
import BroadcastChat from "pages/chats/BroadcastChat";
import ChatAccessPage from "pages/chats/ChatAccessPage";
import ChatSettingsPage from "pages/chats/ChatSettingsPage";
import ChatWith from "pages/chats/ChatWith";
import ChatsParent from "pages/chats/ChatsParent";
import NewMessagePage from "pages/chats/NewMessagePage";
import SentBroadcastUserPage from "pages/chats/SentBroadcastUserPage";
import SentBroadcastsPage from "pages/chats/SentBroadcastsPage";
import Checkout from "pages/checkout/Checkout";
import SuccessOrder from "pages/checkout/success/SuccessOrder";
import { CollectionParent } from "pages/collection/CollectionParent";
import Fans from "pages/fans/Fans";
import SelectGenderPage from "pages/gender/SelectGender";
import AddToLists from "pages/lists/AddToLists";
import ListSelection from "pages/lists/ListSelection";
import ListsParent from "pages/lists/ListsParent";
import SelectedListsOverview from "pages/lists/SelectedListsOverview";
import AddMembers from "pages/lists/members/AddMembers";
import Orders from "pages/orders/Orders";
import PayoutPage from "pages/payout/Payout";
import PayoutMethod from "pages/payout/PayoutMethod";
import CreatorProfile from "pages/profiles/CreatorProfile";
import EditProfilePage from "pages/profiles/EditProfile";
import FanProfile from "pages/profiles/FanProfile";
import CommentSettings from "pages/settings/CommentSettings";
import CreatorSettings from "pages/settings/CreatorSettings";
import NotificationSettings from "pages/settings/NotificationSettings";
import AddMediaToFolders from "pages/vault/AddMediaToFolders";
import VaultParent from "pages/vault/VaultParent";
import VerificationError from "pages/verification/VerificationError";
import VerificationInfo from "pages/verification/VerificationInfo";
import VerificationStart from "pages/verification/VerificationStart";
import VerificationSuccess from "pages/verification/VerificationSuccess";
import { createBrowserRouter, redirect } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <FeedPage />,
  },
  {
    path: "/",
    children: [
      {
        path: "search",
        element: <SearchPage />,
      },
      {
        path: "topCreators",
        element: <TopCreators />,
      },
      {
        path: "discovery/feed/:_id",
        element: <DiscoverFeed />,
      },
      {
        path: "chat",
        element: <ChatsParent />,
      },
      {
        path: "shop",
        element: <ShopPage />,
      },
      {
        path: "creator/:username",
        element: <CreatorProfile />,
      },
    ],
  },
  {
    path: "/post",
    children: [
      {
        path: "create",
        element: (
          <VerifiedRoute>
            <CreatePostPage />
          </VerifiedRoute>
        ),
      },
      {
        path: ":id/edit",
        element: <EditPostPage />,
      },
      {
        path: ":id/comments",
        element: <Comments />,
      },
      {
        path: ":id/likes",
        element: <Likes />,
      },
    ],
  },
  {
    path: "/product",
    children: [
      {
        path: "create",
        element: (
          <VerifiedRoute>
            <CreateProductPage />
          </VerifiedRoute>
        ),
      },
      {
        path: ":productId",
        element: <ProductDetailPage />,
      },
      {
        path: ":productId/edit",
        element: <EditProduct />,
      },
    ],
  },
  {
    path: "profile",
    element: (
      <ProtectedRoute hasRole="fan" redirectTo="/creator/:username">
        <FanProfile />
      </ProtectedRoute>
    ),
  },
  {
    path: "fan/:username",
    element: <FanProfile />,
  },
  {
    path: "profile/edit",
    element: <EditProfilePage />,
  },
  {
    path: "chat",
    children: [
      { path: ":id", element: <ChatsParent /> },
      { path: "with/:id", element: <ChatWith /> },
      { path: "new", element: <NewMessagePage /> },
      { path: "new/message", element: <BroadcastChat /> },
      { path: "access", element: <ChatAccessPage /> },
      {
        path: "settings",
        element: (
          <ProtectedRoute hasRole="creator">
            <ChatSettingsPage />
          </ProtectedRoute>
        ),
      },
      { path: "broadcasts", element: <SentBroadcastsPage /> },
      { path: "broadcasts/users", element: <SentBroadcastUserPage /> },
    ],
  },
  {
    path: "profile/data",
    element: <PersonalDataPage />,
  },
  {
    path: "checkout",
    element: <Checkout />,
  },
  {
    path: "checkout/subscription/success",
    element: <SuccessOrder type={TransactionCategory.SUBSCRIPTION} />,
  },
  {
    path: "checkout/product/success",
    element: <SuccessOrder type={TransactionCategory.PRODUCT} />,
  },
  {
    path: "checkout/tip/success",
    element: <SuccessOrder type={TransactionCategory.TIP} />,
  },
  {
    path: "checkout/chat-product/success",
    element: <SuccessOrder type={TransactionCategory.CHAT_PRODUCT} />,
  },
  {
    path: "checkout/error",
    element: <ErrorPage />,
  },
  {
    path: "order/:orderId",
    element: <OrderDetailPage />,
  },
  {
    path: "gender",
    element: <SelectGenderPage />,
  },
  {
    path: "verification/start",
    element: <VerificationStart />,
  },
  {
    path: "verification/info",
    element: <VerificationInfo />,
  },
  {
    path: "verification/success",
    element: <VerificationSuccess />,
  },
  {
    path: "verification/error",
    element: <VerificationError />,
  },
  {
    path: "verification/failure",
    loader: () => {
      return redirect("/profile");
    },
  },
  {
    path: "payout",
    element: <PayoutPage />,
  },
  {
    path: "payout/method",
    element: (
      <VerifiedRoute>
        <PayoutMethod />
      </VerifiedRoute>
    ),
  },
  {
    path: "payout/information",
    element: <PayoutInformationExplanation />,
  },
  {
    path: "orders",
    element: <Orders />,
  },
  {
    path: "subscriptions",
    element: <Subscriptions />,
  },
  {
    path: "discover/preferences",
    element: <DiscoverWizard />,
  },
  {
    path: "404",
    element: <NotFoundPage />,
  },
  {
    path: "menu",
    element: <CreatorMobileMenu />,
  },
  {
    path: "creator-settings",
    element: (
      <ProtectedRoute hasRole="creator">
        <CreatorSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: "comment-settings",
    element: (
      <ProtectedRoute hasRole="creator">
        <CommentSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: "queue",
    element: <Queue />,
  },
  {
    path: "fans",
    element: <Fans />,
  },
  { path: "/notifications/settings", element: <NotificationSettings /> },
  {
    path: "/collection/:mediaGalleryId?",
    element: <CollectionParent />,
  },
  {
    path: "/vault",
    element: (
      <ProtectedRoute hasRole="creator">
        <VaultParent />
      </ProtectedRoute>
    ),
  },
  {
    path: "/vault/move",
    element: (
      <ProtectedRoute hasRole="creator">
        <AddMediaToFolders />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lists",
    element: (
      <ProtectedRoute hasRole="creator">
        <ListsParent />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lists/include",
    element: (
      <ProtectedRoute hasRole="creator">
        <ListSelection type="include" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lists/exclude",
    element: (
      <ProtectedRoute hasRole="creator">
        <ListSelection type="exclude" />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lists/add/:id",
    element: (
      <ProtectedRoute hasRole="creator">
        <AddMembers />
      </ProtectedRoute>
    ),
  },
  {
    path: "/lists/selected",
    element: (
      <ProtectedRoute hasRole="creator">
        <SelectedListsOverview />
      </ProtectedRoute>
    ),
  },
  {
    path: "lists/add/member",
    element: (
      <ProtectedRoute hasRole="creator">
        <AddToLists />
      </ProtectedRoute>
    ),
  },
  {
    path: "automated-messages",
    element: (
      <ProtectedRoute hasRole="creator">
        <AutomatedMessagesParent />
      </ProtectedRoute>
    ),
    children: [
      {
        path: ":automatedMessageType",
        element: (
          <ProtectedRoute hasRole="creator">
            <AutomatedMessagesParent />
          </ProtectedRoute>
        ),
      },
    ],
  },
  // default route if no other route is matched
  {
    path: "*",
    loader: () => {
      return redirect("/404");
    },
  },
]);
