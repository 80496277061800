import { MediaGalleryFilter } from "@neolime-gmbh/api-gateway-client";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import { ListItemName } from "components/atoms/ListItemName";
import PillSelection from "components/atoms/PillSelection";
import HeaderBar from "components/layouts/HeaderBar";
import { CollectionGrid } from "components/organisms/collection/CollectionGrid";
import { ThreeColMediaGridLoading } from "components/organisms/ThreeColMediaGrid";
import { useMediaGallery } from "hooks/media-gallery/useMediaGallery.hook";
import { useMediaGalleryByCreator } from "hooks/media-gallery/useMediaGalleryByCreator.hook";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseComponent } from "types/components/component.type";

type Props = {
  creatorId: string;
  onBack: () => void;
};

export const CollectionDetail = ({ creatorId, onBack }: Props) => {
  const { getSelectedMediaGallery } = useMediaGallery();
  const selectedMediaGallery = getSelectedMediaGallery(creatorId);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [filter, setFilter] = useState<MediaGalleryFilter | undefined>();

  const { media } = useMediaGalleryByCreator(creatorId, filter);

  useEffect(() => {
    setFilter(undefined);
  }, [creatorId]);

  const onChatClick = () => {
    if (state?.lastViewedPage === "chat") {
      navigate(-1);
    } else {
      navigate(`/chat/with/${creatorId}`, { state: { lastViewedPage: "gallery" } });
    }
  };

  const username = selectedMediaGallery?.isGeneralCollection
    ? t("collection.allCreators")
    : media?.[0]?.creatorUsername ?? "";

  return (
    <div className="flex h-full flex-col gap-3">
      <CollectionDetailHeader
        creatorUsername={username}
        onBack={onBack}
        onChatClick={onChatClick}
        hideChatButton={selectedMediaGallery?.isGeneralCollection}
      />
      {Boolean(selectedMediaGallery?.last3MediaThumbnails?.length) && (
        <div className="flex items-center gap-1.5 px-4 sm:px-0">
          <PillSelection text={t("collection.all")} selected={!filter} onClick={() => setFilter(undefined)} />
          <PillSelection
            text={t("collection.purchases")}
            selected={filter === MediaGalleryFilter.PURCHASED}
            onClick={() => setFilter(MediaGalleryFilter.PURCHASED)}
          />
          <PillSelection
            text={t("collection.free")}
            selected={filter === MediaGalleryFilter.FREE}
            onClick={() => setFilter(MediaGalleryFilter.FREE)}
          />
        </div>
      )}
      <CollectionGrid creatorId={creatorId} filter={filter} />
    </div>
  );
};

type CollectionDetailHeaderProps = {
  creatorUsername: string;
  onBack: () => void;
  onChatClick: () => void;
  hideChatButton?: boolean;
} & BaseComponent;

const CollectionDetailHeader = ({
  creatorUsername,
  onBack,
  onChatClick,
  hideChatButton,
  className,
}: CollectionDetailHeaderProps) => {
  return (
    <HeaderBar className={className} alwaysShowDividerXL>
      <HeaderBar.SubPage className="sm:!pt-4 md:mt-1">
        <HeaderBar.Left className="md:ml-0 md:grow">
          <span className="hidden text-xl font-semibold md:block">
            <ListItemName name={creatorUsername} className="pb-2 pt-1" />
          </span>
          <HeaderBar.BackButton className="md:hidden" onClick={onBack} />
        </HeaderBar.Left>
        <HeaderBar.Center className="md:hidden">
          <HeaderBar.Title>
            <ListItemName name={creatorUsername} />
          </HeaderBar.Title>
        </HeaderBar.Center>
        <HeaderBar.Right className="md:basis-0">
          {!hideChatButton && (
            <TernaryButton onClick={onChatClick} className="!m-0 !py-0">
              <HiOutlineChatBubbleOvalLeft className={"h-6 w-6"} />
            </TernaryButton>
          )}
        </HeaderBar.Right>
      </HeaderBar.SubPage>
    </HeaderBar>
  );
};

export const LoadingFolderDetail = ({ className }: BaseComponent) => {
  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:ml-0">
            <div className="hidden h-6 w-32 rounded-md bg-gray-100 text-xl font-semibold md:block" />
            <HeaderBar.BackButton className="md:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center className="md:hidden">
            <div className="mx-auto h-5 w-24 rounded-md bg-gray-100" />
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0">
            <div className="m-2 h-6 w-6 rounded-md bg-gray-100" />
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <ThreeColMediaGridLoading className={className} />
    </div>
  );
};
