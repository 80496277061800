import { ChatDto, InboxType } from "@neolime-gmbh/api-gateway-client";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import useUserStore from "state/userState";
import { getChatQueryKey, getAllChatQueryKeysForInbox } from "helper/chatsQueryHelper";
import useChatFilterState, { ChatFilter } from "state/chatFilterState";
import { ChatsQueryData } from "./useChatsQuery.hook";

export const useChatsQueryManipulator = () => {
  const queryClient = useQueryClient();
  const user = useUserStore.getState().user;

  const updateChatQueryAndAddToTop = (chat: ChatDto) => {
    const type = chat.inbox;

    const queryKeys = getAllChatQueryKeysForInbox({ type, isCreator: user?.isCreator });

    queryKeys.forEach((queryKey) => {
      queryClient.setQueryData(queryKey, (old: ChatsQueryData) => {
        // check if we have the chat to add in the data, remove it if we do and add it to the top
        if (!old) return old;

        // if we want to update a new chat while being on the unread tab because we've sent a message, we don't want to update the query
        // unless we already have it in the query
        if (
          queryKey.includes("unread") &&
          !chat.unreadMessages &&
          !old.pages.flatMap((p) => p.data).find((c) => c._id === chat._id)
        ) {
          return old;
        }

        return {
          ...old,
          pages: old.pages.map((page, idx) => {
            const oldDataFiltered = page.data.filter((c) => c._id !== chat._id);
            if (idx === 0) {
              return {
                ...page,
                data: [chat, ...oldDataFiltered],
              };
            }
            return {
              ...page,
              data: oldDataFiltered,
            };
          }),
        };
      });
    });
  };

  const updateChatInChatsQuery = (newChat: ChatDto) => {
    const type = newChat.inbox;
    const queryKeys = getAllChatQueryKeysForInbox({ type, isCreator: user?.isCreator });

    queryKeys.forEach((queryKey) => {
      updateQueryData({
        queryKey,
        chatId: newChat._id,
        updateFunction: () => newChat,
      });
    });
  };

  const removeChatFromQuery = (chatId: string, type: InboxType, filter?: ChatFilter) => {
    const currentFilter = filter ?? useChatFilterState.getState().filter;

    const queryKey = getChatQueryKey({ type, isCreator: user?.isCreator, filter: currentFilter });
    queryClient.setQueryData(queryKey, (old: ChatsQueryData) => {
      if (!old) return old;

      return { ...old, pages: old.pages.map((page) => ({ ...page, data: page.data.filter((c) => c._id !== chatId) })) };
    });
  };

  const invalidateChatsQueryAndRefetch = (type: InboxType) => {
    const filter = useChatFilterState.getState().filter;

    const queryKey = getChatQueryKey({ type, isCreator: user?.isCreator, filter });
    queryClient.invalidateQueries({ queryKey, refetchType: "all" });
  };

  const markChatAsReadInQuery = (chatId: string) => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get("tab");
    const inboxType = tab && tab === "request" ? InboxType.REQUEST : InboxType.PRIMARY;

    const queryKeys = getAllChatQueryKeysForInbox({ type: inboxType, isCreator: user?.isCreator });

    queryKeys.forEach((queryKey) => {
      updateQueryData({
        queryKey,
        chatId,
        updateFunction: (chat: ChatDto) => ({ ...chat, unreadMessages: false }),
      });
    });
  };

  const updateTotalSpendForChat = ({ chatId, totalSpend }: { chatId: string; totalSpend: number }) => {
    const filter = useChatFilterState.getState().filter;
    // creators only have primary inbox, and this feature is only available for creators
    const queryKey = getChatQueryKey({ type: InboxType.PRIMARY, isCreator: user?.isCreator, filter });

    updateQueryData({
      queryKey,
      chatId,
      updateFunction: (chat: ChatDto) => ({
        ...chat,
        chatPartner: { ...chat.chatPartner, totalSpendForCreator: totalSpend },
      }),
    });
  };

  // helper to update single chat in chats query
  const updateQueryData = ({
    queryKey,
    chatId,
    updateFunction,
  }: {
    queryKey: readonly unknown[];
    chatId: string;
    updateFunction: (chat: ChatDto) => ChatDto;
  }) => {
    queryClient.setQueryData(queryKey, (old: ChatsQueryData) => {
      if (!old) return old;

      return {
        ...old,
        pages: old.pages.map((page) => ({
          ...page,
          data: page.data.map((c) => (c._id === chatId ? updateFunction(c) : c)),
        })),
      };
    });
  };

  const updateUnreadChatsQuery = () => {
    const queryKey = getChatQueryKey({ type: InboxType.PRIMARY, isCreator: user?.isCreator, filter: "unread" });
    queryClient.setQueryData(queryKey, (old: ChatsQueryData) => {
      if (!old) return old;

      return { ...old, pages: old.pages.map((page) => ({ ...page, data: page.data.filter((c) => c.unreadMessages) })) };
    });
  };

  const updateChatInboxInChatsQuery = (chatId: string, inbox: InboxType) => {
    const oldInbox = inbox === InboxType.PRIMARY ? InboxType.REQUEST : InboxType.PRIMARY;

    //remove from old inbox
    const oldQueryKeys = getAllChatQueryKeysForInbox({ type: oldInbox, isCreator: user?.isCreator });
    const newQueryKeys = getAllChatQueryKeysForInbox({ type: inbox, isCreator: user?.isCreator });

    //invalidate new query keys and refetch
    newQueryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries({ queryKey, refetchType: "all" });
    });

    //remove from old query keys
    oldQueryKeys.forEach((queryKey) => {
      queryClient.setQueryData(queryKey, (old: InfiniteData<{ data: ChatDto[] }>) => {
        if (!old) return old;

        return {
          ...old,
          pages: old.pages.map((page) => ({ ...page, data: page.data.filter((c) => c._id !== chatId) })),
        };
      });
    });
  };

  const getChatFromChatsQuery = (chatId: string, type: InboxType) => {
    const filter = useChatFilterState.getState().filter;
    const queryKey = getChatQueryKey({ type, isCreator: user?.isCreator, filter });
    const data = queryClient.getQueryData<InfiniteData<{ data: ChatDto[] }>>(queryKey);
    return data?.pages.flatMap((p) => p.data).find((c) => c._id === chatId);
  };

  return {
    updateChatQueryAndAddToTop,
    updateChatInChatsQuery,
    removeChatFromQuery,
    invalidateChatsQueryAndRefetch,
    markChatAsReadInQuery,
    updateTotalSpendForChat,
    updateUnreadChatsQuery,
    updateChatInboxInChatsQuery,
    getChatFromChatsQuery,
  };
};
