import { ChatDto, InboxType } from "@neolime-gmbh/api-gateway-client";
import { useEffect, useRef, useState } from "react";
import { useChatsQuery } from "../queries/useChatsQuery.hook";
import { useChatsQueryManipulator } from "../queries/useChatsQueryManipulator.hook";
import useQueryManipulator from "hooks/useQueryManipulator";
import { useParams } from "react-router-dom";
import useChatFilterState from "state/chatFilterState";

export const useChats = (type: InboxType) => {
  const { data: chatsData, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useChatsQuery(type);
  const filter = useChatFilterState((state) => state.filter);
  const { updateChatInChatsQuery, removeChatFromQuery, updateUnreadChatsQuery } = useChatsQueryManipulator();
  const { invalidateChat } = useQueryManipulator();
  const { id } = useParams();

  const [chats, setChats] = useState<ChatDto[]>(() => chatsData?.pages.flatMap((p) => p.data) ?? []);

  const chatsRef = useRef<ChatDto[]>([]);
  chatsRef.current = chats;

  useEffect(() => {
    const flatMapped = chatsData?.pages.flatMap((p) => p.data) ?? [];
    setChats(flatMapped);
  }, [chatsData]);

  useEffect(() => {
    return () => {
      const chat = chatsRef.current.find((c) => c._id === id);
      // check if filter is set to unread, if so, remove chat from query after unmounting
      if (id && filter === "unread" && chat) {
        removeChatFromQuery(id, chat.inbox, "unread");
      }
    };
  }, [id]);

  useEffect(() => {
    if (!filter) {
      // filter was changed from "unread" to "all" (i.e. no filter)
      updateUnreadChatsQuery();
    }
  }, [filter]);

  const updateChat = (chat: ChatDto) => {
    if (chat.inbox !== type) return;
    updateChatInChatsQuery(chat);
    if (id !== chat._id) {
      invalidateChat(chat._id);
    }
  };

  const updateInboxNicknameForChatPartner = async ({ chatId, nickname }: { chatId: string; nickname: string }) => {
    const chatToUpdate = chatsRef.current.find((c) => c._id === chatId);
    if (chatToUpdate) {
      const updatedChat = { ...chatToUpdate, chatPartner: { ...chatToUpdate.chatPartner, nickname } };
      updateChat(updatedChat);
    }
  };

  return {
    chats,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    updateInboxNicknameForChatPartner,
  };
};
