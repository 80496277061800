import { useCallback, useContext } from "react";
import ProductPreview from "../product/ProductPreview";
import MyInfiniteScroll from "../layouts/MyInfiniteScroll";
import useSearchStore from "../../state/searchState";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import { ProductDto } from "@neolime-gmbh/api-gateway-client";
import SearchNoResults from "components/utilities/SearchNoResults";
import { useTranslation } from "react-i18next";
// TODO: generalise TData
type TData = {
  next: string | null;
  data: ProductDto[];
};

type Props = {
  query: string | null;
  isTyping: boolean;
};

const SearchProduct = ({ query, isTyping = false }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const searchProducts = useCallback(
    (query: string, next: string | null) => {
      return maloumClient.products.search(query, [], next ?? undefined);
    },
    [maloumClient],
  );

  const setProductsResults = useSearchStore<(value: TData, addToData?: boolean) => void>(
    (state) => state.setProductsResults,
  );
  const data = useSearchStore<TData>((state) => state.productsResults);
  const setData = useCallback((newData: TData) => setProductsResults(newData, true), [setProductsResults]);
  const loadNextProducts = useCallback(
    (next: string | null) => (query ? searchProducts(query, next) : null),
    [query, searchProducts],
  );
  const createProductComponent = useCallback(
    (item: ProductDto) => <ProductPreview key={item._id} product={item} />,
    [],
  );
  const createLoadingProductComponent = useCallback((key: number) => <ProductPreview isLoading key={key} />, []);
  const NoProductResultsView = useCallback(() => <SearchNoResults query={query} tab={t("products")} />, []);

  return (
    <div className={"my-4"}>
      <MyInfiniteScroll
        className={"grid grid-cols-2 gap-4"}
        // @ts-expect-error - worst typed file in the history of typescript
        loadNext={loadNextProducts}
        loadingItemsCount={6}
        createItemComponent={createProductComponent}
        createLoadingItemComponent={createLoadingProductComponent}
        NoResultsView={NoProductResultsView}
        showLoader={isTyping}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default SearchProduct;
